import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Fade } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Image from 'material-ui-image'
import SampleResponseTrove from './sampleResponseTrove.json';
import Web3 from 'web3';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import HolderRow from './topHolderRow';
import MechaPunkxLogoText from '../assets/mechapunkxLogoTextCompressed.png';
import CompositeRow1 from '../assets/compositeRow1.jpg';
import TopHoldersSection from './topHoldersSection';

import MechaPunkx from '../abis/MechaPunkx.json'
import MechToken from '../abis/MechToken.json'
import Lambo from '../abis/Lambo.json'

const themeConnectButton = createTheme({
  status: {
	danger: '#ff0000',
  },
  palette: {
	primary: {
	  main: '#007bff',
	  darker: '#004a99'
	},
	neutral: {
	  main: '#007bff',
	  contrastText: '#fff'
	},
	action: {
      disabledBackground: '#003e80',
      // disabled: ''
    }
  },
});

const themeSortButton = createTheme({
  status: {
	danger: '#ff0000',
  },
  palette: {
	primary: {
	  main: '#333638',
	  darker: '#004a99'
	},
	neutral: {
	  main: '#333638',
	  contrastText: '#fff'
	},
	action: {
      disabledBackground: '#242728',
       disabled: '#555b5e',
      // disabled: ''
    }
  },
});


export interface DashboardProps {
	// toggleThemeSong: () => void;
}

export default function Dashboard(props) {

	const { ethereum } = window;
	const [mechapunkxContract, setMechaPunkxContract] = React.useState(undefined);
	const [mechContract, setMechContract] = React.useState(undefined);
	const [lamboContract, setLamboContract] = React.useState(undefined);

	const [ethPriceUSD, setEthPriceUSD] = React.useState(1100);
	const [mechPriceETH, setMechPriceETH] = React.useState(0);
	const [floorPriceETH, setFloorPriceETH] = React.useState("~");
	
	const [mintCostMech, setMintCostMech] = React.useState("~");
	const [totalSupplyMech, setTotalSupplyMech] = React.useState(0);
	const [totalSupplyLambo, setTotalSupplyLambo] = React.useState(0);
	const [mechInLP, setMechInLP] = React.useState(0);
	
	const [numMinted, setNumMinted] = React.useState(0);
	const [numHolders, setNumHolders] = React.useState(0);

	

	const numMintRemaining = () => {
		return 7848 - numMinted;
	}

	const floorPriceUSD = () => {
		if (floorPriceETH === "~") return 0;
		return floorPriceETH * ethPriceUSD;
	}

	const mintCostUSD = () => {
		if (mintCostMech === "~") return 0;
		return mintCostMech * mechPriceUSD();
	}

	const burnReturnMECH = () => {
		if (mintCostMech === "~") return 0;
		return parseInt(parseInt(mintCostMech) / 2);
	}

	const burnReturnUSD = () => {
		if (mintCostMech === "~") return 0;
		return parseInt(mintCostMech / 2) * mechPriceUSD();
	}

	const mechPriceUSD = () => {
		if (mechPriceETH === 0 || ethPriceUSD === 0) return 0;
		else {
			// 0.01 eth per mech
			// eth costs 1100 
			return mechPriceETH * ethPriceUSD;
		}
	}
	// Query for eth price
	const queryEthPrice = {
		"query": `{
			tokens(where: {symbol: "USDC"}) {
				derivedETH
			}
			bundles(first: 5) {
				id
				ethPrice
			}
		}`
	}

	const loadingComplete = () => {
		return floorPriceETH !== "~" && mintCostMech !== "~" && numMinted !== 0;
	}

	/*
	const fetchEthPrice = () => {
		fetch('https://api.thegraph.com/subgraphs/name/sushiswap/arbitrum-exchange', {
		  method: 'POST',
		  headers: {
		    'Content-Type': 'application/json',
		    'Accept': 'application/json',
		  },
		  body: JSON.stringify(queryEthPrice)
		})
		  .then(r => r.json())
		  .then(data => {
		  	// console.log('data returned:', data);
		  	try {
		  		let ethPrice = data.data.bundles[0].ethPrice;
		  		return ethPrice;
		  	}
		  	catch (error) {
		  		console.log("Error getting eth price from graph: ", error);
		  		console.log(data);
		  		return 0;
		  	}
		  })
		  .catch(e => {
		  	console.log("Caught error getting eth price: ", e);
		  	return 0;
		  });
	}
	*/

	// Example: WETH, USDC
	let mechWethLP = "0x3fba6811c5f700fecb9748cce3c563a28b514f9c";

	// testnet
	// let mechTokenAddr = "0x905dfcd5649217c42684f23958568e533c711aa3"; // weth/usdc
	// let usdcTokenAddr = "0xcb0e5bfa72bbb4d16ab5aa0c60601c438f04b4ad"; // weth/usdt
	// reserve0 (num tokens)
	// reserveETH --- I think this is total pool value in ETH
	// volumeUSD
	// token0price -> 0.0009 ($1 == this much weth) 
	// token1price -> 1100 (1 weth === this much dollars)
	// Can add more pools into list of pools
	const queryMechUSD = {
		"query": `{
		  pairs(where: {id_in: ["${mechWethLP}"]}) {
		    id
		    token0 {
		      symbol
		    }
		    token1 {
		      symbol
		    }
		    reserve0
		    reserve1
		    reserveUSD
		    token1Price
		  }
		}`
	}


	async function fetchEthPrice() {
		const resp = await fetch('https://api.thegraph.com/subgraphs/name/sushiswap/arbitrum-exchange', {
		  method: 'POST',
		  headers: {
		    'Content-Type': 'application/json',
		    'Accept': 'application/json',
		  },
		  body: JSON.stringify(queryEthPrice)
		})

		if (!resp.ok) {
			const message = `Error getting ETH price: ${resp.status}`;
			console.error(message);
			return 0;
		}

		const data = await resp.json();
		return data.data.bundles[0].ethPrice;
	}

	async function fetchMechData() {

		/*
		return {
	        "id": "0x905dfcd5649217c42684f23958568e533c711aa3",
	        "token0": {
	          "symbol": "WETH"
	        },
	        "token1": {
	          "symbol": "USDC"
	        },
	        "token1Price": "500",
	        "reserve0": "3010.617256016670352536",
	        "reserve1": "3289368.526707",
	        "reserveETH": "3766.077343535625777671631244501873"
	      }
	    */


		const resp = await fetch('https://api.thegraph.com/subgraphs/name/sushiswap/arbitrum-exchange', {
		  method: 'POST',
		  headers: {
		    'Content-Type': 'application/json',
		    'Accept': 'application/json',
		  },
		  body: JSON.stringify(queryMechUSD)
		})

		if (!resp.ok) {
			const message = `Error getting MECH price: ${resp.status}`;
			console.error(message);
			return 0;
		}

		const data = await resp.json();

		console.log("Mech price returns: ", data);
		let pairs = data.data.pairs;
		console.log("Pairs: ", pairs);
		
		let pair = pairs.filter((p) => p.id === "0x3fba6811c5f700fecb9748cce3c563a28b514f9c")[0];
		console.log("Using pair for MECH price: ", pair.token0.symbol, pair.token1.symbol);




		// PAIRS is empty for now



		/*
		for (let i=0; i<pairs.length;i++) {
			let p = pairs[i];
			if (p.id === "") {

			}
		}
		*/

		return pair;
	}

	/* Use a single file updated with all the metadata currently available, everything else is derived from that*/
	const fetchFloorPrice = () => {

      	//prod
		let url = "https://hfihu314z3.execute-api.us-east-1.amazonaws.com/collection/arb/mechapunkx";

		//testnet
      	//let url = "https://nfkndp3w66.execute-api.us-west-2.amazonaws.com/collection/arbtest/mechapunkx";

		// let resp = SampleResponseTrove;

		// if (ethereum !== undefined) {

		fetch(url, {
	      headers : { 
	        'Content-Type': 'application/json',
	        'Accept': 'application/json'
	       }
	   	}).then(function(response){
        	//console.log("Got Trove collection data: ", response);
        	return response.json();
      	}).then(function(resp){
      		
			// null or a string representing the price (like "1000000000000000000" for 1 ETH)
			if (resp.hasOwnProperty("floorPrice")) {
				if (resp["floorPrice"] !== null) {
					let fp = Web3.utils.fromWei(resp['floorPrice'], 'ether');
					if (fp >= 0.1) fp = Number(fp).toFixed(1);
					else fp = Number(fp).toFixed(2);
					console.log("Setting fp in eth: ", fp.toString());
					setFloorPriceETH(fp);		
				}
				else {
					console.log("floorPrice returned null: ", resp);
					setFloorPriceETH(0);
				}
			}
			else {
				console.log("No floorPrice field found in response: ", resp);
			}

			// Get num holders
			if (resp.hasOwnProperty("numOwners") && resp["numOwners"] !== null) {
				setNumHolders(parseInt(resp["numOwners"]));
			}
			else {
				console.error("Error reading num owners from Trove resp: ", resp);
			}
      	});
      	//}
	}

	async function fetchCollectionData(mechapunkxContract, mechContract, lamboContract) {

		// Use the numMinted instead of totalSupply
		
		let minted = await mechapunkxContract.methods.numMinted().call().then((res) => Number(res));
		setNumMinted(minted.toLocaleString());

		let mintCost = await mechapunkxContract.methods.mintCost().call().then((res) => Number(res));
		setMintCostMech(mintCost);

		let supMech = await mechContract.methods.totalSupply().call().then((res) => Number(res));
		setTotalSupplyMech(supMech / 10**18);

		let supLambo = await lamboContract.methods.totalSupply().call().then((res) => Number(res));
		setTotalSupplyLambo(supLambo / 10**18);

	} 


	React.useEffect(() => {

		if (ethereum === undefined) {
			alert("Please install the MetaMask wallet extension from https://metamask.io");
		}
		else {
			init();
		}
	}, []);

	
	async function init() {
		// return;
		if (window.web === undefined) {
			window.web3 = new Web3(window.ethereum);
			const networkId = await window.web3.eth.net.getId();
			// const networkData = MechaPunkx.networks[networkId];
			// const networkData = MechaPunkx.networks["42161"];
			// const networkData = MechaPunkx.networks["421611"]; // arbitrum rinkeby testnet 
			// console.log("Available: ", MechaPunkx.networks); // 421611, arbitrum_testnet

			// if (networkData) {
				if (mechapunkxContract === undefined) {
					//const address = networkData.address; 
					const address = "0x9D3eBe1CBc34614b414Bbb6d83F9b0875B8365EE";
					const contract = new window.web3.eth.Contract(MechaPunkx.abi, address);
					const mechContract = new window.web3.eth.Contract(MechToken.abi, "0x2d4622181f66616d7ca358a2d7395dc1b5e0dac6");
					const lamboContract = new window.web3.eth.Contract(Lambo.abi, "0x593E46216343B33Ef54696F09c5F23Cb4469108f");
					setMechaPunkxContract(contract);
					setMechContract(mechContract);
					setLamboContract(lamboContract);
				
					
					let ethPrice = await fetchEthPrice(); // returns String
					console.log("Using ETH/USDC: ", ethPrice);
					setEthPriceUSD(Number(ethPrice));
	
					try {
						let mechData = await fetchMechData(); // returns obj
						console.log("Using MECH data: ", mechData);
						
						let mechInLP = parseInt(mechData.reserve0); // TO LOCA
						setMechInLP(mechInLP.toLocaleString());

						//let mechPriceUSD = Number(mechData.reserve0) / Number(mechData.reserve1);
						let mechPrice = Number(mechData.token1Price);
						// mechPrice = parseInt(mechPrice);
						setMechPriceETH(mechPrice);
					
					}
					catch (error) {
						console.error("Error getting MECH data: ", error);
					}

					fetchFloorPrice();
					fetchCollectionData(contract, mechContract, lamboContract);
					
				}
			// }
			// else {
				// console.log("Change network to arbitrum");
			// }
		}
	}


	return(
		<div id="mpx-db" className="text-offwhite mpx-db-text" 
			style={{"display": "flex", "flex-direction": "column", "width": "100%", "background-color": "black", "font-weight": "bold", "font-family": "Kanit"}}>
			<div id="mpx-db-stats" style={{"display": "flex", "flex-direction": "column", "padding-left": "4vw", "padding-right": "4vw", "padding-top": "2vw"}}>
				
				<div id="mpx-db-stats-upper" style={{"display": "flex", "flex-direction": "row", "justify-content": "space-between", "align-items": "center"}}>
					<div style={{
						"width": "54%",
						"font-family": "Kanit", "font-weight": "bold", "display": "flex", "flex-direction": "column"}}>
						<div className="mpx-db-text-title-main" style={{"color": "red"}}>
							
						{/*
							TODO: Get the fade
							<Image 
							src={Banner2}
							style={{"background-color": "#1e1e1e"}}
							imageStyle={{height: "auto", borderRadius: "0.75rem 0.75rem 0.75rem 0.75rem"}}
							disableSpinner={true}
							animationDuration={2500}
							aspectRatio={"4/1"}
						/>*/}



							<img src={MechaPunkxLogoText} width="100%"/>
						</div>
						<div>
							<img src={CompositeRow1} width="100%"/>
						</div>
					</div>
					<div style={{"width": "46%", "font-family": "Kanit", "font-weight": "bold", 
						"align-self": "flex-start", "padding-right": "2vw", "padding-bottom": "2vw", 
						"padding-top": "1vw", "text-align": "right", "position": "relative"}}>
						<div>
							Mint remaining:
						</div>
						<div>
							<span className="">{numMintRemaining().toLocaleString()} </span><span style={{"color": "#535353"}}> / 7,848</span>
						</div>
						<div style={{"position": "absolute", "top": 0, "right": 0}}>
							<Box sx={{ display: 'flex'}}>
								<Fade
						            in={!loadingComplete()}
						            style={{
						              transitionDelay: loadingComplete() ? '0ms' : '800ms',
						            }}
						            unmountOnExit
						          >
								<CircularProgress size={"2.5vw"}/>
								</Fade>
							</Box>
						</div>
					</div>
				</div>
				<div id="mpx-db-stats-lower" style={{"display": "flex", "flex-direction": "column", "padding-top": "1vw"}}>
					
					<table style={{"width": "100%"}}>
						<tbody>
							<tr>
								<td>Floor:</td>
								<td>{floorPriceETH} ETH</td>
									{/*"color": "#535353", */}
								<td className="text-gray mpx-db-text-medium-lg" style={{"padding-right": "2vw"}}>(${parseInt(floorPriceUSD()).toLocaleString()})</td>
								<td className="mpx-db-text-small" style={{"max-width": "26%", "width": "26%"}}>
									<a href="" target="_blank">
										<ThemeProvider theme={themeConnectButton}>
											<Button id="buySellMechaPunkxButton"
												href="https://trove.treasure.lol/collection/mechapunkx/"
												target="_blank"
												variant="contained" size={"large"} color={"primary"} 
												style={{"font-family": "inherit", "font-weight": "bolder", "font-size": "1vw", "padding": "7px",
												"border": "3px solid #006fe6", "border-radius": "4px"}}>Buy / Sell MechaPunkx</Button>
										</ThemeProvider>
									</a>
								</td>
							</tr>
							<tr>
								<td>Mint Cost:</td>
								<td>{mintCostMech} MECH</td>
									{/*"color": "#535353", */}
								<td className="text-gray mpx-db-text-medium-lg" style={{"padding-right": "2vw"}}>(${parseInt(mintCostUSD()).toLocaleString()})</td>
								<td className="mpx-db-text-small" 
									style={{"vertical-align": "middle", "max-width": "26%", "width": "26%"}}>
									<div style={{"display": "flex", "flex-direction": "row", "justify-content": "flex-start", "align-items": "center"}}>
										<ThemeProvider theme={themeConnectButton}>
											<Button id="buyMechButton" 
												href="https://app.sushi.com/swap?chainId=42161&outputCurrency=0x2d4622181F66616d7cA358a2d7395Dc1b5E0DAc6&inputCurrency=ETH"
												target="_blank"
												variant="contained" size={"large"} color={"primary"} 
												style={{"font-family": "inherit", "font-weight": "bolder", "font-size": "1vw", "padding": "7px",
												"border": "3px solid #006fe6", "border-radius": "4px"}}>Buy / Sell MECH</Button>
										</ThemeProvider>
										<div style={{"padding-left": "2vw", "color": "#535353"}}>
										<a href="https://arbiscan.io/token/0x3fba6811c5f700fecb9748cce3c563a28b514f9c" target="_blank">({mechInLP} available)</a>
										</div>
									</div>
								</td>
							</tr>
							<tr>
								<td>Burn returns:</td>
								<td>{burnReturnMECH()} MECH</td>
								<td className="text-gray mpx-db-text-medium-lg" style={{"padding-right": "2vw"}}>(${parseInt(burnReturnUSD()).toLocaleString()})</td>
								<td  style={{"max-width": "26%", "width": "26%"}}></td>
							</tr>
							
						</tbody>
					</table>

					<div style={{"display": "flex", "flex-direction": "row", "padding-top": "2vw", "width": "100%", 
						"justify-content": "space-between"}}>
						<div style={{"padding-right": "2vw"}}>
							<div style={{"display": "flex", "flex-direction": "column"}}>
								<div className="mpx-db-text-small" style={{"color": "#535353"}}>
									** Burn any MechaPunkx and receive 1/2 the current mint cost back in MECH.
								</div>
								<div className="mpx-db-text-medium" style={{"padding-top": "2vw"}}>
									{/*Every MechaPunkx generates 1 MECH / day <span className="text-gray">($47)</span>*/}
									1 MechaPunkx = 1 MECH / day <span style={{"color": "#535353"}}>(${mechPriceUSD().toFixed(2).toLocaleString()})</span>
								</div>
							</div>
						</div>
						<div className="mpx-db-text-medium" 
							style={{ 
								"width": "25%",
								"border-radius": "10px", 
								"display": "flex", 
								"flex-direction": "row", 
								"justify-content": "flex-end",
								"background-color": "#262626"	
							}}>
							<table 
								style={{
									// "border-collapse": "collapse",
									"align": "right",
									// "width": "100%",
									"display": "block", 
									"padding-left": "2vw", 
									"padding-right": "2vw", 
									"padding-top": "1vw", 
									"padding-bottom": "1vw", 
									"text-align": "right", 
									"margin-right": "0", 
									"margin-left": "auto"}}>
								<tbody>
									<tr>
										<td colspan={2}>Total Supply</td>
									</tr>
									<tr style={{"position": "relative"}}>
										<td>{totalSupplyMech.toLocaleString()}
										{/*<div style={{"position": "absolute",
											"border-radius": "6px",  
											"background-color": "#9a9a9a", "opacity": "0.5", "top": 0, "right": 0, "width": "120%", zIndex: 1}}>
										&nbsp;
										</div>*/}
										</td>
										<td className="text-gray" style={{"padding-left": "2vw", "padding-right": "1vw"}}>MECH</td>
									</tr>
									<tr>
										<td>{totalSupplyLambo.toLocaleString()}</td>
										<td className="text-gray" style={{"padding-left": "2vw", "padding-right": "1vw"}}>LAMBO</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>


			<TopHoldersSection />

			
		</div>
	);

}