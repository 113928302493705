import { AnimationOnScroll } from 'react-animation-on-scroll';
import "animate.css/animate.min.css";

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Image from 'material-ui-image'
import BirdIcon13 from '../assets/icons/birdIcon13.png'

export interface HolderRowProps {
	address: String;
	ensName: String,
	smolName: String,
	tokens: Array;
	ranks: Object; // Maps tokenId to rank
	holderRank: Number;
	sortByRarity: Boolean; // Affects tokens display opacity
}

export default function HolderRow(props) {
	
	// console.log("We got tokens: ", props.tokens);

	const errorImage = <div style={{"color": "white", "font-size": "6vw", "font-weight": "bold"}}>?</div>

	const addressOrDomain = () => {
		if (props.smolName !== undefined && props.smolName !== "") {
			// return props.smolName;
			return <a className="text-brightblue-hover" href={"https://trove.treasure.lol/user/" + props.address.toString()} target="_blank">{props.smolName}</a>;
		}
		else if (props.ensName !== undefined && props.ensName !== "") {
			// return props.ensName;
			return <a className="text-brightblue-hover" href={"https://trove.treasure.lol/user/" + props.address.toString()} target="_blank">{props.ensName}</a>;
		}
		else {
			// ?collection%5B%5D=Smol+Sharks
			return <a className="text-brightblue-hover" href={"https://trove.treasure.lol/user/" + props.address.toString()} target="_blank">{props.address}</a>;
		}
	}

	if (props.tokens === undefined || props.tokens.length == 0) return (<div></div>);

	// first
	// <Grow in={checked}>{icon}</Grow>
	// one second later
		
	/*
	 <Grow
      in={checked}
      style={{ transformOrigin: '0 0 0' }}
      {...(checked ? { timeout: 1000 } : {})}
    >
      {icon}
    </Grow>
	*/
	//

	function getImage(index, tokenId, rank, sortByRarity) {

		let opacity = (sortByRarity && index > 0) ? 0.28: 1;

		return(
			<ImageListItem id={"mpx-inv-img-" + tokenId.toString()} key={"mpx-inv-img-" + tokenId.toString()} 
				// className={canSelect ? "mpx-inv-img-selectable" : "mpx-inv-img-selectable-nohover"}
				className={"mpx-inv-img-selectable-holders"}
				// style={{"max-width": "4vw"}}
				// onClick={canSelect ? selectMechaPunkx : () => {}}
			>
			<a href={"https://trove.treasure.lol/collection/mechapunkx/" + tokenId.toString()} target="_blank">
				<Image  
					alt=""
					src={"https://lambo.lol/images/mechapunkx-small/" + tokenId.toString() + ".png"}
					srcSet={"https://lambo.lol/images/mechapunkx-small/" + tokenId.toString() + ".png"}
					// src={ "https://lambo.lol/images/mechapunkx/" + tokenId.toString() + ".png" }
					// srcSet={ "https://lambo.lol/images/mechapunkx/" + tokenId.toString() + ".png" }
					style={{"background-color": "#1e1e1e", "color": "white", "border-radius": "4px", "opacity": opacity }}
					disableSpinner={true}
					errorIcon={errorImage}
					animationDuration={2100}
					loading="lazy"
				/>
			</a>
			</ImageListItem>
		);
	}

	// const other =<span className="text-gray" style={{"padding-left": "1vw"}}>{props.tokens.length}</span>

	const rowTitle = <div className="text-offwhite mpx-db-text-small" style={{"font-family": "Kanit", "width": "fit-content"}}>
		{"Rarity:  " + props.ranks[props.tokens[0]]}
	</div>;

	const rowTitle2 = <div className="text-offwhite mpx-db-text-small" style={{"font-family": "Kanit", "width": "fit-content",
		"background-color": "#2e2e2e", "border-radius": "8px", 
		"border": "2px solid #313131",
		"padding-left": "0.8vw", "padding-right": "0.8vw"}}>
		{("#" + props.holderRank.toString())}
	</div>;

	 

	// className="opensea-border" 
	// , ,
	// #{props.holderRank} <span className="text-gray" style={{"padding-left": "1vw"}}>({props.tokens.length})</span>
	return(
		<div style={{"display": "flex", "flex-direction": "column"}}>
			<div style={{"display": "flex", "flex-direction": "row", "justify-content": "space-between"}}>
				{props.sortByRarity ? rowTitle : rowTitle2}
			</div>
			<div>
				<ImageList 
					key={"holder-row-" + props.address} 
					sx={{ width: "100%", height: "100%", "marginBlockEnd": 0, "marginBlockStart": "0.4rem"}} 
					cols={props.sortByRarity ? 14 : 22}
					style={{"background-color": "#222426"}}
				>
					{props.tokens.map((tok, index) => 
						getImage(index, tok, props.ranks[tok], props.sortByRarity)
					)}
				</ImageList>	
			</div>
			<div className="text-brightblue" style={{
				"font-family": "Kanit", "text-align": "right", "width": "100%", "display": "flex", "flex-direction": "row",
				"justify-content": "flex-end"
			}}>
				
				<div className="mpx-db-text-smaller" style={{"margin-bottom": "1vw", "margin-top": "0.4vw"}}>
					{addressOrDomain()}
				</div>
				<div className="mpx-db-text-small" style={{"padding-left": "1.6vw", "color": "#4f4f4f"}}>
					({props.tokens.length})
				</div>
			</div>
		</div>
		
	);
} 

/*
<Grow
      in={true}
      style={{ transformOrigin: '0 0 0' }}
      {...(true ? { timeout: 1000 } : {})}
    >
*/
