import * as React from 'react';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogContentText from '@mui/material/DialogContentText';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';

import LamboIcon from './lamboIcon';
import MechIcon from './mechIcon';

// 126,126,126 gray bg
// 88,93,96 (gray button)

// gray top border 484848
// gray bg 72 72 72

//008000
const themeGenerateLamboDialog = createTheme({
  status: {
    danger: '#ff0000',
  },
  palette: {
    primary: {
      main: '#5c5c5c',
      darker: '#ffffff',
    },
    neutral: {
      main: '#5c5c5c',
      contrastText: '#fff',
    },
  },
});

const themeGenerateLamboButton = createTheme({
  status: {
    danger: '#ff0000',
  },
  palette: {
    primary: {
      main: '#008000',
      darker: '#004d00',
    },
    neutral: {
      main: '#008000',
      contrastText: '#fff',
    },
    action: {
      disabledBackground: '#004d00',
      // disabled: ''
    }
  },
});

export interface SimpleDialogProps {
	open: boolean;
	onClose: (value: string) => void;
	selectedToken: String;
}

function SimpleDialog(props: SimpleDialogProps) {
	const { onClose, open, selectedToken } = props;

	const handleClose = () => {
		onClose("");
	};

	const handleListItemClick = (value: string) => {
		onClose(value);
	};

	return (
		// z - index to position above tooltip
		<Dialog onClose={handleClose} open={open} style={{"cursor": "pointer", "zIndex": 1501}} 
			PaperProps={{
		    style: {
				color: "white",
				backgroundColor: "rgb(42 42 42)", 
				// 2e7d32
				// 7e7e7e
				// 008022
				border: "3px solid #434343",
				boxShadow: "none"
		    },
		  }}>
			<DialogTitle className="noselect" style={{ backgroundColor: "#292929", 
				}}>GLITCH your punk?</DialogTitle>
			<DialogContent style={{"color": "white"}}>

		<br></br>
		<br></br>
		
		<DialogContentText style={{"color": "white", "font-weight": "bold"}}>
        	<div style={{"display": "flex", "flex-direction": "row", "justify-content": "space-evenly", "align-items": "center"}}>
        		<div className="mpx-inv-text">
        			<div style={{"display": "flex", "flex-direction": "column", "justify-content": "space-between", "align-items": "center",
        				"font-family": "Kanit"}}>
        				<div>MechaPunkx</div>
        				<div>#{selectedToken}</div>
        			</div>
        		</div>
        		<div><img src={"https://lambo.lol/images/mechapunkx/" + selectedToken + ".png"} height={"100px"} width={"100px"} style={{"border-radius": "5px"}}/></div>
        	</div>
        </DialogContentText>

       	{/*<br></br>
        <DialogContentText style={{"color": "white", "display": "flex", "align-items": "center"}}>
        	<div style={{"background-color": "red", "color": "white", 
							"font-family": "Kanit", "font-weight": "bold", "border-radius": "15%",
							"display": "inline-block", "width": "2vw", "height": "2vw", "text-align": "center", "line-height": "1.75",
							"margin-left": "1vw"}}>M
					</div> <span style={{"padding-left": "1vw"}}>Cost: 50 <span style={{"font-weight": "bold"}}>MECH</span></span>
        </DialogContentText>
        <br></br>*/}
        
        <br></br>
        <br></br>
        <div style={{"display": "flex", "flex-direction": "row", "justify-content": "space-between", "align-items": "center", "text-align": "center"}}>
        <DialogContentText style={{"color": "white", "font-weight": "bold"}}>
        	Cost: 50 MECH
        </DialogContentText>
        <ThemeProvider theme={themeGenerateLamboDialog}>			
					<Button variant="contained" size={"small"} color={"primary"} 
						style={{"font-family": "inherit", "font-weight": "bolder", "font-size": "0.8vw", "padding": "7px", 
						"border": "3px solid #5d5d5d", "border-radius": "10px"}} onClick={() => handleListItemClick("Ok")} disableRipple={true}>Ok</Button>
		  	</ThemeProvider>
      	</div>
      </DialogContent>

			{/*<List sx={{ pt: 0 }}>
				{emails.map((email) => (
					<ListItem button onClick={() => handleListItemClick(email)} key={email}>
						<ListItemAvatar>
							<Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
							</Avatar>
						</ListItemAvatar>
						<ListItemText primary={email} />
					</ListItem>
				))}
				<ListItem autoFocus button onClick={() => handleListItemClick('addAccount')}>
					<ListItemAvatar>
						<Avatar>
							
						</Avatar>
					</ListItemAvatar>
					<ListItemText primary="Add account" />
				</ListItem>
			</List>*/}
		</Dialog>
	);
}

export interface GlitchButtonProps {
	glitchText: String,
	glitchDisabled: Boolean,
	selectedMechaPunkx: void;
	noneSelected: () => void;
	generate: () => void;
}

export default function GlitchButton(props: GlitchButtonProps) {
	
	const [selectedToken, setSelectedToken] = React.useState("");
	const [open, setOpen] = React.useState(false);


	const handleClickOpen = () => {
		// If no mpx selected, show snackbar notice
		// OR make the error thing show up on "OK" select with no mpx selected, so any user can see what the button does
		if (props.selectedMechaPunkx === undefined) {
			props.noneSelected();
		}
		else {

			let tokenId = props.selectedMechaPunkx.src.split('/').slice(-1)[0];
			if (!tokenId.endsWith(".png")) {
				console.error("Unexpected non-img src: ", tokenId);
				return;
			}
			else {
				tokenId = tokenId.slice(0, -4); // Chop file extension
			}

			setSelectedToken(tokenId);
			setOpen(true);
		}
	};

	const handleClose = (value: string) => {
		setOpen(false);
		if (value.toLowerCase() === "ok") {
			props.generate();
		}
	};

	return (
		<div>
			<ThemeProvider theme={themeGenerateLamboButton}>			
				<Button id="glitchButton" variant="contained" size={"large"} color={"primary"} 
					style={{"font-family": "inherit", "font-weight": "bolder", "font-size": "0.8vw", "padding": "7px", 
					"border": "3px solid #006600", "border-radius": "10px"}} 
					onClick={handleClickOpen}
					disabled={props.glitchDisabled}
				>{props.glitchText}
				</Button>
		  </ThemeProvider>
			<SimpleDialog
				open={open}
				onClose={handleClose}
				selectedToken={selectedToken}
			/>
		</div>
	);
}
