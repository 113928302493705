import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const themeConnectButton = createTheme({
  status: {
    danger: '#ff0000',
  },
  palette: {
    primary: {
      main: '#007bff',
      darker: '#000000',
    },
    neutral: {
      main: '#007bff',
      contrastText: '#fff',
    },
    action: {
      disabledBackground: '#003e80',
      // disabled: ''
    }
  },
});

export interface MintBuyDialogProps {
  sendBuyTransaction: (quantity) => void;
  mintBuyDisabled: Boolean;
  onDialogOpen: () => void;
}

export default function MintBuyDialog(props: MintBuyDialogProps) {
  const [open, setOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [btnDisabled, setBtnDisabled] = React.useState(true);

  // const buyButtonSubmit = document.getElementById("buyButtonSubmit");
  
  const handleClickOpen = () => {
    setOpen(true);
    props.onDialogOpen();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseByButton = () => {
    setOpen(false);

    let quantity = document.getElementById("buyQuantity").value;
    try {
      quantity = parseInt(quantity);
      if (quantity !== undefined && quantity > 0 && quantity < 21) {
        props.sendBuyTransaction(quantity);
      }
    } catch (error) {
      console.log("Error parsing quantity: ", error);
    }
  };

  const handleChange = (e) => {
    let i = parseInt(e.target.value);
    if (i <= 0 || i > 20) {
      setErrorMessage("Must be between 0 and 20");
      // buyButtonSubmit.disabled = false;
      setBtnDisabled(true);
    }
    else {
      setErrorMessage("");
      setBtnDisabled(false);
    }
  }

  return (
    <div>
      <ThemeProvider theme={themeConnectButton}>
        <Button 
          id="mintBuyButtonMain"
          disabled={props.mintBuyDisabled}
          variant="contained" size={"large"} color={"primary"} 
          style={{"font-family": "inherit", "font-weight": "bolder", "font-size": "1.4vw", "padding": "5px",
          "width": "12vw",
          "border": "3px solid #006fe6", "border-radius": "10px"}} onClick={handleClickOpen}>BUY</Button>
      </ThemeProvider>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Buy MechaPunkx</DialogTitle>
        <DialogContent>
          {/*<DialogContentText>
            Buy MechaPunkx
          </DialogContentText>*/}
          <TextField
            variant="outlined"
            autoFocus
            margin="dense"
            id="buyQuantity"
            label="Quantity"
            type="number"
            fullWidth
            variant="standard"
            autoComplete="off"
            onChange={e => handleChange(e)}
          />
        <div style={{"color": "red"}}>
          <br></br>
          <br></br>
          {errorMessage}
        </div>
        </DialogContent>
        <DialogActions>
          {/*<Button onClick={handleClose}>Cancel</Button>*/}
          <Button id="buyButtonSubmit" disabled={btnDisabled} onClick={handleCloseByButton}>Ok</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
