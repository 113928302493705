import * as React from 'react';
import { useState, useEffect, useRef, useMemo } from 'react';
//import { MusicNote as MusicNoteIcon } from '@material-ui/icons';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Fade } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import IconButton from '@mui/material/IconButton';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Image from 'material-ui-image'
import CircularProgress from '@mui/material/CircularProgress';

import useSound from 'use-sound';
import themeSong from '../sounds/mighty.mp3';
import ClickSound from '../sounds/click.wav';
import ConnectSound from '../sounds/a-move-select.wav'; // Or alert / notif1 / pop
//import ExplosionSound from '../sounds/a-explosion-1.wav';
import DripSmallSound from '../sounds/drip_small.wav';
import MistakeSound2 from '../sounds/switch_page.wav';
import NotificationBrightSound from '../sounds/notification_bright.wav';
import DisconnectSound from '../sounds/complete.wav';
import GenerateLamboSound from '../sounds/levelup.mp3';
import GlitchSound from '../sounds/glitch.wav';
// import MistakeSound1 from '../sounds/mistake.wav';


import Gallery from './gallery-inventory'
import MintGenerateButton from './mintGenerateButton';
import GlitchButton from './glitchButton';

import MintBuyDialog from './mintBuyDialog';
import { nextUnlockPeriod, claimableBalance } from './mechTokenMirror';
import AccountView from './accountView';
import SampleTokenMetadata from './sampleTokenMetadata';

import MusicNote from '../assets/icons/musicNote.png';
import MusicNoteBlue from '../assets/icons/musicNoteBlue.png';
import EtherscanLogo from '../assets/links/etherscan_logo_circle.png';
import MonkeyIcon from '../assets/links/mpx_logo_monkey.png'
import BirdIcon13 from '../assets/icons/birdIcon13.png'
import LamboIcon from './lamboIcon';
import MechIcon from './mechIcon';
import Banner2 from '../assets/inventory2Small.png'

import MechaPunkx from '../abis/MechaPunkx.json'
import MechToken from '../abis/MechToken.json'
import Lambo from '../abis/Lambo.json'
import Web3 from 'web3';



// Theme for mint Button
const themeMintButton = createTheme({
  status: {
	danger: '#ff0000',
  },
  palette: {
	primary: {
	  main: '#ff0000',
	  darker: '#000000',
	},
	neutral: {
	  main: '#ff0000',
	  contrastText: '#fff',
	},
  },
});

const themeConnectButton = createTheme({
  status: {
	danger: '#ff0000',
  },
  palette: {
	primary: {
	  main: '#007bff',
	  darker: '#004a99'
	},
	neutral: {
	  main: '#007bff',
	  contrastText: '#fff'
	},
	action: {
      disabledBackground: '#003e80',
      // disabled: ''
    }
  },
});


/*
const BoopButton = () => {
	const [play] = useSound(boopSfx);
	return (<button id="boopButton" onClick={play}>Boop!</button>);
}

I did find a way if put inside the functional component:
const [play] = useSound(boopSfx);

<div style={{"opacity": "0"}}><BoopButton/></div>

Then do auto click:
setTimeout(function(){document.getElementById('boopButton').click();}, 3000);
*/

export interface InventoryProps {
	// Theme song control
	toggleThemeSong: () => void;
	stopThemeSong: () => void;
}

export default function Inventory(props: InventoryProps) {

	const { ethereum } = window;

	const [connectButtonText, setConnectButtonText] = React.useState("Connect");
	const [connectButtonDisabled, setConnectButtonDisabled] = React.useState(false);
	const [addressDisplay, setAddressDisplay] = React.useState("");
	const [addressDisplayStyle, setAddressDisplayStyle] = React.useState("none");
	
	const [mintDisabled, setMintDisabled] = React.useState(false);
	const [mintBuyDisabled, setMintBuyDisabled] = React.useState(false);
	const [mintWhitelistDisabled, setMintWhitelistDisabled] = React.useState(false);

	const [claimMechDisabled, setClaimMechDisabled] = React.useState(false);
	const [claimMechButtonText, setClaimMechButtonText] = React.useState("Claim Mech");
	const [claimLamboDisabled, setClaimLamboDisabled] = React.useState(false);
	const [claimLamboButtonText, setClaimLamboButtonText] = React.useState("Claim Lambo");
	const [generateLamboDisabled, setGenerateLamboDisabled] = React.useState(false);
	const [generateLamboText, setGenerateLamboText] = React.useState("Generate Lambo");

	const [glitchDisabled, setGlitchDisabled] = React.useState(false);
	const [glitchText, setGlitchText] = React.useState("GLITCH");

	const [accounts, setAccounts] = React.useState([]);
	// "Check back in ~ 1 min"
	const errorImage = <Tooltip title={"Revealing..."} placement="top-start" open={true}>
		<div style={{"color": "white", "font-size": "6vw", "font-weight": "bold"}}>?</div></Tooltip>

	const [snackbarBackgroundColor, setSnackbarBackgroundColor] = React.useState("");
	const [snackbarMessage, setSnackbarMessage] = React.useState("");
	const [snackbarOpen, setSnackbarOpen] = React.useState(false);
	const [playClick] = useSound(ClickSound, { volume: 0.43 });
	const [play, { stop, sound }] = useSound(themeSong, { interrupt: true });
	// sound.fade(0, 1, 1000); playbackRate: "0.3"
	
	const [playConnect] = useSound(ConnectSound, { volume: 0.2 });
	// const [playExplosion] = useSound(ExplosionSound, { volume: 0.47, playbackRate: "0.5" }); // Use explosion for Burn too
	const [playDripSmall] = useSound(DripSmallSound, { volume: 0.75, playbackRate: "0.35" });
	const [playNotificationBright] = useSound(NotificationBrightSound, { volume: 0.6, playbackRate: "0.8" });
	const [playDisconnect] = useSound(DisconnectSound, { volume: 0.23, playbackRate: "0.8" });

	const mistakeCounter = useRef(0);
	const mistakeCounterTotal = useRef(0);
	const [playMistake2a] = useSound(MistakeSound2, { volume: 1.2, playbackRate: "0.12" }); // volume: 0.8, playbackRate: "0.12"
	const [playMistake2b] = useSound(MistakeSound2, { volume: 1.2, playbackRate: "0.10" });
	const [playMistake2c] = useSound(MistakeSound2, { volume: 1.2, playbackRate: "0.07" });

	// Cycle error function sounds for better experience
	function playMistake2() {
		let mistakes = [playMistake2a, playMistake2b, playMistake2c];
		mistakes[mistakeCounter.current]();
		mistakeCounterTotal.current += 1;
		if (mistakeCounter.current == 2) mistakeCounter.current = 0;
		else mistakeCounter.current += 1;
		let totalCurrent = mistakeCounterTotal.current;
		if (mistakeCounter.current != 0) {
			setTimeout(function() {
				if (totalCurrent === mistakeCounterTotal.current) {
					mistakeCounterTotal.current = 0;
					mistakeCounter.current = 0;
				}
			}, 6000);
		}
	}


	// const [playDisconnect] = useSound(DisconnectSound, { volume: 0.5 }); // good for user denied transaction (if (err.code === 4001))
	const [playGenerateLambo] = useSound(GenerateLamboSound, { volume: 0.4, playbackRate: 0.9 });
	const [playGlitch] = useSound(GlitchSound, { volume: 0.7, playbackRate: 0.7 });

	// const [playMistake1] = useSound(MistakeSound1, { volume: 0.5 });

	const [mechapunkxContract, setMechaPunkxContract] = React.useState(undefined);
	const [mechContract, setMechContract] = React.useState(undefined);
	const [lamboContract, setLamboContract] = React.useState(undefined);

	const [mintCostMech, setMintCostMech] = React.useState("~");
	const [totalMinted, setTotalMinted] = React.useState("~");
	const [amountRemainingMintMech, setAmountRemainingMintMech] = React.useState("~");
	const [amountRemainingBuy, setAmountRemainingBuy] = React.useState("~");
	const [amountRemainingWhitelist, setAmountRemainingWhitelist] = React.useState("~");

	const [balancesMech, setBalancesMech] = React.useState("0");
	const [balancesMechClaimable, setBalancesMechClaimable] = React.useState("0");
	const [balancesLambo, setBalancesLambo] = React.useState("0");
	const [balancesLamboClaimable, setBalancesLamboClaimable] = React.useState("0");

	const [tokensInWallet, setTokensInWallet] = React.useState([]); // NFTs
	const [tokensInWalletLamboYield, setTokensInWalletLamboYield] = React.useState([]); // NFTs yielding LAMBO (disjoint w/ above)

	const [generatingMechPerDay, setGeneratingMechPerDay] = React.useState(0);
	const [generatingLamboPerDay, setGeneratingLamboPerDay] = React.useState(0);
	const [pendingMechDay, setPendingMechDay] = React.useState("~");
	const [pendingMechTooltip, setPendingMechTooltip] = React.useState(0);
	const [pendingLamboTooltip, setPendingLamboTooltip] = React.useState(0);
	const [selectedMechaPunkx, setSelectedMechaPunkx] = React.useState();
	// const accountViewRefreshItems = React.useRef(null)
	const [accountViewRefreshItems, setAccountViewRefreshItems] = React.useState({});

	const [displayTabCountMech, setDisplayTabCountMech] = React.useState(0);
	const [displayTabCountLambo, setDisplayTabCountLambo] = React.useState(0);
	const [displayTabMechSelected, setDisplayTabMechSelected] = React.useState(0);
	const [displayTabLamboSelected, setDisplayTabLamboSelected] = React.useState(0);

	// TODO: update
	// const linkContractMechaPunkx = "https://testnet.arbiscan.io/address/0x30bcc6687584c28b900b2fb0c23dc721ac1bba00";
	// const linkContractMechToken = "https://testnet.arbiscan.io/token/0x1336d760Ac7c716995c35e5a5ED38ba7a2633BeD";
	// const linkContractLambo = "https://testnet.arbiscan.io/token/0x75cf00B47EB209691773Aa5172D3a5b31009587D";

	const linkContractMechaPunkx = "https://arbiscan.io/address/0x9D3eBe1CBc34614b414Bbb6d83F9b0875B8365EE";
	const linkContractMechToken = "https://arbiscan.io/token/0x2d4622181f66616d7ca358a2d7395dc1b5e0dac6";
	const linkContractLambo = "https://arbiscan.io/token/0x593E46216343B33Ef54696F09c5F23Cb4469108f";

	// Gnosis section
	const [placeholderText, setPlaceHolderText] = React.useState("0x...");
	const [claimableMechGnosis, setClaimableMechGnosis] = React.useState("");
	const [pendingMechGnosis, setPendingMechGnosis] = React.useState("");
	const loadingCompleteGnosis = () => {
		return placeholderText === "0x..." || (placeholderText !== "0x..." && claimableMechGnosis !== "");
	}

	useEffect(() => {

		props.stopThemeSong();

		if (ethereum === undefined) {
			alert("Please install the MetaMask wallet extension from https://metamask.io");
		}
		else {
			init();

			ethereum.on('accountsChanged', handleAccountsChanged);
			ethereum.on('chainChanged', handleChainChanged);

			return () => {
				ethereum.removeListener('accountsChanged', handleAccountsChanged);
				ethereum.removeListener('chainChanged', handleChainChanged);
			};
		}
	}, []); // [] => Only run once

	function parseIntCommas(str) {
		return parseInt(str.replace(/,/g, ''));
	}

	const togglePlay = () => {
		console.log(props);
		if (sound.playing()) {
			stop();
		}
		else {
			play();
		}
	}

	//setTimeout(function(){document.getElementById('boopButton').click();}, 3000);

	// Tooltip message for when user can claim MECH token
	// To inform them unlock is 24 hrs or less
	function unlockTimeMessage() {
		let msg = pendingMechTooltip.toString() + " MECH unlocks in " + pendingMechDay.toString();

		if (pendingMechDay === 1) {
			msg += " day or less";
		}
		else {
			msg += " day(s)";
		}

		return msg;
	}

	const handleCloseSnackbar = (event: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}

		setSnackbarOpen(false);
	};

	function errorMessage(msg) {
		setSnackbarBackgroundColor("rgb(50, 50, 50)");
		setSnackbarMessage(msg);
		setSnackbarOpen(true);
		playMistake2();
	}

	function successMessage(msg) {
		setSnackbarBackgroundColor("green");
		setSnackbarMessage(msg);
		setSnackbarOpen(true);
	}

	function handleMetamaskError(error, msg) {
		try {
			let errorObj = JSON.parse(error.toString()
				.replace("execution reverted", "")
				.replace("Error: Internal JSON-RPC error.", ""));
			console.log("Error " + msg + ": ", error);
			if (errorObj.message.includes("Not on list")) {
				errorMessage("No WL spot found, or address already minted.");
			}
			else {
				errorMessage("Error " + msg + ": " + errorObj.message);
			}
		}
		catch (errorParsing) {
			// Can skip these:   Error minting WL (original):  {code: 4001, message: 'MetaMask Tx Signature: User deni
			// console.log("Error parsing error: ", errorParsing);
			console.log("Error " + msg + " (original): ", error);
		}
	}

	function glitchPunk() {

		// TMP
		// sendGlitchRequest(17, "123")
		// return;

		if (accounts.length <= 0 || ethereum.selectedAddress === undefined) {
			errorMessage("Must connect account first.")
		}
		else {

			//playDisconnect();
			//errorMessage("Coming soon.");

			if (tokensInWallet.length === 0) {
				playDisconnect();
				errorMessage("Must have a MechaPunkx to glitch.");
			}
			else if (selectedMechaPunkx === undefined) {
				playDisconnect();
				errorMessage("Must select a MechaPunkx to glitch.");
			}
			else {
				let tokenId = selectedMechaPunkx.src.split('/').slice(-1)[0];
				if (!tokenId.endsWith(".png")) {
					console.error("Unexpected non-img src: ", tokenId);
				}
				else {
					tokenId = tokenId.slice(0, -4); // Chop file extension
				}

				console.log("G L I T C H !?!", tokenId);

				try {
					let b = balancesMech;
					if (b === "~" || b === "") {
						errorMessage("Need 50 MECH Token to GLITCH a MechaPunkx.");
						return;
					}
					b = parseIntCommas(b);
					if (b < 50) {
						errorMessage("Need 50 MECH Token to GLITCH a MechaPunkx.");
						return;	
					}
				}
				catch (error) {
					errorMessage("Failed to get MECH balance: " + error.toString());
					return;
				}

				tryApproveOrGlitch(tokenId);
			}
		}
	}

	async function sendGlitchRequest(tokenId, transactionHash) {
		
		playGlitch();

		//let url = "http://147.182.132.179:8010/glitch/" + tokenId.toString() + "/" + transactionHash
		let url = "/glitch/" + tokenId.toString() + "/" + transactionHash

		fetch(url).then((resp) => {
			if (resp.status === 404) {
				console.log("404, sendGlitchRequest tokenId: ", tokenId);
			}
			else {
				if (resp.status === 200) {
					console.log("Completed glitch: ", tokenId, transactionHash, resp);
					setAccountViewRefreshItems({"refresh": true});
					setTokensInWallet((x) => [...x]);
					successMessage("GL1TCH Success!! M3CH4P*NKX # " + tokenId.toString() + ", check and refresh Trove in 2 minutes!");	
				}
			}
		}).catch((error) => {
			console.log("Caught glitching: ", error);
		});

	}

	async function tryApproveOrGlitch(tokenId) {
		// User has 50 MECH, and the NFT

		let button = document.getElementById('glitchButton');
		setGlitchDisabled(true);

		// Need Approval to spend MECH
		// Check allowance is greater than cost, otherwise, do approve transaction and call self... maybe with depth counter idk		
		// let burnRequirement = 50 * (10**18);
		// let allowance = await mechContract.methods.allowance(ethereum.selectedAddress, ethereum.selectedAddress).call();
		// console.log("MPX allowance of MECH: ", allowance);

		if (false) {
		//if (allowance < burnRequirement) {
			/*
			setGlitchText("Appr0v1ng"); // button.innerText = "Approving";
			const BN = window.web3.utils.BN;
			const maxApproval = (new BN(2)).pow(new BN(256)).sub(new BN(1));

			try {
				await mechContract.methods
					.approve(mechapunkxContract._address, maxApproval)
					.send({ from: ethereum.selectedAddress })
					.once('receipt', (receipt) => {
						console.log("Approved MECH: ", receipt);
						setGlitchText("GLITCH");
						setGlitchDisabled(false); // button.innerText = "MINT";
						
						if (receipt.status !== true) {
							errorMessage("Error: " + receipt.transactionHash);
						}
						else {
							playNotificationBright();
							tryApproveOrGlitch(tokenId);
						}
					})
					.catch(error => {
						setGlitchText("GLITCH"); // button.innerText
						setGlitchDisabled(false);
						handleMetamaskError(error, "approving MECH for GLITCH");
					});
			}
			catch (error) {
				setGlitchText("GLITCH"); // button.innerText = 
				setGlitchDisabled(false);
				handleMetamaskError(error, "approve MECH for GLITCH");
			}
			*/
			//console.error("Allowance: ", allowance);
			setGlitchText("3RR0R"); // button.innerText = 
			setGlitchDisabled(false);
		}
		else {
			console.log("Ready to GLITCH.");
			
			try {
				const BN = window.web3.utils.BN;
				let n1 = new BN(50);
				let n2 = (new BN(10)).pow(new BN(18))
				console.log("N1: ", n1);
				console.log("N2: ", n2);
				console.log("Mul: ", n1.mul(n2))
				let burnRequirement = n1.mul(n2); //n1.multipliedBy(n2);
				//burnRequirement = new BN(burnRequirement)
				let test = await mechContract.methods.burn(burnRequirement)
					.call({ from: ethereum.selectedAddress });
				
				setGlitchDisabled(true);
				let q = mechContract.methods.burn(burnRequirement);
				q.send({ from: ethereum.selectedAddress })
					.once('receipt', async (receipt) => {
						console.log("glitch burn receipt: ", receipt);
						setGlitchDisabled(false);
						setSelectedMechaPunkx(undefined);

						// Update MECH balance after burn
						updateBalanceMech();
						successMessage("GL1TCH!! M3CH4P*NKX pr0c3ss1ng #" + tokenId.toString());
						playGlitch();

						// Call some API with transaction receipt, or periodically monitor for burn, but then can't tell which token
						// Submission form?
						// API is python script that runs the conversion

						sendGlitchRequest(tokenId, receipt.transactionHash)
						
						// Show message to refresh metadata on Trove

					})
					.catch(error => {
						// if ("User denied") {}
						handleMetamaskError(error, "glitch for tokenId: ", tokenId);
						setGlitchDisabled(false);
					});
			}
			catch (error) {	
				handleMetamaskError(error, "glitch for tokenId: ", tokenId);
				setGlitchDisabled(false);
			}
		}
	}

	async function tryApproveOrConvertYield(tokenId) {
		// User has 50 MECH, and the NFT

		let button = document.getElementById('generateLamboButton');
		setGenerateLamboDisabled(true);

		// Need Approval to spend MECH
		// Check allowance is greater than cost, otherwise, do approve transaction and call self... maybe with depth counter idk		
		let burnRequirement = 50 * (10**18);
		let allowance = await mechContract.methods.allowance(ethereum.selectedAddress, mechapunkxContract._address).call();
		// console.log("MPX allowance of MECH: ", allowance);

		if (allowance < burnRequirement) {
			setGenerateLamboText("Approving"); // button.innerText = "Approving";
			const BN = window.web3.utils.BN;
			const maxApproval = (new BN(2)).pow(new BN(256)).sub(new BN(1));

			try {
				await mechContract.methods
					.approve(mechapunkxContract._address, maxApproval)
					.send({ from: ethereum.selectedAddress })
					.once('receipt', (receipt) => {
						console.log("Approved MECH: ", receipt);
						setGenerateLamboText("Generate LAMBO");
						setGenerateLamboDisabled(false); // button.innerText = "MINT";
						
						if (receipt.status !== true) {
							errorMessage("Error: " + receipt.transactionHash);
						}
						else {
							playNotificationBright();
							tryApproveOrConvertYield(tokenId);
						}
					})
					.catch(error => {
						setGenerateLamboText("Generate LAMBO"); // button.innerText
						setGenerateLamboDisabled(false);
						handleMetamaskError(error, "approving MECH for generate LAMBO");
					});
			}
			catch (error) {
				setGenerateLamboText("Generate LAMBO"); // button.innerText = 
				setGenerateLamboDisabled(false);
				handleMetamaskError(error, "approve MECH for generate LAMBO");
			}
		}
		else {
			console.log("Ready to generate LAMBO.");
			
			try {
				let test = await mechapunkxContract.methods.convertYieldToLambo(tokenId)
					.call({ from: ethereum.selectedAddress });
				
				setGenerateLamboDisabled(true);
				let q = mechapunkxContract.methods.convertYieldToLambo(tokenId);
				q.send({ from: ethereum.selectedAddress })
					.once('receipt', async (receipt) => {
						console.log("convertYieldToLambo Receipt: ", receipt);
						setGenerateLamboDisabled(false);
						setSelectedMechaPunkx(undefined);

						let lamboYieldRate = await lamboContract.methods.currentEmissionRate().call().then((res) => Number(res));
						setGeneratingMechPerDay(generatingMechPerDay - 1); // 1 MECH / day / NFT
						setGeneratingLamboPerDay((tokensInWalletLamboYield.length + 1) * lamboYieldRate);

						// Move token from the MECH section to the LAMBO section
						setTokensInWallet((x) => x.filter((item) => item != tokenId));
						setTokensInWalletLamboYield((x) => [...x, tokenId]);

						setDisplayTabCountMech(parseInt(Math.ceil(tokensInWallet.length / 36.0)))
						setDisplayTabCountLambo(parseInt(Math.ceil(tokensInWalletLamboYield.length / 36.0)))

						// Update MECH balance after burn
						updateBalanceMech();

						successMessage("Converted MechaPunkx #" + tokenId.toString() + " to generate daily LAMBO! (-50 MECH)");
						playGenerateLambo();
					})
					.catch(error => {
						// if ("User denied") {}
						handleMetamaskError(error, "converting to LAMBO for tokenId: ", tokenId);
						setGenerateLamboDisabled(false);
					});
			}
			catch (error) {	
				handleMetamaskError(error, "converting to LAMBO for tokenId: ", tokenId);
				setGenerateLamboDisabled(false);
			}
		}
	}

	function convertYieldToLambo() {

		if (accounts.length <= 0 || ethereum.selectedAddress === undefined) {
			errorMessage("Must connect account first.")
		}
		else {

			//playDisconnect();
			//errorMessage("Coming soon.");

			if (tokensInWallet.length === 0) {
				playDisconnect();
				errorMessage("Must have a MechaPunkx to convert.");
			}
			else if (selectedMechaPunkx === undefined) {
				playDisconnect();
				errorMessage("Must select a MechaPunkx to convert.");
			}
			else {
				let tokenId = selectedMechaPunkx.src.split('/').slice(-1)[0];
				if (!tokenId.endsWith(".png")) {
					console.error("Unexpected non-img src: ", tokenId);
				}
				else {
					tokenId = tokenId.slice(0, -4); // Chop file extension
				}

				console.log("Converting to LAMBO yield for token ", tokenId);

				try {
					let b = balancesMech;
					if (b === "~" || b === "") {
						errorMessage("Need 50 MECH Token to convert a MechaPunkx.");
						return;
					}
					b = parseIntCommas(b);
					if (b < 50) {
						errorMessage("Need 50 MECH Token to convert a MechaPunkx.");
						return;	
					}
				}
				catch (error) {
					errorMessage("Failed to get MECH balance: " + error.toString());
					return;
				}

				tryApproveOrConvertYield(tokenId);
			}
		}
	}

	function selectMechaPunkx(e) {

		// TODO: Can do this react style

		if (selectedMechaPunkx !== undefined) {
			selectedMechaPunkx.classList.remove("mpx-inv-img-selected");
		}
		
		e.target.classList.add("mpx-inv-img-selected");
		setSelectedMechaPunkx(e.target);
		playClick();

		// Maybe show hover icon to make it clearer
		// And show # over the item too

	}

	function generateLamboNoneSelected() {
		errorMessage("Need to select a MechaPunkx to convert.");
	}

	function glitchNoneSelected() {
		errorMessage("N33d t0 s3l3ct 4 M3ch4PunkX t0 c0nv3rt.");
	}

	// TODO: better version:
	// https://docs.metamask.io/guide/onboarding-library.html#examples

	const handleAccountsChanged = (accs) => {
		console.log("Account changed");
		updateAccount(accs);
	}

	const handleChainChanged = (chainId) => {
		console.log("Chain changed");
		// Handle the new chain.
		// Correctly handling chain changes can be complicated.
		// We recommend reloading the page unless you have good reason not to.
		// window.location.reload();
		// updateAccount();

		// If user switches network to other than Arbitrum show alert here
		// if (chainId != 421611) {
		if (chainId != 42161) {
			// alert("Switch network to Arbitrum Rinkeby");
			alert("Switch network to Arbitrum One (42161");
			// Could also clear user data, easier if they just refresh
		}
	}

	/*
	useLayoutEffect(() => {
		return () => {
			// Your code here.
		}
	}, [])
	// This is equivalent to ComponentWillUnmount.
	
	but the implementation here might be more correct:
	https://reactjs.org/docs/hooks-effect.html
	see this:
	return function cleanup() {
	*/

	// Display connected account
	async function updateAccount(accs) {
		console.log("Trying update accounts: ", accs === undefined, accs);
		if (accs !== undefined && accs.length > 0 && accs[0] !== undefined) {
			setConnectButtonText("Connected");
			setConnectButtonDisabled(true);
			setAddressDisplay(ethereum.selectedAddress);
			setAddressDisplayStyle("block");
			// if (accs[0] != accounts[0]) {
			updateUserData();
			playConnect();
			// }
		}
		else {
			setAddressDisplay('No account.');
			setConnectButtonText("Connect");
			setConnectButtonDisabled(false);
			setAddressDisplayStyle("none");
			clearUserData();
		}
	}

	async function connectMetamask() {
		if (accounts === undefined || accounts.length === 0) {
			try {
				let id = ethereum.networkVersion; // 42161 Arbitrum

				// if (id !== 421611) {
				if (id !== 42161) { // Arbitrum One
					let network = await switchNetworkArbitrum();
					if (!network) return;
				}

				// Will open the MetaMask UI
				// Disable button while the request is pending
				setConnectButtonDisabled(true);
				let accs = await ethereum.request({ method: 'eth_requestAccounts' })
					.catch((error) => {
						console.log("Error requesting accounts: ", error);
					});

				/*
				.then(handleAccountsChanged)
				    .catch((error) => {
				      if (error.code === 4001) {
				        // EIP-1193 userRejectedRequest error
				        console.log('Please connect to MetaMask.');
				      } else {
				        console.error(error);
				      }
				    });
				*/

				// Make sure an update occurred
				if (accs !== undefined && accounts !== undefined && 
					(accs.length !== accounts.length || !accs.every(function(value, index) { return value === accounts[index]}))) {
					
					setAccounts(accs);
					updateAccount(accs);
				}
			}
			catch (error) {
				console.error(error);
				setConnectButtonDisabled(false);
			}
		}
	}

	async function switchNetworkArbitrum() {
		try {
		  await ethereum.request({
			method: 'wallet_switchEthereumChain',
			// params: [{ chainId: '0x66EEB' }] // Arbitrum Testnet Rinkeby (421611)
			params: [{ chainId: '0xA4B1' }] // Arbitrum One
			// params: [{ chainId: '0xf00' }], // Eth mainnet
		  })
		  return true;
		} catch (switchError) {
		  // This error code indicates that the chain has not been added to MetaMask.
		  if (switchError.code === 4902) {
		  	//throw Error("Arbitrum not added to wallet");

		 	// alert("Add Network [Arbitrum Rinkeby], see: https://chainlist.org/chain/421611");
		 	alert("Add Network [Arbitrum One], see: https://chainlist.org/chain/42161")
		 	console.log("Error switching to Arbitrum", switchError);

			// try {
			//   await ethereum.request({
			// 	method: 'wallet_addEthereumChain',
			// 	params: [
			// 	  {
			// 		chainId: '0xf00',
			// 		chainName: '...',
			// 		rpcUrls: ['https://...']
			// 	  },
			// 	],
			//   });
			// } catch (addError) {
			//   // handle "add" error
			// }
			return false;
		  }
		  else {
		  	handleMetamaskError(switchError);
		  	return true;
		  }
		}
	}

	async function updateBalanceMech() {
		let addr = ethereum.selectedAddress;
		const BN = window.web3.utils.BN;
		let mechBalance = await mechContract.methods.balanceOf(addr).call();
		// console.log("Mech balance str: ", mechBalance);
		let b = new BN(mechBalance).div((new BN(10)).pow(new BN(18)));
		setBalancesMech(b.toNumber().toLocaleString());
		return b.toNumber();
	}

	async function updateBalanceMechFromClaim() {
		let prevBalance = parseIntCommas(balancesMech);
		let mechBalance = await updateBalanceMech();
		if (prevBalance >= mechBalance) {
			console.log("Error, expected MECH balance to increase: ", prevBalance, mechBalance);
		}
		else {
			let received = mechBalance - prevBalance;
			successMessage("Successfully claimed: " + received.toLocaleString() + " MECH");
			// Assumes all yield MECH
			updateClaimableMech(tokensInWallet);
			playNotificationBright();
		}
	}
	
	async function updateBalanceLambo() {
		let addr = ethereum.selectedAddress;
		const BN = window.web3.utils.BN;
		let lamboBalance = await lamboContract.methods.balanceOf(addr).call();
		// console.log("LAMBO balance str: ", lamboBalance);
		let b = new BN(lamboBalance).div((new BN(10)).pow(new BN(18)));
		setBalancesLambo(b.toNumber().toLocaleString());
		return b.toNumber();
	}

	async function updateBalances() {
		updateBalanceMech();
		updateBalanceLambo();
	}

	async function updateClaimableMech(tokensGeneratingMech) {
		
		if (tokensGeneratingMech.length > 0) {

			let addr = ethereum.selectedAddress;
			let maxEarned = await mechContract.methods.maxEarned().call().then((res) => parseInt(res));

			let next = nextUnlockPeriod(maxEarned);
			let unlockDistance = next[0] - maxEarned;
			let nextAmount = next[1];
			if (unlockDistance <= 0) {
				console.log("Check unlock distance: ", unlockDistance, maxEarned, next);
				unlockDistance = "~";
			}
			setPendingMechDay(unlockDistance);

			// If user has many tokens, skip sum so metamask doesn't hate us
			// Could do a partial sum, but whatever
			if (tokensGeneratingMech.length >= 50) {
				setBalancesMechClaimable("~");
				setPendingMechTooltip("~");
			}
			else {
				
				let pending = [];
				let claimed = [];
				let claimable = [];
				let available = []; // Actual amount returned if calling claim now

				for (let i=0; i < tokensGeneratingMech.length; i++) {
					
					let tokenId = tokensGeneratingMech[i];
					let p = await mechContract.methods.pendingBalance(tokenId).call({ from: addr }).then((res) => parseInt(res));
					pending[i] = p;
					claimed[i] = maxEarned - pending[i];
					claimable[i] = parseInt(claimableBalance(maxEarned, claimed[i]));
					// console.log("Claimable balance: ", claimable[i], tokenId);
					if (claimable[i] > p) {
						available[i] = p;
					}
					else {
						available[i] = claimable[i];
					}
					console.log("Token [" + tokenId.toString() + "] available: " + available[i] + 
						", claimable: " + claimable[i] + ", pending: " + pending[i]);
				}
				
				const totalAvailable = available.reduce((prev, a) => prev + a, 0);
				const totalPending = pending.reduce((prev, a) => prev + a, 0);
				console.log("Total MECH available: ", totalAvailable, " pending: ", totalPending);

				// Better to show X amount, but Y amount will unlock in Z days (next unlock in X days)
				// Or could just say next distribution in X days, then on that day, amount appears in their claimable
				// I think nextAmount is always > pending at the end of the next interval, so sum(pending) unlocks at next interval
				setBalancesMechClaimable(totalAvailable);

				// Unlock amount has to exclude currently available I think
				let locked = totalPending - totalAvailable;
				if (locked < 0) {
					console.log("Error calculating locked.");
					locked = 0;
				}
				setPendingMechTooltip(locked); 
			}
		}
	}

	async function updateClaimableLambo(tokensYieldingLambo) {
		
		let totalLambo = 0;

		// For each LAMBO yielding NFT
		// Must be LAMBO yielding or reverts
		for (let i=0; i < tokensYieldingLambo.length; i++) {
			let tokenId = tokensYieldingLambo[i];
			let claimable = await lamboContract.methods.amountCanClaim(tokenId).call().then((res) => Number(res));
			totalLambo += claimable;
			console.log("Token: [" + tokenId.toString() + "]", "claimable LAMBO: [" + claimable.toString() + "]");
		}

		console.log("Total claimable LAMBO: ", totalLambo);
		if (totalLambo !== balancesLamboClaimable) {
			setBalancesLamboClaimable(totalLambo);
		}
	}


	async function getNumMintWhitelist() {
		
		return 200; // 127
		/*
		let numWhitelist = 0;
		
		try {
			let { error, events } = await mechapunkxContract.getPastEvents('AllowlistMint', {
				// fromBlock: 11860176,
				fromBlock: 15203263,
				toBlock: 'latest'
			});
			console.log("Got back events: ", events);
			console.log("Got back errors: ", error);
			if (events === undefined || (Array.isArray(events) && events.length === 0)) {
				return 0;
			}

			// if (events !== undefined) console.log("getNumMintWhitelist, events: ", events);
			// if (error !== undefined) console.log("getNumMintWhitelist, error: ", error);
			// let xs = events;
			// if (!Array.isArray(events)) xs = [events];
			// numWhitelist = xs.reduce((prev, event) => prev + parseInt(event.returnValues.quantity), 0);
		}
		catch (error) {
			console.log("Error getting numMintedWhitelist: ", error);
		}

		return numWhitelist;
		*/
	}

	async function updateTotalNumMinted() {
		let numMinted = await mechapunkxContract.methods.numMinted().call().then((res) => Number(res));
		setTotalMinted(numMinted.toLocaleString());
		return numMinted;
	}

	async function updateNumMintWhitelist() {
		let amountWhitelist = await getNumMintWhitelist();
		setAmountRemainingWhitelist((230 - amountWhitelist).toString());
		return amountWhitelist;
	}

	async function updateNumMintSale() {
		let saleRemaining = await mechapunkxContract.methods.saleRemaining().call().then((res) => Number(res));
		let amountBuy = 200 - saleRemaining;
		setAmountRemainingBuy((200 - amountBuy).toString());
		return amountBuy;
	}

	// async function updateNumMintMech() {
	// }

	async function updateUserData() {
		
		if (mechapunkxContract === undefined) {
			errorMessage("Please refresh the page and reconnect.");
			return;
		}

		let numMinted = await updateTotalNumMinted();
		//let remaining = 7848 - parseInt(numMinted);
		let amountBuy = await updateNumMintSale();
		let amountWhitelist = await updateNumMintWhitelist();
		let amountClaim = numMinted - (amountBuy + amountWhitelist);
		setAmountRemainingMintMech((7418 - amountClaim).toLocaleString()); // technically wrong since purchased amount is optional

		let mintCost = await mechapunkxContract.methods.mintCost().call().then((res) => Number(res));
		setMintCostMech(mintCost);

		// Balances
		updateBalances();

		// Split tokens by generating MECH or LAMBO
		let generatingMech = [];
		let generatingLambo = [];	
		let mechapunkxOwned = await mechapunkxContract.methods.tokensInWallet(ethereum.selectedAddress).call();
		
		for (let i=0; i < mechapunkxOwned.length; i++) {
			let tok = mechapunkxOwned[i];
			let yieldsLambo = await mechapunkxContract.methods.yieldsLambo(tok).call({ from: ethereum.selectedAddress });
			if (yieldsLambo) {
				generatingLambo.push(tok);
			}
			else generatingMech.push(tok);
		}

		setTokensInWalletLamboYield(generatingLambo);
		setTokensInWallet(generatingMech);

		setDisplayTabCountMech(parseInt(Math.ceil(generatingMech.length / 36.0)))
		setDisplayTabCountLambo(parseInt(Math.ceil(generatingLambo.length / 36.0)))

		// setTokensInWallet(mechapunkxOwned);
		// setTokensInWallet([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26]);
		if (generatingMech.length > 0 || generatingLambo.length > 0) {
			console.log("User has tokens: ", generatingMech, generatingLambo);
			document.getElementById("mpx-inv-overlay").style.display = "none";
		}
		
		// Alternatively, check which yield lambo by contract / server / event logs
		/*
		contract.getPastEvents('LamboYield', {
			//filter: {}, //filter: {_operator: [20,23], _value:'0'}, // Using an array means OR: e.g. 20 or 23
			fromBlock: startBlock,
			toBlock: 'latest'
			}, function(error, events) {
			handleEvents(error, events, contract);
		});
		*/

		if (generatingMech.length > 0) {
			setGeneratingMechPerDay(generatingMech.length); // 1 MECH / day / NFT
			updateClaimableMech(generatingMech);
		}

		if (generatingLambo.length > 0) {
			let lamboYieldRate = await lamboContract.methods.currentEmissionRate().call().then((res) => Number(res));
			setGeneratingLamboPerDay(generatingLambo.length * lamboYieldRate);
			updateClaimableLambo(generatingLambo);
		}
	}

	function clearUserData() {

	}

	/* Handles after mint, sale, whitelist */
	function updateNewMechaPunkxFromReceipt(receipt) {
		if (receipt.status !== true) {
			errorMessage("Error getting MechaPunkx " + receipt.message);
		}
		else {
			let tokensMinted = [];
			let events = receipt.events.Transfer;
			console.log(receipt.events);

			// Looks like events is the object for a single mint, and an array for multiple WTF

			if (Array.isArray(events)) {
				for (let i=0; i < events.length; i++) {
					let e = events[i];
					if (e.event === "Transfer") {
						tokensMinted.push(e.returnValues.tokenId);
						// e.returnValues.to; // make sure this is the user
					}
				}
			}
			else {
				tokensMinted.push(events.returnValues.tokenId);
			}

			console.log("Minted token IDs: ", tokensMinted);
			
			// Update display, Newly generated does not yield LAMBO
			updateTotalNumMinted();
	
			if (tokensMinted.length > 0) {
				setGeneratingMechPerDay(tokensInWallet.length + tokensMinted.length); // 1 MECH / day / NFT
				setTokensInWallet((x) => [...x, ...tokensMinted]);

				setDisplayTabCountMech(parseInt(Math.ceil((tokensInWallet.length + tokensMinted.length) / 36.0)))
				setDisplayTabCountLambo(parseInt(Math.ceil(tokensInWalletLamboYield.length / 36.0)))

				successMessage("Minted " + tokensMinted.length.toString() + " new MechaPunkx, yahoooooo!");
				// Check if image revealed in 5 seconds
				setTimeout(function() { check404updateImages(tokensMinted, 0); }, 8000);
			}
		}
	}


	// For testing
	// useEffect(() => {
	// 	setTimeout(function() { check404updateImages([7], 0); }, 7000);
	// }, []);

	/* 
	If NFT image not revealed yet, ping every 20 seconds for 2 minutes to check, then trigger update here
	
	NOTE: Only checks for brand new reveals / 404's, not all images
	*/
	async function check404updateImages(tokenIds, depth) {

		let interval = 20000;
		if (depth > 4) return;

		for (let i=0; i < tokenIds.length; i++) {
			
			let url = "https://lambo.lol/images/mechapunkx/" + tokenIds[i].toString() + ".png";

			// Local test
			// let url = "/mechapunkx/images/" + tokenIds[i].toString() + ".png"
			
			// , {mode: 'no-cors'}
			fetch(url).then((resp) => {
				if (resp.status === 404) {
					console.log("404, waiting on reveal of tokenId: ", tokenIds[i].toString());
					setTimeout(function() { check404updateImages([tokenIds[i]], depth + 1) }, interval);
				}
				else {
					console.log("Image revealed, tokenId: ", tokenIds[i], "resp status: ", resp.status);
					console.log("Resp: ", resp);
					if (resp.status === 200) {
						console.log("Revealing: ", tokenIds[i]);
						setAccountViewRefreshItems({"refresh": true});
						setTokensInWallet((x) => [...x]);
						successMessage("MechaPunkx " + tokenIds[i].toString() + " revealed, see account at the bottom!");	
					}
				}
			}).catch((error) => {
				console.log("Caught: ", error);
				setTimeout(function() { check404updateImages([tokenIds[i]], depth + 1) }, interval);
			});
		}
		
	}

	/* Returns images displayed for Generating MECH per day and generating LAMBO per day */
	function getTokenImageSmaller(tokenId, canSelect) {
		// NOTE: the id attribute is imporant, used by convertYieldToLambo to know which token is clicked
		// Changed to use the src right now, passing the inner image
		return (
			<ImageListItem id={"mpx-inv-img-" + tokenId.toString()} key={"mpx-inv-img-" + Math.random().toString()} //tokenId.toString()} 
				className={canSelect ? "mpx-inv-img-selectable" : "mpx-inv-img-selectable-nohover"}
				onClick={canSelect ? selectMechaPunkx : () => {}}
			>
				<Image  
					alt=""
					src={ "https://lambo.lol/images/mechapunkx-small/" + tokenId.toString() + ".png" }
					srcSet={ "https://lambo.lol/images/mechapunkx-small/" + tokenId.toString() + ".png" }
					// src={ (tokenId == 1) ? "https://lambo.lol/1234.png" : BirdIcon13}
					// srcSet={ (tokenId == 1) ? "https://lambo.lol/1234.png" : BirdIcon13}
					// style={{"background-color": "#1e1e1e"}}
					style={{"background-color": "#1e1e1e", "color": "white", "border-radius": "4px"}}
					disableSpinner={true}
					errorIcon={errorImage}
					animationDuration={2500}
					loading="lazy"
				/>
			</ImageListItem>
		);
	}

	async function mintWhitelist() {
		
		if (accounts.length <= 0 || ethereum.selectedAddress === undefined) {
			errorMessage("Must connect account first.");
		}
		else {
			// errorMessage("Coming soon.");

			// Call mapping? 

			// WL check
			// ethereum.selectedAddress
			if (false) {
				errorMessage("Not found on WL: " + ethereum.selectedAddress);
			}
			else {
				try {
					console.log("Mint WL for [" + ethereum.selectedAddress + "]");
					let test = await mechapunkxContract.methods.mintAllowlist()
						.call({ from: ethereum.selectedAddress });

					setMintWhitelistDisabled(true);
					let q = mechapunkxContract.methods.mintAllowlist()
					q.send({ from: ethereum.selectedAddress })
						.once('receipt', (receipt) => {
							console.log("mintWhitlist Receipt: ", receipt);
							setMintWhitelistDisabled(false);
							updateNumMintWhitelist();
							updateNewMechaPunkxFromReceipt(receipt);
							props.stopThemeSong();
							play();
						})
						.catch(error => {
							// if ("User denied") {}
							handleMetamaskError(error, "minting WL");
							setMintWhitelistDisabled(false);
						});
				}
				catch (error) {	
					handleMetamaskError(error, "minting WL");
					setMintWhitelistDisabled(false);
				}
			}
		}
		
	}

	async function mintSale(quantity) {
		if (quantity <= 0 || quantity > 20) {
			errorMessage("Quantity must be between 1 and 20");
		}
		else if (accounts.length <= 0 || ethereum.selectedAddress === undefined) {
			errorMessage("Must connect account first.")
		}
		else {
			try {
				let price = 100000000000000000 * quantity;
				console.log("Try mint quantity: ", quantity);
				let test = await mechapunkxContract.methods.mintSale(quantity)
					.call({ from: ethereum.selectedAddress, value: price });

				setMintBuyDisabled(true);
				let q = mechapunkxContract.methods.mintSale(quantity);
				q.send({ from: ethereum.selectedAddress, value: price })
					.once('receipt', (receipt) => {
						console.log("mintSale Receipt: ", receipt);
						setMintBuyDisabled(false);
						updateNumMintSale();
						updateNewMechaPunkxFromReceipt(receipt);
						props.stopThemeSong();
						play();
					})
					.catch(error => {
						handleMetamaskError(error, "minting sale");
						setMintBuyDisabled(false);
					});
			}
			catch (error) {	
				handleMetamaskError(error, "minting sale");
				setMintBuyDisabled(false);
			}
		}
	}

	async function mintUsingMech() {

		if (accounts.length <= 0 || ethereum.selectedAddress === undefined) {
			errorMessage("Must connect account first.");
		}
		else {
			// Approval to spend MECH
			// Check allowance is greater than cost, otherwise, do approve transaction and call self... maybe with depth counter idk		
			let allowance = await mechContract.methods.allowance(ethereum.selectedAddress, mechapunkxContract._address).call();
			// console.log("MPX allowance of MECH: ", allowance);

			let button = document.getElementById('mintUsingMechButton');
			setMintDisabled(true);

			if (allowance < mintCostMech) {

				button.innerText = "Approving";
				const BN = window.web3.utils.BN;
				const maxApproval = (new BN(2)).pow(new BN(256)).sub(new BN(1));

				try {
					await mechContract.methods
						.approve(mechapunkxContract._address, maxApproval)
						.send({ from: ethereum.selectedAddress })
						.once('receipt', (receipt) => {
							console.log("Approved MECH: ", receipt);
							
							button.innerText = "MINT";
							setMintDisabled(false);
							
							if (receipt.status !== true) {
								errorMessage("Error: " + receipt.transactionHash);
							}
							else {
								playNotificationBright();
							}
						})
						.catch(error => {
							button.innerText = "MINT";
							setMintDisabled(false);
							handleMetamaskError(error, "approving MECH for mint claim");
						});
				}
				catch (error) {
					button.innerText = "MINT";
					setMintDisabled(false);
					handleMetamaskError(error, "mint w/ MECH");
				}
			}
			else {

				let q =  mechapunkxContract.methods.mintNFT();

				try {
					let test = await mechapunkxContract.methods.mintNFT().call({ from: ethereum.selectedAddress });
				
					q.send({ from: ethereum.selectedAddress })
						.once('receipt', (receipt) => {
							console.log("Mint using MECH Receipt: ", receipt);
							setMintDisabled(false);

							if (receipt.status === true) {

								playNotificationBright();
								updateNewMechaPunkxFromReceipt(receipt);
								
								try {
									updateBalanceMech();
									let prevNum = parseIntCommas(amountRemainingMintMech);
									setAmountRemainingMintMech((prevNum - 1).toLocaleString());
								} 
								catch (error) {
									console.log("Error parsing amountRemainingMintMech: ", error);
								}
							}
							else {
								errorMessage("Something went wrong, see console: " + receipt.transactionHash);
							}
						})
						.catch(error => {
							handleMetamaskError(error, "minting w/ MECH");
							setMintDisabled(false);
						});

				} 
				catch (error) {
					
					console.log("Error minting w/ MECH: ", error.message, error);
					setMintDisabled(false);

					if (error.message.includes("Not enough MECH")) {
						errorMessage("Minting requires " + mintCostMech.toString() + " MECH");
					}
					else {
						errorMessage("Error minting w/ MECH [" + error.code + "]: " + error.message);
					}
				}
			}
		}
	}

	async function claimMech() {
		if (accounts.length <= 0 || ethereum.selectedAddress === undefined) {
			errorMessage("Must connect account first.");
		}
		else {
			if (tokensInWallet.length === 0) {
				errorMessage("Must have MechaPunkx to earn MECH");
			}
			else if (balancesMechClaimable === 0) {
				errorMessage("No MECH available right now");
			}
			else {
				try {
					let test = await mechapunkxContract.methods.claimMechTokenAll().call({ from: ethereum.selectedAddress });

					setClaimMechDisabled(true);
					setClaimMechButtonText("Claiming");

					let q = mechapunkxContract.methods.claimMechTokenAll();
					q.send({ from: ethereum.selectedAddress })
						.once('receipt', (receipt) => {
							console.log("Claim MECH Receipt: ", receipt);
							setClaimMechDisabled(false);
							setClaimMechButtonText("Claim Mech");
							if (receipt.status === true) {
								updateBalanceMechFromClaim();
							}
							else {
								errorMessage("Error claiming MECH " + receipt.message);
							}
						})
						.catch(error => {
							handleMetamaskError(error, "claiming MECH");
							setClaimMechDisabled(false);
							setClaimMechButtonText("Claim Mech");
						});
				}
				catch (error) {	
					setClaimMechDisabled(false);
					setClaimMechButtonText("Claim Mech");
					handleMetamaskError(error, "claiming MECH");
				}
			}
		}
	}

	async function claimLambo() {

		if (accounts.length <= 0 || ethereum.selectedAddress === undefined) {
			errorMessage("Must connect account first.");
		}
		else if (tokensInWalletLamboYield.length === 0) {
			errorMessage("Need to convert a MechaPunkx first to yield LAMBO (green button).")
		}
		else if (balancesLamboClaimable <= 0) {
			errorMessage("No LAMBO available right now");
		}
		else {
			
			setClaimLamboDisabled(true);
			setClaimLamboButtonText("Claiming");

			try {
				let test = await lamboContract.methods.claimAll().call({ from: ethereum.selectedAddress });

				let q = lamboContract.methods.claimAll();
				q.send({ from: ethereum.selectedAddress })
					.once('receipt', (receipt) => {
						console.log("Claim LAMBO Receipt: ", receipt);
						setClaimLamboDisabled(false);
						setClaimLamboButtonText("Claim Lambo");
						if (receipt.status === true) {
							updateBalanceLambo();
							playNotificationBright();
							// updateClaimableLambo();
							setBalancesLamboClaimable(0);
							// successMessage("Successfully claimed: " + received.toLocaleString() + " LAMBO");
							successMessage("Success, claimed LAMBO!");
						}
						else {
							errorMessage("Error claiming LAMBO " + receipt.message);
						}
					})
					.catch(error => {
						setClaimLamboDisabled(false);
						setClaimLamboButtonText("Claim Lambo");
						handleMetamaskError(error, "claiming LAMBO");
					});
			}	
			catch (error) {	
				setClaimLamboDisabled(false);
				setClaimLamboButtonText("Claim Lambo");
				handleMetamaskError(error, "claiming LAMBO");
			}
		}
	}

	async function init() {
		if (window.web === undefined) {

  			// Prev
			window.web3 = new Web3(window.ethereum);
			
			const networkId = await window.web3.eth.net.getId();
			// const networkData = MechaPunkx.networks[networkId];

			// const networkData = MechaPunkx.networks["42161"];
			// const networkData = MechaPunkx.networks["421611"]; // arbitrum rinkeby testnet 
			// console.log("Available: ", MechaPunkx.networks); // 421611, arbitrum_testnet

			console.log("Available networks: ", MechaPunkx.networks);
			console.log("Network Id: ", networkId);

			if (networkId === 42161) {
				// if (networkData) {

				if (mechapunkxContract === undefined) {
					//const address = networkData.address; 
					// testnet rinkeby
					// const address = "0x30bcc6687584c28b900b2fb0c23dc721ac1bba00";
					// const contract = new window.web3.eth.Contract(MechaPunkx.abi, address);
					// const mechContract = new window.web3.eth.Contract(MechToken.abi, "0x1336d760Ac7c716995c35e5a5ED38ba7a2633BeD");
					// const lamboContract = new window.web3.eth.Contract(Lambo.abi, "0x75cf00B47EB209691773Aa5172D3a5b31009587D");
					
					const address = "0x9D3eBe1CBc34614b414Bbb6d83F9b0875B8365EE";
					const contract = new window.web3.eth.Contract(MechaPunkx.abi, address);
					const mechContract = new window.web3.eth.Contract(MechToken.abi, "0x2d4622181f66616d7ca358a2d7395dc1b5e0dac6");
					const lamboContract = new window.web3.eth.Contract(Lambo.abi, "0x593E46216343B33Ef54696F09c5F23Cb4469108f");
					
					setMechaPunkxContract(contract);
					setMechContract(mechContract);
					setLamboContract(lamboContract);
				}
			}
			else {
				console.log("Change network to arbitrum");
			}
		}
	}

	let lastAddress = '';
	let connected = false;

	async function addressUpdate() {
		// if (!connected) {
		let addressElement = document.getElementById("addressInput");
		let maybeAddress = addressElement.value;
		if (maybeAddress != lastAddress) {
			lastAddress = maybeAddress;
			if (maybeAddress.length < 40) {
				if (maybeAddress != '') {
					console.log("Not an address: ", maybeAddress);
				}
			}
			else {
				if (window.web3.utils.isAddress(maybeAddress)) {
					addressElement.classList.remove('addressInputFailure');
					// addressInput.disabled="disabled";

					setTimeout(function() {
						addressElement.classList.add('addressInputSuccess');
					}, 450);
					
					setTimeout(function() {
						addressElement.classList.remove('addressInputSuccess');
					}, 3000);

					// connected = true;
					//updateTables(globalPriceData, false);
					// updatePricesAndTables();
					updateMechClaimableGnosis(maybeAddress);
				}
				else {
					console.log("Bad address: ", maybeAddress);
					addressElement.classList.add('addressInputFailure');
					
					setTimeout(function() {
						addressElement.classList.remove('addressInputFailure');
					}, 2500);
				}
			}
		}
		// }
	}

	async function updateMechClaimableGnosis(addr) {

		// lookup address from input, but use connection
		
		// Ensure connected account
		if (addressDisplay === "") {
			errorMessage("Connect account first (connect button above)")
		}
		else {

			// Copypasta from above
			console.log("Checking MECH available");

			// Split tokens by generating MECH or LAMBO
			let generatingMech = [];
			let generatingLambo = [];	
			let mechapunkxOwned = await mechapunkxContract.methods.tokensInWallet(addr).call();
			
			console.log("Found tokens (gnosis): ", mechapunkxOwned);

			for (let i=0; i < mechapunkxOwned.length; i++) {
				let tok = mechapunkxOwned[i];
				let yieldsLambo = await mechapunkxContract.methods.yieldsLambo(tok).call({ from: ethereum.selectedAddress });
				if (yieldsLambo) {
					generatingLambo.push(tok);
				}
				else generatingMech.push(tok);
			}

			if (generatingMech.length > 0) {

				let maxEarned = await mechContract.methods.maxEarned().call().then((res) => parseInt(res));

				let next = nextUnlockPeriod(maxEarned);
				let unlockDistance = next[0] - maxEarned;
				let nextAmount = next[1];
				if (unlockDistance <= 0) {
					console.log("Check unlock distance: ", unlockDistance, maxEarned, next);
					unlockDistance = "~";
				}
				//setPendingMechDay(unlockDistance);

				// If user has many tokens, skip sum so metamask doesn't hate us
				// Could do a partial sum, but whatever
				if (generatingMech.length >= 50) {
					console.log("More than 50 mechas, cancelling.");
					// setBalancesMechClaimable("~");
					// setPendingMechTooltip("~");
				}
				else {
					
					let pending = [];
					let claimed = [];
					let claimable = [];
					let available = []; // Actual amount returned if calling claim now

					for (let i=0; i < generatingMech.length; i++) {
						
						let tokenId = generatingMech[i];
						let p = await mechContract.methods.pendingBalance(tokenId).call({ from: addr }).then((res) => parseInt(res));
						pending[i] = p;
						claimed[i] = maxEarned - pending[i];
						claimable[i] = parseInt(claimableBalance(maxEarned, claimed[i]));
						// console.log("Claimable balance: ", claimable[i], tokenId);
						if (claimable[i] > p) {
							available[i] = p;
						}
						else {
							available[i] = claimable[i];
						}
						console.log("Token [" + tokenId.toString() + "] available: " + available[i] + 
							", claimable: " + claimable[i] + ", pending: " + pending[i]);
					}
					
					const totalAvailable = available.reduce((prev, a) => prev + a, 0);
					const totalPending = pending.reduce((prev, a) => prev + a, 0);
					console.log("Total MECH available: ", totalAvailable, " pending: ", totalPending);

					// Better to show X amount, but Y amount will unlock in Z days (next unlock in X days)
					// Or could just say next distribution in X days, then on that day, amount appears in their claimable
					// I think nextAmount is always > pending at the end of the next interval, so sum(pending) unlocks at next interval
					// setBalancesMechClaimable(totalAvailable);

					// Unlock amount has to exclude currently available I think
					let locked = totalPending - totalAvailable;
					if (locked < 0) {
						console.log("Error calculating locked.");
						locked = 0;
					}
					// setPendingMechTooltip(locked);
					setClaimableMechGnosis(totalAvailable) // claimable now
					setPendingMechGnosis(locked); // tooltip, accumulating
				}
			}



		}

	}

  return (
	<div className="mechapunkx-inventory mpx-inv-text">
		<div className="mpx-inv-title noselect" style={{"color": "#000eff", "padding-left": "2vw"}}>
		  <span style={{"color": "#e81a00"}}>MINT</span>
		</div>
		<div className="mechapunkx-inventory-top" style={{"background-color": ""}}>
			<div style={{"padding-left": "2vw", "padding-right": "2vw", "padding-bottom": "2vw"}}>
				<div style={{"display": "flex", "flex-direction": "column", "justify-content": "space-between", 
					"background-color": "black", "border-radius": "8px"}}>
					
					<Image 
						src={Banner2}
						style={{"background-color": "#1e1e1e"}}
						imageStyle={{height: "auto", borderRadius: "0.75rem 0.75rem 0.75rem 0.75rem"}}
						disableSpinner={true}
						animationDuration={2500}
						aspectRatio={"4/1"}
					/>

					{/*mechapunkx-inventory-top-inner*/}
					<div className="noselect " style={{"padding-left": "2vw", "padding-right": "2vw", "padding-top": "2vw", "padding-bottom": "2vw"}}>
						{/*#ffe500*/}
						<p>
						The <span style={{"color": "green"}}>MechaPunkx Mint</span> has controls to power your collection, and shows your NFT's. 
						Claim <span style={{"color": "red"}}>MECH</span> token daily for each MECHAPUNKX you own. Then use it to mint more MECHAPUNKX. 
						The cost to mint in <span style={{"color": "red"}}>MECH</span> will increase +1 per day until reaching 100 MECH.
						For more details, see the <RouterLink to="/mechapunkx/tokens" onClick={() => playClick()} style={{"color": "#2196f3"}}>
						Tokens page</RouterLink>, or ask for help in discord.
						</p>
						<br></br>
						To mint the most NFT's possible for free, see the 
							<RouterLink to="/mechapunkx/tokens" onClick={() => playClick()} style={{"color": "#2196f3"}}> Max Reward Schedule</RouterLink> (bottom).
					
					</div>
					{/*<div style={{"display": "flex", "flex-direction": "row", "justify-content": "start", "text-align": "left", "padding-left": "1vw"}}>
						<a href="https://etherscan.io/" target="_blank">
							<div className="text-small" style={{"display": "flex", "flex-direction": "row", "align-items": "center"}}>
								
								<div style={{"padding-right": "1vw", "padding-left": "1vw", "padding-bottom": "1vw", "padding-top": "1vw", 
							"display": "flex", "justify-content": "center", "align-items": "center"}}>
									<img src={EtherscanLogo} height="25px" width="25px" ></img>
								</div>
								<div style={{"color": "lightgray", "text-align": "center"}}>0x1234</div>
							</div>
						</a>
					</div>*/}
				</div>
			</div>
		</div>
		<div style={{"padding": "2vw"}}>
			{/*<div style={{"padding": "2vw", "border-radius": "8px"}} >*/}
				<div className="mechapunkx-inventory-account" style={{"background-color": "black", 
					"padding": "2vw", "border-radius": "8px", "display": "flex", "flex-direction": "column", "margin-bottom": "12px"}}>			    	
					
					{/*Account + Connect Button */}

					{/*mechapunkx-section-spacer*/}
					<div className="" style={{"width": "100%", 
						"display": "flex", "flex-direction": "row", "justify-content": "space-between", "align-items": "center"}}>
						<div style={{"display": "flex", "flex-direction": "row", "align-items": "center"}}>
							<div style={{"padding-right": "2vw", "padding-top": "0.7vw", "padding-bottom": "0.7vw"}}>
								Account:
							</div>
							{/*<div style={{"padding-top": "1vw", "padding-bottom": "1vw"}}>*/}
							<div id="selectedAddress" style={{"background-color": "#303030", "display": "flex", "align-items": "center", 
								"padding": "0.7vw", "border-radius": "8px", "font-size": "0.9vw", "height": "40%", "display": addressDisplayStyle}}>
								{addressDisplay}
							</div>
							{/*</div>*/}
						</div>
						<div style={{"display": "flex", "flex-direction": "row"}}>
							<ThemeProvider theme={themeConnectButton}>
								<Button id="connectButton" 
									disabled={connectButtonDisabled}
									variant="contained" size={"large"} color={"primary"} 
									style={{"font-family": "inherit", "font-weight": "bolder", "font-size": "0.8vw", "padding": "7px",
									"border": "3px solid #006fe6", "border-radius": "4px"}} onClick={connectMetamask}>{connectButtonText}</Button>
							</ThemeProvider>
						</div>
					</div>
					{/*, "width": "100px", "height": "100px"*/}
					<div style={{"display": "flex", "flex-direction": "row", "justify-content": "flex-end", "padding-top": "1vw"}}>
						<div
							onClick={() => {
								stop();
								props.toggleThemeSong();
							}}
							style={{"display": "flex", "flex-direction": "column", "justify-content": "center", "padding-left": "1vw"
						}}>
							<img className="music-button" src={MusicNoteBlue} style={{"border-radius": "5px"}}/>
						</div>
						<div
							onClick={() => {
								props.stopThemeSong();
								togglePlay();
							}}
							style={{"display": "flex", "flex-direction": "column", "justify-content": "center", "padding-left": "1vw",
						}}>
							<img className="music-button" src={MusicNote} style={{"border-radius": "5px"}}/>
						</div>
					</div>

					<div>
						<div className="noselect" style={{"display": "flex", "flex-direction": "row", "justify-content": "left", "align-items": "center"}}>
							<div style={{"display": "flex", "flex-direction": "column"}}>
								<div style={{"text-align": "left", "color": "", "padding-bottom": "4px"}}>Total minted:
								</div>
								<div style={{"text-align": "left"}}>
									{totalMinted} <span style={{"color": "gray"}}>/ 7,848</span>
								</div>
							</div>
							<div style={{"padding": "1vw", 
								"display": "flex", "flex-direction": "row", "justify-content": "left", "align-items": "center"}}>
								<img src={BirdIcon13} height="50px"></img>
							</div>
						</div>
					</div>

					{/*Mint buttons*/}

					<div className="mechapunkx-section-spacer">
						<table className="border-box" width="100%" style={{"width": "100%"}}>
							<tbody>
								<tr>
									<td style={{"padding-top": "3vw", "padding-bottom": "1vw"}}>Remaining</td>
									<td></td>
									<td style={{"padding-top": "3vw", "padding-bottom": "1vw", "text-align": "right", "padding-right": "4vw"}}>Cost</td>
									<td style={{"padding-top": "3vw", "padding-bottom": "1vw"}}></td>
								</tr>
								<tr>
									<td>{amountRemainingMintMech} <span style={{"color": "#696969"}}>/ 7,418</span></td>
									<td></td>
										{/*<MechIcon/>*/}
									<td className="mpx-inv-tb-spacer" style={{"text-align": "right", "padding-right": "4vw"}}>{mintCostMech} MECH</td> 
									<td className="mpx-inv-tb-spacer" style={{"text-align": "right"}}>
										<ThemeProvider theme={themeConnectButton}>
											<Button variant="contained" size={"large"} color={"primary"} 
												style={{"font-family": "inherit", "font-weight": "bolder", "font-size": "1.4vw", "padding": "5px", 
												"width": "12vw",
												"border": "3px solid #006fe6", "border-radius": "6px"}} 
												onClick={() => { mintUsingMech(); }}
												disabled={mintDisabled}
												id="mintUsingMechButton"
												>MINT</Button>
										</ThemeProvider>
									</td>
								</tr>
								<tr>
									<td className="mpx-inv-tb-spacer">{amountRemainingBuy} <span style={{"color": "#696969"}}>/ 200</span></td>
									<td></td>
									<td className="mpx-inv-tb-spacer" style={{"text-align": "right", "padding-right": "4vw"}}>
										
										<span style={{"color": "gray"}}>**</span> 0.1 ETH
										
									</td>
									<td className="mpx-inv-tb-spacer" style={{"text-align": "right"}}>
										<MintBuyDialog sendBuyTransaction={mintSale} mintBuyDisabled={mintBuyDisabled} onDialogOpen={playDripSmall}/>
									</td>
								</tr>
								<tr>
									<td className="mpx-inv-tb-spacer">{amountRemainingWhitelist} <span style={{"color": "#696969"}}>/ 230</span></td>
									<td></td>
									<td className="mpx-inv-tb-spacer" style={{"text-align": "right", "padding-right": "4vw"}}>Free</td>
									<td className="mpx-inv-tb-spacer" style={{"text-align": "right"}}>
										
											<ThemeProvider theme={themeConnectButton}>
												<Tooltip title={"WL mint has completed."} placement="bottom-end">
												<div>
												<Button variant="contained" size={"large"} color={"primary"} 
													// disabled={mintWhitelistDisabled}
													disabled={true}
													style={{"font-family": "inherit", "font-weight": "bolder", "font-size": "1.4vw", "padding": "5px",
													"width": "12vw",
													"border": "3px solid #006fe6", "border-radius": "10px"}} onClick={mintWhitelist}>WHITELIST
												</Button>
												</div>
												</Tooltip>
											</ThemeProvider>
										
									</td>
								</tr>

								<tr>
									<td colspan="4">
										
									</td>
								</tr>
					
								{/* Token claiming */}

								<tr>
									<td className="mpx-inv-tb-spacer" style={{"padding-top": "3vw", "padding-bottom": "1vw"}}>Balances</td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
								<tr>
									<td>MECH:</td>
									<td className="mpx-inv-tb-spacer" style={{"text-align": "right"}}>{balancesMech}</td>
									<td className="mpx-inv-tb-spacer mpx-inv-text-claimable" style={{"text-align": "right", "padding-right": "4vw"}}>
										(<Tooltip title={unlockTimeMessage()} placement="top" open={pendingMechTooltip > 0 || pendingMechTooltip === "~"}>
											<span>{balancesMechClaimable} claimable)</span></Tooltip></td>
									<td className="mpx-inv-tb-spacer" style={{"text-align": "right"}}>
										<ThemeProvider theme={themeConnectButton}>
											<Button variant="contained" size={"large"} color={"primary"} 
												style={{"font-family": "inherit", "font-weight": "bolder", "font-size": "1.4vw", "padding": "5px", 
												"width": "12vw",
												"border": "3px solid #006fe6", "border-radius": "6px"}}
												disabled={claimMechDisabled}
												onClick={claimMech}>{claimMechButtonText}</Button>
										</ThemeProvider>
									</td>
								</tr>
								<tr>
									<td>LAMBO:</td>
									<td style={{"text-align": "right"}}>{balancesLambo}</td>
									<td className="mpx-inv-text-claimable" style={{"text-align": "right", "padding-right": "4vw"}}>
										<span>({balancesLamboClaimable} claimable)</span></td>
									<td style={{"text-align": "right"}}>
										<ThemeProvider theme={themeConnectButton}>
											<Button variant="contained" size={"large"} color={"primary"} 
												style={{"font-family": "inherit", "font-weight": "bolder", "font-size": "1.4vw", "padding": "5px",
												"width": "12vw",
												"border": "3px solid #006fe6", "border-radius": "10px"}}
												disabled={claimLamboDisabled}
												onClick={claimLambo}>{claimLamboButtonText}</Button>
										</ThemeProvider>
									</td>
								</tr>
							</tbody>
						</table>
					</div>

					<div className="mechapunkx-section-spacer" style={{"padding-top": "3vw", "position": "relative", "border-radius": "10px", "border": "3px solid #262626", "padding": "2vw", "margin-top": "4vw"}}>
						<div id="mpx-inv-overlay" 
							style={{"position": "absolute", "width": "100%", "height": "100%", "background-color": "black", "opacity": "0.8"}}>
						</div>
						<div className="mechapunkx-section-spacer" style={{"padding-bottom": "1vw", 
							"display": "flex", "flex-direction": "row", "justify-content": "space-between", "align-items": "center", "padding-left": "0.3vw"}}>

							{/*generating, top X % percentile)*/}

							<div>Generating: <span style={{"padding-left": "2.3vw", "padding-right": "2vw"}}>{generatingMechPerDay}</span> MECH <MechIcon/> <span style={{"padding-left": "1vw"}}>/ day</span> 
							</div>
							<div style={{"display": "flex", "flex-direction": "row", "justify-content": "flex-end", "align-items": "center"}}>
								<div style={{"margin-right": "1vw"}}>
									<GlitchButton 
										generate={glitchPunk} 
										noneSelected={glitchNoneSelected} 
										selectedMechaPunkx={selectedMechaPunkx}
										glitchText={glitchText}
										glitchDisabled={glitchDisabled}
									/>
								</div>
								<div>
									<MintGenerateButton 
										generate={convertYieldToLambo} 
										noneSelected={generateLamboNoneSelected} 
										selectedMechaPunkx={selectedMechaPunkx}
										generateLamboText={generateLamboText}
										generateLamboDisabled={generateLamboDisabled}
									/>
								</div>
							</div>
						</div>
						<div style={{"display": "flex", "flex-direction": "column", "background-color": "#212224", "padding-left": "1vw", "padding-right": "1vw"}}>

							{/*onClick={() => handleClickOpen(url, metadata, imageId)}*/}	
							<div style={{"display": "flex", "flex-direction": "row", "justify-content": "flex-end", "align-items": "center",
								"padding-top": "1vw"}}>
								{ tokensInWallet.length <= 36 ? "" :
									[...Array(displayTabCountMech).keys()].map((i) => 
									<div className="" style={{ "display": "flex", "flex-direction": "row", "justify-content": "center", "align-items": "center",
										"height": "1.4vw", "width": "1.4vw", "padding": "0.4vw", "margin-right": "0.4vw",
										"cursor": "pointer", "border": "2px solid rgb(44, 44, 44)", "border-radius": "5px", "color": i === displayTabMechSelected ? "white" : "#404040"}}
										onClick={() => {
											console.log("Changing display tab: ", i);
											setDisplayTabMechSelected(i); 
											playDripSmall();
										}}>
									{i}
									</div>
								)}
							</div>

							<ImageList key="generating-mech-img-list" sx={{ width: "100%", height: "100%" }} cols={6} >
								{tokensInWallet.slice((displayTabMechSelected*36),((displayTabMechSelected+1)*36)).map((tokenId) => (
									getTokenImageSmaller(tokenId, true)
								))}
							</ImageList>

						{/*


						<ImageListItem key={"mpx-inv-img-" + tokenId.toString()} 
							className="mpx-inv-img-selectable" 
							onClick={selectMechaPunkx}
						>
							<img 
								src={`${BirdIcon13}`}
								srcSet={`${BirdIcon13}`}
								loading="lazy"
							></img>
						</ImageListItem>

						<ImageList sx={{ width: "100%", height: "100%" }} cols={4} rowHeight={164}>
							{tokensInWallet.map((tokenId) => (
								<ImageListItem key={"mpx-inv-img-" + tokenId.toString()} className="mpx-inv-img-selectable" onClick={selectMechaPunkx}>
									<img src={`${BirdIcon13}?w=164&h=164&auto=format`} srcSet={`${BirdIcon13}?w=164&h=164&auto=format&dpr=2 2x`} loading="lazy"></img>
								</ImageListItem>
							))}
						</ImageList>*/}
						{/*className="mpx-inv-img-selectable"*/}
						{/*<ImageList sx={{ width: "100%", height: "100%" }} cols={6} rowHeight={164}>
							{tokensInWallet.map((tokenId) => (
								<ImageListItem key={tokenId}>
									<img src={`${BirdIcon13}?w=164&h=164&fit=crop&auto=format`} 
									srcSet={`${BirdIcon13}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
									loading="lazy"></img>
								</ImageListItem>
							))}
						</ImageList>*/}
						

							{/*{tokensInWallet.map((tokenId) => (
								<img src={BirdIcon13} className="mpx-inv-img-selectable" height="100px" width="100px" ></img> 
							))}*/}
							{/*onClick={}*/}
							{/*alt={"generating_" + tokenId.toString()}*/}
						</div>
						<div className="mechapunkx-section-spacer" style={{"padding-top": "5vw", "padding-bottom": "1vw", "padding-left": "0.3vw"}}>
							Generating: <span style={{"padding-left": "2.3vw", "padding-right": "2vw"}}>{generatingLamboPerDay}</span> LAMBO <LamboIcon/><span style={{"padding-left": "1vw"}}>/ day</span>  
						</div>
						<div style={{"display": "flex", "flex-direction": "row", "background-color": "#212224", "padding-left": "1vw", "padding-right": "1vw", "min-height": "100px"}}>
							{/*onClick={() => handleClickOpen(url, metadata, imageId)}*/}	
							<div style={{"display": "flex", "flex-direction": "row", "justify-content": "flex-end", "align-items": "center",
								"padding-top": "1vw"}}>
								{ tokensInWalletLamboYield.length <= 36 ? "" :
									[...Array(displayTabCountLambo).keys()].map((i) => 
									<div className="" style={{ "display": "flex", "flex-direction": "row", "justify-content": "center", "align-items": "center",
										"height": "1.4vw", "width": "1.4vw", "padding": "0.4vw", 
										"cursor": "pointer", "border": "2px solid rgb(44, 44, 44)", "border-radius": "5px", "color": i === displayTabLamboSelected ? "white" : "gray"}}
										onClick={() => {
											console.log("Changing display tab lambo: ", i);
											setDisplayTabLamboSelected(i); 
											playDripSmall();
										}}>
									{i}
									</div>
								)}
							</div>

							<ImageList key="generating-lambo-img-list" sx={{ width: "100%", height: "100%" }} cols={6} >
								{tokensInWalletLamboYield.slice((displayTabLamboSelected*36),((displayTabLamboSelected+1)*36)).map((tokenId) => (
									getTokenImageSmaller(tokenId, false)
								))}
							</ImageList>
						</div>

					</div>

					<div style={{"padding-bottom": "3vw"}}>
						<div style={{"padding-top": "3vw", "padding-bottom": "2vw"}}>Contracts:
						</div>
						<table className="border-box" width="100%" style={{"width": "100%"}}>
							<tbody>
								{/*<tr>
									<td>0x...</td>
									<td style={{"text-align": "right"}}>Buy / Trade MECH on <span style={{"color": "blue"}}>Sushi</span></td>
								</tr>
								<tr>
									<td>0x...</td>
									<td style={{"text-align": "right"}}>Buy / Trade LAMBO on <span style={{"color": "blue"}}>Sushi</span></td>
								</tr>*/}

								<tr style={{"padding-bottom": "1vw"}}>
								</tr>

								<tr>
									<td className="text-small" style={{"color": "#696969"}}><a href={linkContractMechToken} target="_blank">0x2d4622181f66616d7ca358a2d7395dc1b5e0dac6</a></td>
									<td style={{"text-align": "right"}}><a href={linkContractMechToken} target="_blank">MECH</a></td>
									<td><a href={linkContractMechToken} target="_blank"><MechIcon/></a></td>
									{/*<td style={{"text-align": "right"}}>[icon]</td>*/}
								</tr>
								<tr>
									<td className="text-small" style={{"color": "#696969"}}><a href={linkContractLambo} target="_blank">0x593E46216343B33Ef54696F09c5F23Cb4469108f</a></td>
									<td style={{"text-align": "right"}}><a href={linkContractLambo} target="_blank">LAMBO</a></td>
									<td><a href={linkContractLambo} target="_blank"><LamboIcon/></a></td>
									{/*<td style={{"text-align": "right"}}>[icon]</td>*/}
								</tr>
								<tr>
									<td className="text-small" style={{"color": "#696969"}}><a href={linkContractMechaPunkx} target="_blank">0x9D3eBe1CBc34614b414Bbb6d83F9b0875B8365EE</a></td>
									<td style={{"text-align": "right"}}><a href={linkContractMechaPunkx} target="_blank">MECHAPUNKX</a></td>
									<td></td>
									{/*<td style={{"text-align": "right"}}>[icon]</td>*/}
								</tr>
							</tbody>
						</table>
						<div style={{"padding-top": "3vw", "padding-bottom": "2vw"}}>
							<div>
							To see claimable MECH (for Gnosis Safe users):
							</div>
							<div style={{"padding-top": "1vw", "position": "relative"}}>
								<div>Enter Address (Use connect button above first):</div>
								<div style={{"position": "absolute", "top": 0, "right": 0}}>
									<Box sx={{ display: 'flex'}}>
										<Fade
								            in={!loadingCompleteGnosis()}
								            style={{
								              transitionDelay: loadingCompleteGnosis() ? '0ms' : '800ms',
								            }}
								            unmountOnExit
								          >
										<CircularProgress size={"2.5vw"}/>
										</Fade>
									</Box>
								</div>
								<div style={{'text-align': 'left', "padding-top": "1vw"}}>
									<input style={{'box-sizing': 'border-box', 'max-width': '100%', 'min-width': '350px'}} 
									type="text" 
									id="addressInput" 
									class="addressInput" 
									placeholder={placeholderText}
									maxlength="42" name="addressInput" 
									onFocus={() => {
										if (placeholderText == "0x...") return
										else setPlaceHolderText("")
									}}	
									autocomplete="off" onKeyUp={()=> addressUpdate()} onChange={() => addressUpdate()}/>
								</div>
							</div>
							<div style={{"padding-top": "1vw"}}>{
								"Claimable: " + claimableMechGnosis.toString() + " MECH"
							}
							</div>
							<div style={{"padding-top": "1vw"}}>{
								"Accumulating: " + pendingMechGnosis.toString() + " MECH (locked)"
							}
							</div>
						</div>
					</div>

				{/*<div className="noselect" style={{"display": "flex", "flex-direction": "row", "justify-content": "right", "align-items": "center",
				"margin-top": "5px"}}>
					<div style={{"display": "flex", "flex-direction": "row", "justify-content": "end", "text-align": "right"}}>
						<a href="https://etherscan.io/address/0xc2d296311C041332946EC8DCE90b40127d217836#contracts" target="_blank">
							<div style={{"display": "flex", "flex-direction": "row", "align-items": "center"}}>
								<div className="text-small" style={{"color": "#696969"}}>Contract:</div>
								<div style={{"padding-right": "2vw", "padding-left": "1vw", 
									"display": "flex", "justify-content": "center"}}>
									<img src={EtherscanLogo} height="30px" width="30px" style={{"border-radius": "5px"}}></img>
								</div>
								
							</div>
						</a>
					</div>
				</div>*/}
			{/*</div>*/}
			</div>

			{/* TEST */}
			{/*<AccountView ownerAddress={addressDisplay} tokens={SampleTokenMetadata.tokens} refreshItems={accountViewRefreshItems}/>*/}
			
			{/* REAL */}
			<AccountView 
				ownerAddress={addressDisplay} 
				tokens={
					tokensInWallet.concat(tokensInWalletLamboYield).map((tokenId) => ({
						"tokenId": tokenId.toString(),
						"src": "https://lambo.lol/images/mechapunkx-small/" + tokenId.toString() + ".png"//, 
						// "rarity": "" // TODO: either access metadata here, put into object, or do it in the account view (since it looks up traits)
					}
				))}
				accountViewRefreshItems={accountViewRefreshItems}
			/>
		</div>

		{/*
		<div style={{"padding-left": "2vw", "padding-right": "2vw", "padding-bottom": "2vw",
			"background-color": "#1e1e1e", "border-radius": "8px"}}>		
		<Gallery/>
		</div>*/}
		<Snackbar
			open={snackbarOpen}
			autoHideDuration={9000}
			onClose={handleCloseSnackbar}
			//action={action}
		>
			<SnackbarContent style={{
		      backgroundColor: snackbarBackgroundColor
		    }}
		    message={snackbarMessage}
		  />
		</Snackbar>
		
	</div>
  );
}
