import Banner from "../assets/banner_animation_narrow.gif";
import KneeSample from "../assets/kneecaps-sample-2.png";
import KneeSample2 from "../assets/kneecaps-sample-6.png";
import KneeSample8 from "../assets/kneecaps-sample-8.png";
import KneeSample9 from "../assets/kneecaps-sample-9.png";

import sample_1 from '../assets/kneecaps/1.png';
import sample_2 from '../assets/kneecaps/2.png';
import sample_3 from '../assets/kneecaps/3.png';
import sample_4 from '../assets/kneecaps/4.png';

import KneecapsLogo from '../assets/lambocorpKneesSmall.png';
import EtherscanLogo from '../assets/links/etherscan_logo_circle.png';
import RarityToolsLogo from '../assets/links/rarity_tools_circle2.png';
import OpenSeaLogo from '../assets/links/opensea_logo_circle.png';
import Image from 'material-ui-image'

import { Fade } from '@mui/material';


export default function Home() {
  return (
    <div style={{"display": "flex", "flex-direction": "column"}}>
    	
    	<div className="kneecaps-home-title noselect" 
    		style={{"background-color": "black", "color": "white", "padding-left": "2vw", "padding-right": "2vw", 
    		"padding-top": "1vw", "padding-bottom": "1vw"
    		}}>
    		<div>KNEECAPS</div>
    		<div className="kneecaps-home-facts">
    			Over 1k Minted<br></br>
    			275+ Owners
    		</div>
    	</div>
    
    <div className="kneecaps-home text-page" >
      <div className="kneecaps-home-banner-top noselect">
	      	<Image 
				src={sample_1}
				aspectRatio={"1/1"}
				style={{"background-color": "#1e1e1e", "width": "22%", "padding": "0"}}
				imageStyle={{"background-color": "#1e1e1e", "height": "auto", "position": "relative", borderRadius: "0.75rem"}}
				disableSpinner={true}
				animationDuration={3500}
				alt="epstein"
			/>
			<Image 
				src={sample_2}
				aspectRatio={"1/1"}
				style={{"background-color": "#1e1e1e", "width": "22%", "padding": "0"}}
				imageStyle={{"background-color": "#1e1e1e", "height": "auto", "position": "relative", borderRadius: "0.75rem"}}
				disableSpinner={true}
				animationDuration={3500}
				alt="didn't"
			/>
			<Image 
				src={sample_3}
				aspectRatio={"1/1"}
				style={{"background-color": "#1e1e1e", "width": "22%", "padding": "0"}}
				imageStyle={{"background-color": "#1e1e1e", "height": "auto", "position": "relative", borderRadius: "0.75rem"}}
				disableSpinner={true}
				animationDuration={3500}
				alt="kill"
			/>
			<Image 
				src={sample_4}
				aspectRatio={"1/1"}
				style={{"background-color": "#1e1e1e", "width": "22%", "padding": "0"}}
				imageStyle={{"background-color": "#1e1e1e", "height": "auto", "position": "relative", borderRadius: "0.75rem"}}
				disableSpinner={true}
				animationDuration={3500}
				alt="himself"
			/>
      </div>
      <div className="kneecaps-home-upper">
      	<div className="kneecaps-home-left">
      		<div className="kneecaps-home-history text-page noselect" style={{
      			"margin-bottom": "2vw", "background-color": "black", "color": "white", "font-weight": "bold", "line-height": "1.4"}}>
      			<div className="kneecaps-home-description">
	      			<span style={{"color": "#ec39a7"}}>5,536 Unique NTF's</span> Bending on the <span style={{"color": "#ec39a7"}}>Ethereum</span> Blockchain.<br></br><br></br>
					Mint for free on etherscan <a href="https://etherscan.io/address/0xc2d296311C041332946EC8DCE90b40127d217836#writeContract" target="_blank" style={{"color": "#4afbff"}}>(scratchKnee(s))</a>. 
					<br></br>Get your knees named in the Discord. 
					<br></br>
				</div>
				
				<div className="kneecaps-home-links">

					<a href="https://opensea.io/collection/kneecaps" target="_blank">
	    			<div style={{"display": "flex", "flex-direction": "row", "align-items": "center"}}>
	    				<div style={{"padding-right": "1vw", "padding-bottom": "1vw", "padding-top": "1vw", 
	    			"display": "flex", "justify-content": "center"}}>
	    					<img src={OpenSeaLogo} height="30px" width="30px" style={{"border-radius": "5px"}}></img>
	    				</div>
	    				<div>OpenSea</div>
	    				{/*<div>OpenSea <span style={{"color": "gray"}}>(opensea.io/collection/kneecaps)</span>
	    				</div>*/}
	    			</div>
	    			</a>

					<a href="https://rarity.tools/kneecaps" target="_blank">
	    			<div style={{"display": "flex", "flex-direction": "row", "align-items": "center"}}>
	    				<div style={{"padding-right": "1vw", "padding-bottom": "1vw", "padding-top": "1vw", "padding-left": "1vw", 
	    			"display": "flex", "justify-content": "center"}}>
	    					<img src={RarityToolsLogo} height="30px" width="30px" style={{"border-radius": "5px"}}></img>
	    				</div>
	    				<div>Rarity Tools</div>
	    				{/*<div>Rarity Tools <span style={{"color": "gray"}}>(rarity.tools/kneecaps)</span>
	    				</div>*/}
	    			</div>
	    			</a>


	    			<a href="https://etherscan.io/address/0xc2d296311C041332946EC8DCE90b40127d217836#contracts" target="_blank">
	    			<div style={{"display": "flex", "flex-direction": "row", "align-items": "center"}}>
	    				<div style={{"padding-right": "1vw", "padding-bottom": "1vw", "padding-top": "1vw", "padding-left": "1vw", 
	    			"display": "flex", "justify-content": "center"}}>
	    					<img src={EtherscanLogo} height="30px" width="30px" style={{"border-radius": "5px"}}></img>
	    				</div>
	    				<div>Contract
	    				{/*<span style={{"color": "gray"}}>(0xc2d296311C041332946EC8DCE90b40127d217836)</span>*/}
	    				</div>
	    			</div>
	    			</a>

    			</div>

      		</div>
	      	<div className="kneecaps-home-history text-small" style={{"line-height": "1.4"}}>
	      	{/*TODO Right align the data separately*/}
	      		<div style={{"font-weight": "bold"}}>The Origin of KNEECAPS <span style={{"color": "#004c8a"}}>- March 2022</span></div>
	      		<p style={{"text-indent": "2vw"}}>
	      		The story of KNEECAPS begins with Billy, a simple boy, from a small town. Billy was simple, but he was quick on his feet, and had an unbridled energy to see great rivers, forests, and mountains, and spread happiness to the whole world. 
	      		Now Billy's adventures were rarely alone, and our story begins with Billy and his friend Zac Efron, long before the days of creating the Zac Efron Network.
	      		</p><p style={{"text-indent": "2vw"}}>One day in June of 2021, Billy and Zac made a ship. They got on board and whistled as they sailed onto the OpenSea.
	      		But maybe I'm getting ahead of myself, and should go a few paces back.
	      		</p>
	      		<p style={{"text-indent": "2vw"}}> 
	      		Billy always had dreams, but one summer's eve he awoke, and with his eyes still shut, he saw in his thoughts a simple juice box. It was simple, he thought, just like himself, and Billy liked that.
	      		Suddenly, the box of juice opened its eyes wide and spoke to him, bellowing "SHOW THEM THE KNEES". It echoed in Billy's mind, and he shuddered in awe and great fear. Immediately, he fell back into a deep sleep, exhausted from doing karate on trees earlier that afternoon. 
	      		</p><p style={{"text-indent": "2vw"}}>
	      		The next morning, Billy erupted from his bed and instinctively shouted a beast-like, primal, earth-shattering "REEEEEEEEEE" from the depth of his soul. 
	      		He knew in his heart he had to honor the vision. He had to share the knees across the world like the juice box told him. So he struck a pose, imitating the conviction of his much older, wiser, middle school classmate, Ricky.
	      		</p><p style={{"text-indent": "2vw"}}>
	      		Day after day, Billy worked and grinded. He cold-called his aunties to sell them NTF's. Eventually all the sweat and tears added up, and well, little ol' Billy felt like he moved mountains.
	      		</p><p style={{"text-indent": "2vw"}}>
	      		"They're just knees. No one wants to see those!" people told him.
	      		</p><p style={{"text-indent": "2vw"}}>
	      		Billy didn't listen! He had conviction. He knew the knees brought real good into the world, and they made people laugh, especially when times seemed dark. 
	      		"My knees will have their moment in the sun, and with a few hundred friends at my back, they can't stop me!", Billy thought. 
	      		If he failed though, they'd send him back to the farm with his Uncle T0mmy and Aunt Lori.
	      		</p><p style={{"text-indent": "2vw"}}>
	      		Days passed, and Billy showed the knees to everyone, but his town was too small, and many knees remained. 
	      		There was only one choice left, and Billy knew he had to leave his hometown. 
	      		</p>
	      		<p style={{"text-indent": "2vw"}}>So Billy and Zac Efron built a ship with twigs and rope, and got onto OpenSea, back to where our story began. 
	      		On the water, the sun began to rise high overahead, and Billy looked at Zac. Their home town faded in the distance and the wind swept across the sea. 
	      		</p><p style={{"text-indent": "2vw"}}> 
	      		Billy felt sad, as it slowly shrinked, but then he remembered the Juice, and that so many adventures were coming in his future. He took a deep breath, and at the top of his lungs, he shouted "I will not go back to those, CAT HAIRRRR PILLOWSSSS!"
	      		</p><p style={{"text-indent": "2vw"}}>Suddenly the wind caught their sail, and with youthful exhuberance and a look in his eyes of slightly more than just friendship, Zac said, 
	      		"You know what Billy, I think we're gonna be just fine!"
	      		</p>
	      		{/*<p>
	      		<span style={{"font-weight": "bold"}}>- Book of SIPS</span>
	      		</p>*/}
	      	</div>
	      	<Fade in={true} appear={true}><img src={Banner} className="kneecaps-banner"></img></Fade>
      	</div>
      	<div className="kneecaps-home-right">
      		<Fade in={true} appear={true}><img src={KneeSample} className="kneecaps-home-sample"></img></Fade>
      		<Fade in={true} appear={true}><img src={KneeSample2} className="kneecaps-home-sample" style={{"margin-top": "2vw"}}></img></Fade>
      		<Fade in={true} appear={true}><img src={KneeSample8} className="kneecaps-home-sample" style={{"margin-top": "2vw", "margin-bottom": "4vw", "background-color": "black"}}></img></Fade>
      		{/*<Fade in={true} appear={true}><img src={KneeSample9} className="kneecaps-home-sample" style={{"margin-top": "2vw"}}></img></Fade>*/}
      	</div>
      </div>
    </div>
    </div>
  );
}