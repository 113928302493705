import * as React from 'react';
import logo from './logo.svg';
import './App.css';
import { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component'
import axios from 'axios';
import ReactDOM from 'react-dom';
import ResponsiveAppBar from './appBarResponsive';

// react-router
// import { render } from "react-dom";
//import { BrowserRouter } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  Outlet
} from "react-router-dom";
import Projects from "./routes/projects";
//import Expenses from "./routes/expenses";
import Invoices from "./routes/invoices";
import Invoice from "./routes/invoice";


//import titleBg from './assets/titleBg.png';
import titleBg from './assets/skyBlue.png';
import titleText from './assets/titleText.png';

import RouterBreadcrumbs from './about';


// For modal
// import SimpleDialog from './simpleDialog';
import SimpleDialogDemo from './simpleDialog';
import GalleryModal from './galleryModal';

import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
//import PersonIcon from '@mui/icons-material/Person';
//import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';



// <img src="/image.jpg" alt="image" />


/*
Tutorial from:
https://www.digitalocean.com/community/tutorials/how-to-build-an-infinite-scroll-image-gallery-with-react-css-grid-and-unsplash

Using infinite scroll within a component
https://codesandbox.io/s/w3w89k7x8
https://www.npmjs.com/package/react-infinite-scroll-component

npm i react-infinite-scroll-component
or
https://www.npmjs.com/package/react-infinite-scroller
*/

// Number of random images to fetch
const count = 10;

/*
// I think these below get removed?

// Optional style for fetched images
.images {
	display: flex;
	max-width: 80%;
	margin: 2% auto;
	flex-wrap: wrap;
	align-items: flex-start;
}
.single-photo {
	max-width: 300px;
	align-self: auto;
	margin: 1% 2px;
	flex-grow: 1;
	vertical-align: middle;
}

<Modal
  open={open}
  onClose={handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={style}>
    <Typography id="modal-modal-title" variant="h6" component="h2">
      Text in a modal
    </Typography>
    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
      Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
    </Typography>
  </Box>
</Modal>

*/




// Component for image
const GalleryImage = ({ url, keyy, handleClickOpen }) => {
	
	return (
		<div className="image-outer">
			<div className="image-item" key={keyy}>
				<img className="gallery-img" src={url} height="200 px" width="200 px" onClick={() => handleClickOpen(url)} />
			</div>
			<div className="image-caption">
				NO. {url.split("output_").slice(-1)[0].split('.png')[0]}
			</div>
			<div id="image-id" className="image-caption2">
				NO. {url.split("output_").slice(-1)[0].split('.png')[0]}
			</div>

			<SimpleDialogDemo/>
					
		</div>
	);
};

// style={{ backgroundImage: `url(${background_banner})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: "no-repeat"
// style={{ backgroundImage: `url(${background})`, backgroundPosition: 'center', backgroundSize: 'contain', backgroundRepeat: "no-repeat"}

/*
function useAsync(asyncFn, onSuccess) {
	useEffect(() => {
		let isActive = true;
		asyncFn().then(data => {
			if (isActive) onSuccess(data);
		});
		return () => { isActive = false };
	}, [asyncFn, onSuccess]);
}
*/


// React Hook React.useEffect has a missing dependency: 'fetchImages'. Either include it or remove the dependency array

let Collage = () => {

	const [images, setImages] = React.useState([]);
	const [loaded, setIsLoaded] = React.useState(false);

	// Gallery Modal
	const [open, setOpen] = React.useState(false);
	const [modalImage, setModalImage] = React.useState(""); // TODO: Default image I think

	// Provided function in hook runs every time array parameter is updated
	
	React.useEffect(() => {
		fetchImages();
	}, []);


	let sampleImages = [
		"output_102.png", "output_251.png", "output_2725.png", "output_353.png", "output_2417.png", 
		"output_5555.png", "output_6272.png", "output_7062.png", "output_8579.png", "output_1084.png", "output_3217.png", 
		"output_2945.png", "output_3663.png", "output_4946.png", "output_556.png", "output_6362.png", "output_7208.png", 
		"output_5497.png", "output_1224.png", "output_352.png", "output_3116.png", "output_3792.png", "output_5118.png", 
		"output_5616.png", "output_6568.png", "output_6662.png", "output_1431.png", "output_4848.png", "output_2079.png", 
		"output_4263.png", "output_5233.png", "output_6.png", "output_736.png", "output_6840.png", "output_1545.png", 
		"output_6026.png", "output_3418.png", "output_4381.png", "output_7849.png", "output_7972.png", "output_5420.png", 
		"output_6093.png", "output_1740.png", "output_1979.png", "output_4664.png", "output_2263.png", "output_921.png", 
		"output_8383.png", "output_2580.png", "output_6947.png"];
	sampleImages.reverse();

	function hasMoreSamples() {
		return images.length < sampleImages.length;
	}


	// Display sample images
	const fetchImages = (count = 9) => {
			//const apiRoot = "https://kneecaps.io/images/"
			/*
			axios.get('http://localhost:5000/1246.png', {}).then (res => {
				setImages([...images, ...res.data]);
				setIsLoaded(true);
			});
			*/  
			
			// Load more if available
			if (hasMoreSamples()) {

				console.log("Loading " + count.toString() + " images");

				let fs = sampleImages.slice(images.length, Math.min(images.length + count, images.length + (sampleImages.length - images.length)));
				let fsPaths = [];
				for (let i=0; i<fs.length; i++) {
					fsPaths.push('https://lambo.lol/mecha-sample/' + fs[i]);
				}
				
				//console.log("Images displayed, samples left: " + images.length.toString() + ", " + (sampleImages.length - images.length).toString())
				// Original, successful
				//setImages([...images, ['https://lambo.lol/mecha-sample/' + sampleImages[images.length]]]);								
				setImages(arr => [...images].concat(fsPaths));
				setIsLoaded(true);
			}
	}
	
	// url = {image.urls.regular} ----- previous
	// style={{backgroundImage: `url(${titleBg})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundAttachment: 'fixed'}}

	/* For MODAL */
	const handleClickOpen = (url) => {
		setOpen(true);
		setModalImage(url);
		// console.log("Clicked img: ", url);
	}

	/* For MODAL */
	const handleClose = (value) => {
	    setOpen(false);
	    //setModalImage(""); // TODO: .... hide image / use default fixed size maybe so no shift?
	 };

	return (
		<div className="hero is-fullheight is-bold is-info" >
			<div className="hero-body">


				<div className="gallery">
					
					<div className="gallery-left">
					</div>
					
					<div className="gallery-right">
						<div className="container">
							<div className="header content gallery-t">
								{/*<h2 className="subtitle is-6">Gallery</h2>*/}
								{<span className="gallery-title">
									MECHA<span className="gallery-title2">PUNKX</span> <span className="gallery-subtitle">// 7000</span>
								</span>}
								{/*<img src={titleText} height="100px" alt="image" />*/}
							</div>
							<InfiniteScroll
									 dataLength={images}
									 next={() => fetchImages(5)}
									 //next={() => { console.log("Called next... ")}}
									 hasMore={true}
									 loader={
										<img
											 src="https://kneecaps.io/images/1246.png"
											 height="80 px"
											 width="80 px"
											 alt="loading"
										/>}
							 >
									 <div className="image-grid" style={{ marginTop: "30px" }}>
											{loaded ? 
													images.map((image, index) => (
														<GalleryImage url={image} key={index} handleClickOpen={handleClickOpen}/>
													)): ""}
									</div>
							</InfiniteScroll>
						</div>
					</div>
				</div>
			</div>
			<GalleryModal
		        modalImage={modalImage}
		        open={open}
		        onClose={handleClose}
		      />
		</div>
	);
};

class App extends Component {

	constructor(props) {
		super(props);

		this.menu = <ResponsiveAppBar />;
		this.projects = <Projects menu={this.menu}/>;

		this.state = {
			//accounts: [],
			//contract: null,
			//kneecaps: [] // minted by user
		};

		//this.initialize = this.initialize.bind(this);
		//window.addEventListener('DOMContentLoaded', this.initialize);
	}


	/*
	Full react router example App at:
		https://stackblitz.com/edit/github-agqlf5?file=src/App.jsx

		modal example, but was kind of a bitch
		https://stackblitz.com/github/remix-run/react-router/tree/main/examples/modal?file=src/App.tsx

	Better to separate <Route path="/" element={<App />} />
	https://reactrouter.com/docs/en/v6/getting-started/tutorial

	import { render } from "react-dom";
	import {
	  BrowserRouter,
	  Routes,
	  Route,
	} from "react-router-dom";
	import App from "./App";
	import Expenses from "./routes/expenses";
	import Invoices from "./routes/invoices";

	const rootElement = document.getElementById("root");
	render(
	  <BrowserRouter>
	    <Routes>
	    </Routes>
	  </BrowserRouter>,
	  rootElement
	);

	// routes with children will nest the UI components for shared layout when the child route matches (instead of erasing)
	Parent route persists and Outlet swaps between the two children


	Index route
	Index routes render in the parent routes outlet at the parent route's path.
	Index routes match when a parent route matches but none of the other children match.
	Index routes are the default child route for a parent route.
	Index routes render when the user hasn't clicked one of the items in a navigation list yet
	
	{const projects = <Projects({"menu": r})\>;}
	
	RENDER code reruns with every render
	*/

	render() {

		
		
		return (

			<RouterBreadcrumbs/>
		);
	}
}

export default App;
