import DiscordLogo from './assets/links/discord_logo_circle.png';
import TwitterLogo from './assets/links/twitter_logo_circle.png';
import OpenSeaLogo from './assets/links/opensea_logo_circle.png';
import TreasureLogo from './assets/links/treasure_logo_circle3black.png';
import KneecapsFavicon from './assets/kneecaps_favicon_img.png';
import LamboLogo from './assets/lambo3SatIconSmall.png';
import KneecapsLogo from './assets/lambocorpKneesSmall.png';
import EtherscanLogo from './assets/links/etherscan_logo_circle.png';
import RarityToolsLogo from './assets/links/rarity_tools_circle2.png';
import { Link as RouterLink } from 'react-router-dom';
import MonkeyIcon from './assets/links/mpx_logo_monkey.png'

export default function LamboCorpLinks() {
  return (
    <div className="home-links " style={{"background-color": "rgb(11,207, 246)", "position": "relative", "overflow": "hidden"}}>
    	<div className="cloud-intro" style={{"position": "absolute", "width": "100%", "height": "65vw", "z-index": 10}}>
		</div>
    	<div className="padding-page text-page" style={{"display": "flex", "flex-direction": "row", "padding-left": "2vw", "z-index": 0}}>
    		<div style={{"width": "64%"}}>
		      	
		      	<div className="home-links-project" style={{"margin-top": "1vw"}}>
		    		
		    		<RouterLink to="/projects" key="links-projects">
			    		<div className="noselect" style={{"display": "flex", "flex-direction": "row", "align-items": "center"}}>
			    			<div style={{"padding-right": "1vw", "padding-bottom": "1vw", "padding-top": "1vw"}}>
				    			<img src={LamboLogo} height="43px" style={{"border-radius": "5px"}}></img> 
					    	</div>
					    	<div className="home-links-project-title"><span style={{"color": "yellow"}}>LAMBOCORP</span>
					    	</div>
				    	</div>
			    	</RouterLink>


			    	<div style={{"display": "flex", "flex-direction": "column"}}>

		    			<a href="https://twitter.com/lamboc0rp" target="_blank">
		    			<div style={{"display": "flex", "flex-direction": "row", "align-items": "center"}}>
		    				<div style={{"padding-right": "1vw", "padding-bottom": "1vw", "padding-top": "1vw", 
		    			"display": "flex", "justify-content": "center"}}>
		    					<img src={TwitterLogo} height="30px" width="30px" style={{"border-radius": "5px"}}></img>
		    				</div>
		    				<div>Twitter <span style={{"color": "gray"}}>(@lamboc0rp)</span>
		    				</div>
		    			</div>
		    			</a>
		    			
		    			<a href="https://discord.gg/VbSjbrFsPt" target="_blank">
		    			<div style={{"display": "flex", "flex-direction": "row", "align-items": "center"}}>
		    				<div style={{"padding-right": "1vw", "padding-bottom": "1vw", "padding-top": "1vw", 
		    			"display": "flex", "justify-content": "center"}}>
		    					<img src={DiscordLogo} height="30px" width="30px" style={{"border-radius": "5px"}}></img>
		    				</div>
		    				<div>Discord
		    				</div>
		    			</div>
		    			</a>

		    			<a href="https://twitter.com/zebosXL" target="_blank">
		    			<div style={{"display": "flex", "flex-direction": "row", "align-items": "center"}}>
		    				<div style={{"padding-right": "1vw", "padding-bottom": "1vw", "padding-top": "1vw", 
		    					"display": "flex", "justify-content": "center"}}>
		    					<img src={TwitterLogo} height="30px" width="30px" style={{"border-radius": "5px"}}></img>
		    				</div>
		    				<div>Uncle Tommy
		    				</div>
		    			</div>
		    			</a>
			    		</div>
			    	


		    		{/*<div style={{"display": "flex", "flex-direction": "row"}}>
						<a className="mechapunkx-home-links-logo" href="https://twitter.com/lamboc0rp" target="_blank">
						<img src={TwitterLogo} style={{"height": "30px", "width": "30px", "margin-right": "1vw"}}></img></a>
						<a className="mechapunkx-home-links-logo" href="https://discord.gg/VbSjbrFsPt" target="_blank">
						<img src={DiscordLogo} style={{"height": "30px", "width": "30px", "margin-right": "1vw"}}></img></a>
		    		</div>*/}
		    	</div>
		    	
			    <div className="home-links-project">

			    	<RouterLink to="/kneecaps" key="links-kneecaps">
				    	<div className="noselect" style={{"display": "flex", "flex-direction": "row", "align-items": "center"}}>
				    		<div style={{"padding-right": "1.4vw", "padding-bottom": "1vw", "padding-top": "1vw", 
				    			"display": "flex", "justify-content": "center"}}>
				    			<img src={KneecapsLogo} height="50px" style={{"border-radius": "5px"}}></img>
				    		</div>
				    		{/*ec39a7*/}
					    	<div className="home-links-project-title" style={{"display": "flex", "flex-direction": "column", 
					    		"justify-content": "center", "align-items": "center", 
					    		"padding-bottom": "0", "margin-bottom": "1vw", "color": "#dc02bf"}}>KNEECAPS
					    	</div>
				    	</div>
			    	</RouterLink>

		    			<div style={{"display": "flex", "flex-direction": "column"}}>

		    			<a href="https://etherscan.io/address/0xc2d296311C041332946EC8DCE90b40127d217836#writeContract" target="_blank">
		    			<div style={{"display": "flex", "flex-direction": "row", "align-items": "center"}}>
		    				<div style={{"padding-right": "1vw", "padding-bottom": "1vw", "padding-top": "1vw", 
		    			"display": "flex", "justify-content": "center"}}>
		    					<img src={KneecapsFavicon} height="30px" width="30px" style={{"border-radius": "5px"}}></img>
		    				</div>
		    				<div>Mint <span style={{"color": "gray"}}>(Etherscan)</span>
		    				</div>
		    			</div>
		    			</a>
		    			
		    			<a href="https://opensea.io/collection/kneecaps" target="_blank">
		    			<div style={{"display": "flex", "flex-direction": "row", "align-items": "center"}}>
		    				<div style={{"padding-right": "1vw", "padding-bottom": "1vw", "padding-top": "1vw", 
		    			"display": "flex", "justify-content": "center"}}>
		    					<img src={OpenSeaLogo} height="30px" width="30px" style={{"border-radius": "5px"}}></img>
		    				</div>
		    				<div>OpenSea <span style={{"color": "gray"}}>(opensea.io/collection/kneecaps)</span>
		    				</div>
		    			</div>
		    			</a>

		    			<a href="https://rarity.tools/kneecaps" target="_blank">
		    			<div style={{"display": "flex", "flex-direction": "row", "align-items": "center"}}>
		    				<div style={{"padding-right": "1vw", "padding-bottom": "1vw", "padding-top": "1vw", 
		    			"display": "flex", "justify-content": "center"}}>
		    					<img src={RarityToolsLogo} height="30px" width="30px" style={{"border-radius": "5px"}}></img>
		    				</div>
		    				<div>Rarity Tools <span style={{"color": "gray"}}>(rarity.tools/kneecaps)</span>
		    				</div>
		    			</div>
		    			</a>


		    			<a href="https://arbiscan.io/address/0x9D3eBe1CBc34614b414Bbb6d83F9b0875B8365EE#code" target="_blank">
		    			<div style={{"display": "flex", "flex-direction": "row", "align-items": "center"}}>
		    				<div style={{"padding-right": "1vw", "padding-bottom": "1vw", "padding-top": "1vw", 
		    			"display": "flex", "justify-content": "center"}}>
		    					<img src={EtherscanLogo} height="30px" width="30px" style={{"border-radius": "5px"}}></img>
		    				</div>
		    				<div>Contract
		    				{/*<span style={{"color": "gray"}}>(0xc2d296311C041332946EC8DCE90b40127d217836)</span>*/}
		    				</div>
		    			</div>
		    			</a>
			    		</div>
			    	</div>

			    	<div className="home-links-project">

			    	<RouterLink to="/mechapunkx" key="links-mechapunkx">
				    	<div className="noselect" style={{"display": "flex", "flex-direction": "row", "align-items": "center"}}>
				    		<div style={{"padding-right": "1vw", "padding-bottom": "1vw", "padding-top": "1vw", 
				    			"display": "flex", "justify-content": "center"}}>
				    			<img src={MonkeyIcon} height="50px" style={{"border-radius": "5px"}}></img>
				    		</div>
					    	<div className="home-links-project-title" style={{"display": "flex", "flex-direction": "row", 
					    		"justify-content": "center", "align-items": "center", "padding-bottom": "0", "margin-bottom": "1vw"}}>
					    		<span style={{"color": "red"}}>MECHA</span><span style={{"color": "blue"}}>PUNKX</span>
					    	</div>
				    	</div>
			    	</RouterLink>

		    		<div style={{"display": "flex", "flex-direction": "column"}}>

			    			<a href="https://trove.treasure.lol/collection/mechapunkx" target="_blank">
			    			<div style={{"display": "flex", "flex-direction": "row", "align-items": "center"}}>
			    				<div style={{"padding-right": "1vw", "padding-bottom": "1vw", "padding-top": "1vw", 
			    			"display": "flex", "justify-content": "center"}}>
			    					<img src={TreasureLogo} height="30px" width="30px" style={{"border-radius": "5px"}}></img>
			    				</div>
			    				<div>MechaPunkx Collection
			    				{/*<span style={{"color": "gray"}}>(0xc2d296311C041332946EC8DCE90b40127d217836)</span>*/}
			    				</div>
			    			</div>
			    			</a>

			    			<a href="https://trove.treasure.lol/collection/mechapunkx-legends" target="_blank">
			    			<div style={{"display": "flex", "flex-direction": "row", "align-items": "center"}}>
			    				<div style={{"padding-right": "1vw", "padding-bottom": "1vw", "padding-top": "1vw", 
			    			"display": "flex", "justify-content": "center"}}>
			    					<img src={TreasureLogo} height="30px" width="30px" style={{"border-radius": "5px"}}></img>
			    				</div>
			    				<div>MechaPunkx Legends Collection (Promo)
			    				</div>
			    			</div>
			    			</a>

			    			<a href="https://arbiscan.io/address/0x9D3eBe1CBc34614b414Bbb6d83F9b0875B8365EE#code" target="_blank">
			    			<div style={{"display": "flex", "flex-direction": "row", "align-items": "center"}}>
			    				<div style={{"padding-right": "1vw", "padding-bottom": "1vw", "padding-top": "1vw", 
			    			"display": "flex", "justify-content": "center"}}>
			    					<img src={EtherscanLogo} height="30px" width="30px" style={{"border-radius": "5px"}}></img>
			    				</div>
			    				<div>Contract
			    				{/*<span style={{"color": "gray"}}>(0xc2d296311C041332946EC8DCE90b40127d217836)</span>*/}
			    				</div>
			    			</div>
			    			</a>


		    			
		    		</div>
		    	</div>
	    	</div>
    	</div>
    </div>
  );
}

/*
<a className="mechapunkx-home-links-logo" href="https://treasure.lol" target="_blank">
<img src={TreasureLogo} style={{"height": "2vw", "width": "2vw", "margin-right": "1vw"}}></img></a>



<a className="mechapunkx-home-links-logo" href="https://twitter.com/lamboc0rp" target="_blank">
						<img src={TwitterLogo} style={{"height": "2vw", "width": "2vw", "margin-right": "1vw"}}></img></a>
						<a className="mechapunkx-home-links-logo" href="https://discord.gg/VbSjbrFsPt" target="_blank">
						<img src={DiscordLogo} style={{"height": "2vw", "width": "2vw", "margin-right": "1vw"}}></img></a>
*/