const distributionData = [
	"1,1,,0,1,9",
	"2,1,,0,,9",
	"3,1,,0,,9",
	"4,1,,0,,9",
	"5,1,,0,,9",
	"6,1,,0,,9",
	"7,1,,0,,9",
	"8,1,+8,8,,9",
	"9,2,+1,(mint NFT) 9 => 0,2,9",
	"10,2,+1,1,,10",
	"11,2,+1,2,,11",
	"12,2,+1,3,,12",
	"13,2,+1,4,,13",
	"14,2,+1,5,,14",
	"15,2,+1,6,,15",
	"16,2,+1,7,,16",
	"17,2,,7,,17",
	"18,3,+11,(mint NFT) 18 => 0,3,18",
	"19,3,,0,,19",
	"20,3,+6,6,,20",
	"21,3,+3,9,,21",
	"22,3,+3,12,,22",
	"23,3,+3,15,,23",
	"24,3,+3,18,,24",
	"25,3,+3,21,,25",
	"26,3,+3,24,,26",
	"27,4,+3,(mint NFT) 27 => 0,4,27",
	"28,4,+4,4,,28",
	"29,4,+4,8,,29",
	"30,4,+4,12,,30",
	"31,4,+4,16,,31",
	"32,4,+4,20,,32",
	"33,4,+4,24,,33",
	"34,4,+4,28,,34",
	"35,4,+4,32,,35",
	"36,5,+4,(mint NFT) 36 => 0,5,36",
	"37,5,+5,5,,37",
	"38,5,+5,10,,38",
	"39,5,+5,15,,39",
	"40,5,+5,20,,40",
	"41,5,+5,25,,41",
	"42,5,+5,30,,42",
	"43,5,+5,35,,43",
	"44,5,+5,40,,44",
	"45,6,+5,(mint NFT) 45 => 0,6,45",
	"46,6,+6,6,,46",
	"47,6,+6,12,,47",
	"48,6,+6,18,,48",
	"49,6,+6,24,,49",
	"50,6,+6,30,,50",
	"51,6,+6,36,,51",
	"52,6,+6,42,,52",
	"53,6,+6,48,,53",
	"54,7,+6,(mint NFT) 54 => 0,7,54",
	"55,7,+7,7,,55",
	"56,7,+7,14,,56",
	"57,7,+7,21,,57",
	"58,7,+7,28,,58",
	"59,7,+7,35,,59",
	"60,7,+7,42,,60",
	"61,7,+7,49,,61",
	"62,7,+7,56,,62",
	"63,8,+7,(mint NFT) 63 => 0,8,63",
	"64,8,+8,8,,64",
	"65,8,+8,16,,65",
	"66,8,+8,24,,66",
	"67,8,+8,32,,67",
	"68,8,+8,40,,68",
	"69,8,+8,48,,69",
	"70,8,+8,56,,70",
	"71,8,+8,64,,71",
	"72,9,+8,(mint NFT) 72 => 0,9,72",
	"73,9,+9,9,,73",
	"74,9,+9,18,,74",
	"75,9,+9,27,,75",
	"76,9,+9,36,,76",
	"77,9,+9,45,,77",
	"78,9,+9,54,,78",
	"79,9,+9,63,,79",
	"80,9,+9,72,,80",
	"81,10,+9,(mint NFT) 81 => 0,10,81",
	"82,10,+10,10,,82",
	"83,10,+10,20,,83",
	"84,10,+10,30,,84",
	"85,10,+10,40,,85",
	"86,10,+10,50,,86",
	"87,10,+10,60,,87",
	"88,10,+10,70,,88",
	"89,10,+10,80,,89",
	"90,11,+10,(mint NFT) 90 => 0,11,90",
	"91,11,+11,11,,91",
	"92,11,+11,22,,92",
	"93,11,+11,33,,93",
	"94,11,+11,44,,94",
	"95,11,+11,55,,95",
	"96,11,+11,66,,96",
	"97,11,+11,77,,97",
	"98,11,+11,88,,98",
	"99,12,+11,(mint NFT) 99 => 0,12,99",
	"100,12,+12,12,,100",
	"101,12,+12,24,,100",
	"102,12,+12,36,,100",
	"103,12,+12,48,,100",
	"104,12,+12,60,,100",
	"105,12,+12,72,,100",
	"106,12,+12,84,,100",
	"107,12,+12,96,,100",
	"108,13,+12,(mint NFT) 108 => 8,13,100",
	"109,13,+13,21,,100",
	"110,13,+13,34,,100",
	"111,13,+13,47,,100",
	"112,13,+13,60,,100",
	"113,13,+13,73,,100",
	"114,13,+13,86,,100",
	"115,13,+13,99,,100",
	"116,14,+13,(mint NFT) 112 => 12,14,100",
	"117,14,+14,26,,100",
	"118,14,+14,40,,100",
	"119,14,+14,54,,100",
	"120,14,+14,68,,100",
	"121,14,+14,82,,100",
	"122,14,+14,96,,100",
	"123,15,+14,(mint NFT) 110 => 10,15,100",
	"124,15,+15,25,,100",
	"125,15,+15,40,,100",
	"126,15,+15,55,,100",
	"127,15,+15,70,,100",
	"128,15,+15,85,,100",
	"129,16,+15,(mint NFT) 100 => 0,16,100",
	"130,16,+16,16,,100",
	"131,16,+16,32,,100",
	"132,16,+16,48,,100",
	"133,16,+16,64,,100",
	"134,16,+16,80,,100",
	"135,16,+16,96,,100",
	"136,17,+16,(mint NFT) 112 => 12,17,100",
	"137,17,+17,29,,100",
	"138,17,+17,46,,100",
	"139,17,+17,63,,100",
	"140,17,+17,80,,100",
	"141,17,+17,97,,100",
	"142,18,+17,(mint NFT) 114 => 14,18,100",
	"143,18,+18,32,,100",
	"144,18,+18,50,,100",
	"145,18,+18,68,,100",
	"146,18,+18,86,,100",
	"147,19,+18,(mint NFT) 104 => 4,19,100",
	"148,19,+19,23,,100",
	"149,19,+19,42,,100",
	"150,19,+19,61,,100",
	"151,19,+19,80,,100",
	"152,19,+19,99,,100",
	"153,20,+19,(mint NFT) 118 => 18,20,100",
	"154,20,+20,38,,100",
	"155,20,+20,58,,100",
	"156,20,+20,78,,100",
	"157,20,+20,98,,100",
	"158,21,+20,(mint NFT) 118 => 18,21,100",
	"159,21,+21,39,,100",
	"160,21,+21,60,,100",
	"161,21,+21,81,,100",
	"162,22,+21,(mint NFT) 102 => 2,22,100",
	"163,22,+22,24,,100",
	"164,22,+22,46,,100",
	"165,22,+22,68,,100",
	"166,22,+22,90,,100",
	"167,23,+22,(mint NFT) 112 => 12,23,100",
	"168,23,+23,35,,100",
	"169,23,+23,58,,100",
	"170,23,+23,81,,100",
	"171,24,+23,(mint NFT) 104 => 4,24,100",
	"172,24,+24,28,,100",
	"173,24,+24,52,,100",
	"174,24,+24,76,,100",
	"175,25,+24,(mint NFT) 100 => 0,25,100",
	"176,25,+25,25,,100",
	"177,25,+25,50,,100",
	"178,25,+25,75,,100",
	"179,26,+25,(mint NFT) 100 => 0,26,100",
	"180,26,+26,26,,100",
	"181,26,+26,52,,100",
	"182,26,+26,78,,100",
	"183,27,+26,(mint NFT) 104 => 4,27,100",
	"184,27,,4,,100",
	"185,27,+54,58,,100",
	"186,27,+27,85,,100",
	"187,28,+27,(mint NFT) 112 => 12,28,100",
	"188,28,+28,40,,100",
	"189,28,+28,68,,100",
	"190,28,+28,96,,100",
	"191,29,+28,(mint NFT) 124 => 24,29,100",
	"192,29,+29,53,,100",
	"193,29,+29,82,,100",
	"194,30,+29,(mint NFT) 111 => 11,30,100"
];

export default function MechDistributionTable() {

	return(
		<div style={{"width": "100%"}}>
			<div style={{"padding-left": "6vw", "padding-right": "6vw"}}>
				<div className="mechapunkx-tokens-title" style={{"padding-bottom": "2vw", "padding-top": "3vw"}}>How to mint the most 
					MechaPunkx for free?</div>
				
				<div>
				Claim your MECH Tokens as soon as you have enough to mint a new NFT.
				<br></br>
				<br></br>
				Your balance of MechaPunkx NFT and MECH Token should roughly match the chart below that approximates the result. 
				<br></br>
				<br></br>
				If starting after Day 1, or if you fall behind the claim + mint schedule, the table shows the number of MECH & MechaPunkx to get max rewards going forward.
				</div>
				<br></br>
				<br></br>
				<div className="mechapunkx-tokens-title">Max Reward Schedule (Estimated)</div>
				<br></br>
				<br></br>
			</div>
			<div className="mobile-warning" style={{"padding-right": "6vw", "padding-left": "6vw"}}>
				(Viewable on Desktop browser).
			</div>
			<div className="text-small mpx-inv-table-div">
				<table className="mpx-inv-table" width="100%" style={{"border-collapse": "collapse", "table-layout": "fixed"}}> 
				{/*style={{"background-color": "red"}}>*/}
					<thead style={{"position": "sticky", "top": "0", "text-align": "right", "background-color": "#2b2b2b"}}>
						<tr>
							<td style={{"padding-bottom": "1vw", "padding-top": "1vw"}} colspan="2"><span style={{"color": "red"}}>MECH</span> Earned / Day</td>
							<td style={{"padding-bottom": "1vw", "padding-top": "1vw"}}><span style={{"color": "red"}}>MECH</span> Claimed</td>
							<td style={{"padding-bottom": "1vw", "padding-top": "1vw"}}><span style={{"color": "red"}}>MECH</span> Balance </td>
							<td style={{"padding-bottom": "1vw", "padding-top": "1vw"}}><span style={{"color": "red"}}>MECHAPUNKX</span></td>
							<td className="mpx-inv-table-last" style={{"padding-bottom": "1vw", "padding-top": "1vw", "padding-left": "1vw"}}>Mint Cost</td>
						</tr>
					</thead>
					<tbody style={{"text-align": "right"}}>
						
						{/*<tr style={{"padding-bottom": "50px"}}><td></td></tr>*/}
						{/*<tr>
							<td></td>
							<td>MECH Earned / Day</td>
							<td>MECH Claimed</td>
							<td>MECH Balance </td>
							<td>MECHAPUNKX</td>
						</tr>*/}
						{/*<br></br>*/}

						{/*"194,30,+29 (mint NFT),111 => 11,30,100",*/}
						{/*.slice(0,20)*/}
						{/*424242*/}
						{/*<tr style={rowCount === 193 ? ({"background-color": "red"}): ({"background-color": "#333333"})}>*/}
						<tr height="10px"></tr>
						{distributionData.map((row, rowCount) => (
							<tr>
							{row.split(",").map((field, i) => {
								if (i === 0 && rowCount === 193) return (<td style={{"text-align": "left", "color": "yellow", "white-space": "nowrap"}}>Day {field}</td>);
								else if (i === 0) return (<td style={{"text-align": "left", "white-space": "nowrap"}}>Day {field}</td>);
								else if (i == 3) {
									if (field.includes("mint NFT")) {
										field = field.replace(/\(mint NFT\) /, '');
										return (<td><span style={{"color": "#424242", "white-space": "nowrap"}}>(Mint NFT)</span> &nbsp;{field}</td>); 
									}
									else return (<td style={{"white-space": "nowrap"}}>{field}</td>); 
								}
								else if (i == 4 && field.includes("30")) return (<td style={{"color": "yellow", "white-space": "nowrap"}}>{field}</td>);
								else if (i == 5) return (<td className="mpx-inv-table-last" style={{"padding-left": "1vw", "white-space": "nowrap"}}>{field}</td>);
								else {
									return (<td style={{"white-space": "nowrap"}}>{field}</td>);
								}
							})}
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);

}

/*
(<td style={{"color": "gray"}}>{field}</td>);
<ImageListItem key={"mpx-inv-img-" + tokenId.toString()} className="mpx-inv-img-selectable" onClick={selectMechaPunkx}>
							<img src={`${BirdIcon13}`} srcSet={`${BirdIcon13}`} loading="lazy"></img>
						</ImageListItem>
highlight the 30 at the end of the table
blur previous days a bit ******
Text field, based on current day,
assuming has X mech and Y mechapunkx,
show how many MPX can unlock by the end ****
toggle for simple view
*/