import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Image from 'material-ui-image'
import BirdIcon13 from '../assets/icons/birdIcon13.png'
import Tooltip from '@mui/material/Tooltip';


export interface AccountViewItemsProps {
	handleClickItem: String;
	items: Array;
	accountViewRefreshItems: Object;
}

export default function AccountViewItems(props: AccountViewItemsProps) {

	const errorImage = <Tooltip id="tooltip-err" title={"Revealing..."} placement="top-start" open={true}>
		<div id="tooltip-err-inner" style={{"color": "white", "font-size": "6vw", "font-weight": "bold"}}>?</div></Tooltip>


	return(
		<div key="account-view-items" style={{"display": "flex", "flex-direction": "column", 
			"padding-top": "0vw", "padding-bottom": "2vw", "padding-left": "3vw", "padding-right": "3vw"}}>
			{/*262B2F*/}

			<div style={{"background-color": "#212224", "display": "flex", 
				"flex-direction": "row", "border-radius": "8px", "padding-left": "1vw", "padding-right": "1vw"}}>

				{/* Any limit to number loaded ?? */}

				{/*{props.items.map((item) => (
					<div onClick={() => props.handleClickItem(item)}>
						<img src={item.src} height="100px" width="100px"/>
						{item.tokenId}
					</div>
				))}*/}

				<ImageList key={"account-imagelist"} sx={{ width: "100%", height: "100%", "overflow": "hidden"}} cols={14} >
					{props.items.slice(0,100).map((item) => (
						<ImageListItem key={"mpx-inv-img-" + item.tokenId.toString() + "-" + Math.random().toString()} 
							className="mpx-inv-img-selectable-acc"
							onClick={(element) => {
								let hasErrorOverlay = element.target.parentNode.querySelector("#tooltip-err-inner") != null
								if (!hasErrorOverlay) {
									props.handleClickItem(item);
								}
							}}
						>
							<Image  
								alt=""
								src={item.src}
								srcSet={item.src}
								// src={"/mechapunkx/images/7.png"}
								// srcSet={"/mechapunkx/images/7.png"}
								// src={ (item.tokenId == 921) ? "https://lambo.lol/1234.png" : BirdIcon13}
								// src={"https://lambo.lol/1234.png"}
								// srcSet={ (item.tokenId == 921) ? "https://lambo.lol/1234.png" : BirdIcon13}
								// srcSet={"https://lambo.lol/1234.png"}
								// style={{"background-color": "#1e1e1e"}}
								style={{"background-color": "#1e1e1e", "color": "white", "border-radius": "4px"}}
								disableSpinner={false}
								errorIcon={errorImage}
								animationDuration={1000}
								loading="lazy"
							/>
						</ImageListItem>
					))}
				</ImageList>





			</div>
		</div>
	);
}