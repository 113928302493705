import * as React from 'react';
import Box from '@mui/material/Box';
import { styled, ThemeProvider, createTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import GalleryFilterCat from './gallery-filter-cat';

//import ListItemIcon from '@material-ui/icons';
//import IconButton from '@material-ui/icons/IconButton';

import ArrowRight from '@material-ui/icons/ArrowRight';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import Home from '@material-ui/icons/Home';
import Settings from '@material-ui/icons/Settings';
import People from '@material-ui/icons/People';
import PermMedia from '@material-ui/icons/PermMedia';
import Dns from '@material-ui/icons/Dns';
import Public from '@material-ui/icons/Public';
import { ExpandLess as ListItemIcon } from "@material-ui/icons";
import { ExpandMore as IconButton } from "@material-ui/icons";

import Cloud1 from './assets/icons/categories/cloud_1.png';
import Energy4 from './assets/icons/categories/energy_4.png';
import Strength2 from './assets/icons/categories/strength_2.png';
import RunningMan from './assets/icons/categories/agility_1.png';
import Wood1 from './assets/icons/categories/wood_1.png';
import Psychic8 from './assets/icons/categories/psychic_8.png';
import Sound4 from './assets/icons/categories/sound_4.png';
import Hammer from './assets/icons/categories/attack_7.png';
import Craftsman9 from './assets/icons/categories/craftsman_9.png';

const iconsCategories = {
  'Background': Cloud1,
  'Skin': Energy4,
  'Armor': Strength2,
  'Armor Version': Strength2,
  'Body': RunningMan,
  'Hair': Wood1,
  'Eyes': Psychic8,
  'Mouth': Sound4,
  'Item': Hammer,
  'Other': Craftsman9
}


const data = [
  { icon: <People />, label: 'Type' },
  { icon: <Dns />, label: 'Armor' },
  { icon: <Dns />, label: 'Armor (specific)' },
  { icon: <Public />, label: 'Eyes' },
  { icon: <Public />, label: 'Hair' },
  { icon: <Public />, label: 'Mouth' },
  { icon: <PermMedia />, label: 'Item' },
  { icon: <PermMedia />, label: 'Other' }
];


const FireNav = styled(List)({
  '& .MuiListItemButton-root': {
    paddingLeft: 24,
    paddingRight: 24,
  },
  '& .MuiListItemIcon-root': {
    minWidth: 0,
    marginRight: 16,
  },
  '& .MuiSvgIcon-root': {
    fontSize: 20,
  },
});

export default function GalleryFilter(props) {
  
  // const [open, setOpen] = React.useState(true);
  
  // console.log("Got props: ", props.categories);

  const categories = props.categories;
  // console.log("SETUP: ", categories);
  const orderedCatList = ["Background", "Skin", "Armor", "Armor Version", "Body", "Hair", "Eyes", "Mouth", "Item", "Other"];
  
  /*
  Object.entries(categories).forEach(cat, values) => (
     console.log("CAT: ", cat);
    ));
  */

  // TMP mock quantities
  let categoriesWithQuantities = {}
  let catKeys = Object.keys(categories);

  for (let i=0; i<catKeys.length; i++) {
    let cat = catKeys[i];
    // console.log("CATS: ", categories);
    // console.log("Cat: ", cat);
    let values = categories[cat];
    let tmp = [];
    for (let j=0; j<values.length; j++) {
      let v = values[j];
      tmp.push({
        'name': v,
        'quantity': 0 //Math.floor(Math.random() * 900)
      });
    }
    categoriesWithQuantities[cat] = tmp;
  }


  return (
    
    <Box sx={{ display: 'flex', "flex-direction": "column", "justify-content": "center", "align-items": "center", 
      'ml': "4vw", 'mr': "4vw", "mt": "4vw"}}>

      <ThemeProvider
        theme={createTheme({
          components: {
            MuiListItemButton: {
              defaultProps: {
                disableTouchRipple: true,
              },
            },
          },
          palette: {
            mode: 'dark',
            // primary: { main: 'rgb(102, 157, 246)' },
            // background: { paper: 'rgb(5, 30, 52)' },
            primary: { main: 'rgb(255, 100, 0)' },
            // background: { paper: 'rgb(255, 0, 0)' },
            background: { paper: '#1e1e1e' },
          },
        })}
      >
        {/*maxWidth: 256 */}

        <Paper elevation={0} sx={{ 'display': 'flex', 'flex-direction': 'column', 'width': '100%', borderRadius: "8px"}}>
          <FireNav component="nav" disablePadding>
          
          <Box sx={{px: 3, pt: 2.5, pb: 2.5,
            '&:hover, &:focus': { '& svg': { opacity: 1 } },
          }}>Search</Box>
          
          {/*<div style={{"color": "white"}}>Search:
          </div>
          */}  
            {/*
            <ListItemButton component="a" href="#customized-list">
              <ListItemIcon sx={{ fontSize: 20 }}>🔥</ListItemIcon>
              <ListItemText
                sx={{ my: 0 }}
                primary="Filter"
                primaryTypographyProps={{
                  fontSize: 20,
                  fontWeight: 'medium',
                  letterSpacing: 0,
                }}
              />
            </ListItemButton>
            
            <Divider />
            
            <ListItem component="div" disablePadding>
              <ListItemButton sx={{ height: 56 }}>
                <ListItemIcon>
                  <Home color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary="Project Overview"
                  primaryTypographyProps={{
                    color: 'primary',
                    fontWeight: 'medium',
                    variant: 'body2',
                  }}
                />
              </ListItemButton>
              <Tooltip title="Project Settings">
                <IconButton
                  size="large"
                  sx={{
                    '& svg': {
                      color: 'rgba(255,255,255,0.8)',
                      transition: '0.2s',
                      transform: 'translateX(0) rotate(0)',
                    },
                    '&:hover, &:focus': {
                      bgcolor: 'unset',
                      '& svg:first-of-type': {
                        transform: 'translateX(-4px) rotate(-20deg)',
                      },
                      '& svg:last-of-type': {
                        right: 0,
                        opacity: 1,
                      },
                    },
                    '&:after': {
                      content: '""',
                      position: 'absolute',
                      height: '80%',
                      display: 'block',
                      left: 0,
                      width: '1px',
                      bgcolor: 'divider',
                    },
                  }}
                >
                  <Settings />
                  <ArrowRight sx={{ position: 'absolute', right: 4, opacity: 0 }} />
                </IconButton>
              </Tooltip>
            </ListItem>
            <Divider />
            */}

            {orderedCatList.map((cat) => {
              if (cat.includes("Version")) {
                return <GalleryFilterCat name={cat} values={[]} icon={iconsCategories[cat]}/>
              }
              else {
                let values = categories[cat];
                // categoriesWithQuantities
                return <GalleryFilterCat name={cat} values={categoriesWithQuantities[cat]} icon={iconsCategories[cat]}/>
              }
            })}
                        
            {/*<Divider/>*/}
            
            {/*
            {Object.keys(categories).map((cat) => (
              <GalleryFilterCat name={cat}/>
            ))}
            */}


            {/*
            <GalleryFilterCat name={"Background"}/>
            <GalleryFilterCat name={"Type"}/>
            <Divider />
            <GalleryFilterCat name={"Armor"}/>
            <GalleryFilterCat name={"Armor Version"}/>
            <GalleryFilterCat name={"Body"}/>
            <GalleryFilterCat name={"Hair"}/>
            <GalleryFilterCat name={"Eyes"}/>
            <GalleryFilterCat name={"Mouth"}/>
            <GalleryFilterCat name={"Item"}/>
            <GalleryFilterCat name={"Other"}/>
          */}

          </FireNav>
        </Paper>
      </ThemeProvider>
    </Box>
  );
}


/*
<div className="image-grid" style={{ marginTop: "30px" }}>
  {loaded ? 
      images.map((image, index) => (
        <GalleryImage url={image} key={index} handleClickOpen={handleClickOpen}/>
      )): ""}
</div>
*/
