import React from 'react'
import Divider from '@mui/material/Divider';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { Fade } from '@mui/material';
import Image from 'material-ui-image'
import { Link as RouterLink } from 'react-router-dom';
import useSound from 'use-sound';
import ClickSound from '../sounds/click.wav';

import MechaPunkxLogo from '../assets/mechapunkxLogoText.png'
import DiscordLogo from '../assets/links/discord_logo_circle.png';
import TwitterLogo from '../assets/links/twitter_logo_circle.png';
import TreasureLogo from '../assets/links/treasure_logo_circle3black.png';
import sample_frog from '../assets/mechapunkx/output_7722.png';
import arrowRight from '../assets/arrowRight.png';
import arrowDown from '../assets/arrowDown.png';

import MonkeyIcon from '../assets/icons/monkeyIcon.png'
import BirdIcon from '../assets/icons/birdIcon.png'
import BirdIcon2 from '../assets/icons/birdIcon2.png'
import BirdIcon3 from '../assets/icons/birdIcon3.png'
import BirdIcon4 from '../assets/icons/birdIcon4.png'
import BirdIcon5 from '../assets/icons/birdIcon5.png'
import BirdIcon6 from '../assets/icons/birdIcon6.png'
import BirdIcon7 from '../assets/icons/birdIcon7.png'
import BirdIcon8 from '../assets/icons/birdIcon8.png'
import BirdIcon9 from '../assets/icons/birdIcon9.png'
import BirdIcon10 from '../assets/icons/birdIcon10.png'
import BirdIcon11 from '../assets/icons/birdIcon11.png'
import BirdIcon12 from '../assets/icons/birdIcon12.png'
import BirdIcon13 from '../assets/icons/birdIcon13.png'
import BirdIcon14 from '../assets/icons/birdIcon14.png'
import BirdIcon15 from '../assets/icons/birdIcon15.png'
import CursorIcon from '../assets/icons/cursor2.png'


// import sample_1 from '../assets/mechapunkx/output_7880.png';
// import sample_2 from '../assets/mechapunkx/output_8459.png';
// import sample_3 from '../assets/mechapunkx/output_1492.png';
// import sample_4 from '../assets/mechapunkx/output_463.png';

import sample_5 from '../assets/mechapunkx/output_1492.png';
import sample_6 from '../assets/mechapunkx/dragonzord_full_proc.png';
import sample_7 from '../assets/mechapunkx/redShogun3.png';
import sample_8 from '../assets/mechapunkx/output_8452.png';

// import sample_9 from '../assets/mechapunkx/output_259_proc.png';
// import sample_10 from '../assets/mechapunkx/output_8188.png';
// import sample_11 from '../assets/mechapunkx/output_7880.png';
// import sample_12 from '../assets/mechapunkx/output_7806.png';

// import sample_13 from '../assets/mechapunkx/output_1351.png';
// import sample_14 from '../assets/mechapunkx/redShogun3.png';
// import sample_15 from '../assets/mechapunkx/output_8225.png';
// import sample_16 from '../assets/mechapunkx/output_1497.png';



export default function Home() {

	const [playClick] = useSound(ClickSound, { volume: 0.5 });

	let iconImages = [MonkeyIcon, BirdIcon, BirdIcon13, BirdIcon3, BirdIcon15, BirdIcon5, BirdIcon6, BirdIcon10, BirdIcon8, BirdIcon9, 
		BirdIcon7, BirdIcon11, BirdIcon12, BirdIcon2, BirdIcon14, BirdIcon4];

	// let [iconIdx, setIconIdx] = React.useState(Math.min(Math.floor(Math.random() * iconImages.length), iconImages.length));
	let [iconIdx, setIconIdx] = React.useState(0);

	const nextIconIdx = () => {
		if (iconIdx == iconImages.length - 1) return 0
		else return iconIdx + 1
	}
	const nextIcon = () => {
		setIconIdx(nextIconIdx);
	}

	setTimeout(() => {
	 	nextIcon();
    }, 3000);

	let bannerGroups = [
		//[sample_1, sample_2, sample_3, sample_4],
		[sample_5, sample_6, sample_7, sample_8]
		//[sample_9, sample_10, sample_11, sample_12],
		//[sample_13, sample_14, sample_15, sample_16]
	]

	// let date = new Date();
	// let sampleIdx = Math.floor((date.getHours() / 24) * 4);
	let sampleImages = bannerGroups[0];

  	return (

    <div className="mechapunkx-home mechapunkx-home-text" style={{"line-height": "1.2"}}>
    	
    	<Fade in={true} appear={true} timing={300}>
	      	<div className="mechapunkx-home-title padding-page noselect">
		      {/*<span style={{"color": "#000eff"}}>MECHA</span><span style={{"color": "#e81a00"}}>PUNKX</span>*/}
		      	<span style={{"color": "#e81a00"}}>MECHA</span><span style={{"color": "#000eff"}}>PUNK</span><span style={{"color": "blue"}}>X</span>
		       <img src={iconImages[iconIdx]} className="mechapunkx-home-icon noselect" 
		       		style={{"padding-left": "3vw", "cursor": "pointer"}}></img>
		       	{/*onClick={nextIcon}*/}
	      	</div>
      	</Fade>

      	{/*<div className="mechapunkx-home-title noselect" style={{"display": "flex", "flex-direction": "row", 
      		"justify-content": "center", "align-items": "center",
      		"padding-right": "2vw", "padding-top": "1vw", "padding-bottom": "1vw"
      	}}>	     
	      <img src={MechaPunkxLogo} width={"88%"}></img>
	       <img src={iconImages[iconIdx]} className="mechapunkx-home-icon noselect" 
	       	height="100px" width="100px" style={{"padding-left": "1vw", "cursor": "pointer"}} onClick={nextIcon}></img>
      	</div>*/}

      	{/*<div className="mechapunkx-home-title-spacer noselect">-</div>*/}


      	<div className="mechapunkx-home-banner-top noselect">
	      	<Image 
				src={sampleImages[0]}
				aspectRatio={"1/1"}
				style={{"background-color": "#1e1e1e", "width": "22%", "padding": "0"}}
				imageStyle={{"background-color": "#1e1e1e", "height": "auto", "position": "relative", borderRadius: "0.75rem"}}
				disableSpinner={true}
				animationDuration={3500}
				alt="epstein"
			/>
			<Image 
				src={sampleImages[1]}
				aspectRatio={"1/1"}
				style={{"background-color": "#1e1e1e", "width": "22%", "padding": "0"}}
				imageStyle={{"background-color": "#1e1e1e", "height": "auto", "position": "relative", borderRadius: "0.75rem"}}
				disableSpinner={true}
				animationDuration={3500}
				alt="didn't"
			/>
			<Image 
				src={sampleImages[2]}
				aspectRatio={"1/1"}
				style={{"background-color": "#1e1e1e", "width": "22%", "padding": "0"}}
				imageStyle={{"background-color": "#1e1e1e", "height": "auto", "position": "relative", borderRadius: "0.75rem"}}
				disableSpinner={true}
				animationDuration={3500}
				alt="kill"
			/>
			<Image 
				src={sampleImages[3]}
				aspectRatio={"1/1"}
				style={{"background-color": "#1e1e1e", "width": "22%", "padding": "0"}}
				imageStyle={{"background-color": "#1e1e1e", "height": "auto", "position": "relative", borderRadius: "0.75rem"}}
				disableSpinner={true}
				animationDuration={3500}
				alt="himself"
			/>
      	</div>
      	<div style={{"display": "flex", "flex-direction": "row", "justify-content": "end", "padding-right": "2.3vw", "background-color": "black"}}>
      		{/*4b4b4b*/}
      		{/*025dc7*/}
      		{/*103f76*/}
      		<RouterLink to="/mechapunkx/gallery" onClick={() => playClick()}>
		      	<div className="mechapunkx-home-gallery-link noselect" 
					style={{"color": "white", "background-color": "#4b4b4b", "border-radius": "8px",
					"display": "flex", "flex-direction": "row", "align-items": "center"
				}}>
					<div>Check Out The Gallery!</div>
					<div className="mechapunkx-home-gallery-link-icon">
						<img src={CursorIcon} height="100%"/>
					</div>
				</div>
			</RouterLink>
		</div>
      	<div className="mechapunkx-home-bottom">
      		<div style={{"padding-right": "2vw", "padding-left": "2vw"}}>
	      		<div className="mechapunkx-home-content-top" style={{"justify-content": "space-around"}}>
	      			{/*<div className="mechapunkx-home-content-left">
	      				<div className="padding-page noselect" style={{"display": "flex", 
	      					"flex-direction": "column", "justify-content": "left", "padding-top": "2vw"}}>
	      					<div style={{"display": "flex", "flex-direction": "row"}}>
	      						<a className="mechapunkx-home-links-logo" href="https://twitter.com/lamboc0rp" target="_blank">
	      							<img src={TwitterLogo} style={{"width": "max(2.6vw, 27px)", "margin-right": "1vw"}}></img></a>
	      						<a className="mechapunkx-home-links-logo" href="https://discord.gg/VbSjbrFsPt" target="_blank">
	      							<img src={DiscordLogo} style={{"width": "max(2.6vw, 27px)", "margin-right": "1vw"}}></img></a>
	      						<a className="mechapunkx-home-links-logo" href="https://treasure.lol" target="_blank">
	      							<img src={TreasureLogo} style={{"width": "max(2.6vw, 27px)", "margin-right": "1vw"}}></img></a>
	      					</div>
			      			<div style={{"color": "gray", "padding-top": "2vw"}}>Contract:</div>
			      			<div style={{"color": "gray", "padding-top": "0vw"}}>0x</div>
			      			<div style={{"padding-top": "2vw"}}>Whitelist check:</div>
			      			<div style={{"padding-top": "1vw"}}>
			      				<TextareaAutosize
			      					aria-label="whitelist"
			      					placeholder="Coming soon"
			      					className="mechapunkx-home-wl-textarea"
			      				/>
			      			</div>
			      		</div>
		      		</div>*/}
		      		      
		      		<div className="mechapunkx-home-content-right padding-page">
			      		<p>MechaPunkx is an NFT collection of 7,848 punks in mech suits. MechaPunkx launches exclusively on <a href="https://trove.treasure.lol/collection/mechapunkx" target="_blank">
							<span style={{"color": "red"}}>TROVE</span></a> marketplace on Arbitrum.
						</p>
						<p>
						</p>
						<br></br>
						<div className="mechapunkx-home-subtitle" style={{"color": "blue", "font-family": "Kanit", "margin-top": "2vw", "margin-bottom": "2vw"}}>
			      			<span style={{"color": "red"}}>HOLD, <span className="text-lightblue">
			      				EARN,</span> <span style={{"color": "red"}}>GET NEW PUNKX</span></span>
			      		</div>
			      		<br></br>

			      		

						<div className="tokens-text-summary" style={{"width": "100%", "margin-top": "2vw", "margin-bottom": "10vw"}}>
							{/*, "table-layout": "fixed"*/}
							{/*"max-width": "100%", */}
							<table style={{"width": "100%", "table-layout": "fixed"}}>
								<tbody>
									<tr>
										<td style={{"width": "40%"}}>
											<div style={{"display": "flex", "flex-direction": "row"}}>
											<img src={sample_frog} width="80%" style={{"border-radius": "10px", "border": "6px solid #585858"}}/>
											</div>
										</td>
										{/*<td style={{"width": "5%"}}>
											<span style={{"border": "4px solid #585858", "padding": "8px", "border-radius": "8px", "color": "#585858"}}>=></span>
										</td>*/}
										<td style={{"vertical-align": "middle"}}>
											<div>
												<span style={{"padding-right": "3vw", "color": "#585858"}}><img src={arrowRight} height="25px"/></span>
												Earns 1 MECH / day
											</div>
											<div style={{"padding-top": "1.5vw"}}>
												<span style={{"padding-right": "3vw", "opacity": 0}}><img src={arrowRight} height="25px"/></span>
												Mint new MechaPunkx using MECH
											</div>
											{/*<ul style={{"list-style-type": "none"}}>
												<li>Earns 1 MECH / day</li>
												<li style={{"padding-top": "1.5vw"}}>Mint new MechaPunkx using MECH</li>
											</ul>*/}
										</td>
									</tr>
									<tr>
										<td style={{"text-indent": "11vw", "font-weight": "bold", "color": "#585858"}}>
											{/*style={{"border": "8px solid #585858", "padding": "1vw", "border-radius": "8px"}}*/}
											<img src={arrowDown} height="25px" style={{"padding-top": "4vw"}}/>
										</td>
										<td>
										</td>
										
									</tr>
									<tr>
										<td style={{"text-align": "left", "padding-right": "0vw", "vertical-align": "top", "padding-top": "2vw"}}>
											{/*<span style={{"border": "4px solid white", "border-radius": "8px", "padding": "0.5vw"}}>*/}
												Convert
											{/*</span>*/}
											<span style={{"padding-left": "1vw"}}> (Costs 50 Mech)
											</span>
										</td>
										{/*<td style={{"width": "5%", "vertical-align": "top"}}>
											<span style={{"border": "4px solid #585858", "padding": "8px", "border-radius": "8px", "color": "#585858"}}>=></span>
										</td>*/}
										<td style={{"vertical-align": "top", "display": "table-cell", "padding-top": "2vw", "white-space": "nowrap"}}>
											<div style={{"display": "flex", "flex-direction": "row"}}>
												<div style={{"padding-right": "3vw", "color": "#585858"}}>
													<img src={arrowRight} height="25px"/>
												</div>
												<div>Earns LAMBO daily </div>
												<div><span style={{"color": "#585858"}}>&nbsp;(instead of MECH)</span></div>
											</div>
											<div style={{"padding-top": "1.5vw"}}>
												<span style={{"padding-right": "3vw", "opacity": 0}}><img src={arrowRight} height="25px"/></span>
												Used in LamboCorp World game
												<div><span style={{"padding-right": "3vw", "opacity": 0}}><img src={arrowRight} height="25px"/></span>
												and across LamboCorp
												</div>
											</div>
											
										</td>
									</tr>
								</tbody>
							</table>
						</div>


						<p>
							{/*<span style={{"color": "red"}}>Mecha</span>Punkx?*/}
							<span className="mechapunkx-home-subtitle text-lightblue">LAUNCH</span><span className="mechapunkx-home-subtitle2"> (JUNE 20th)</span>
							<img src={BirdIcon2} style={{"height": "3.5vw", "width": "3.5vw", "margin-left": "1vw"}}></img>
						</p>
						<div style={{"padding-left": "1vw"}}>
							<ul>
								{/*<li style={{"margin-bottom": "12px", "list-style": "none"}}>June 20th</li>*/}
								<li style={{"margin-bottom": "12px"}}>200 Whitelist</li>
								<li style={{"margin-bottom": "12px"}}>200 Pre-sale <span className="mechapunkx-home-subtext">(0.1 ETH)</span></li>
								<li style={{"margin-bottom": "12px"}}>30 Dev & Marketing</li>
								<li style={{"margin-bottom": "12px"}}>7,418 Free to MechaPunkx holders, distributed over 6 months</li>
								<li style={{"margin-bottom": "12px"}}>Total: 7,848</li>
							</ul>
						</div>

						<br></br>
						<p>
							{/*<span style={{"color": "red"}}>Mecha</span>Punkx?*/}
							<span className="mechapunkx-home-subtitle text-lightblue">WHY BUY?</span>
							<img src={BirdIcon3} style={{"height": "3.5vw", "width": "3.5vw", "margin-left": "1vw"}}></img>
						</p>
						<p style={{"text-indent": "2vw"}}>
							As the premier NFT collection of LamboCorp: 
						</p>

						<div style={{"padding-left": "2vw"}}>
						<table>
							<tbody style={{"vertical-align": "top"}}>
								<tr>
									<td>1.</td>
									<td>MechaPunkx yield MECH token or LAMBO token (both ERC20s).</td>
									{/*(the early "employees" of <span style={{"color": "yellow"}}>LamboCorp</span>)*/}
								</tr>
								<tr style={{"height": "5px"}}></tr>
								<tr>
									<td></td>
									{/*, and control the distribution of the collection*/}
									{/*, because $LAMBO powers LamboCorp*/}
									{/*<td className="mechapunkx-home-subtext" style={{"line-height": "2em", "padding-top": "14px"}}><span style={{"border": "2px solid gray", "padding": "5px", "border-radius": "4px", "margin-right": "7px"}}>MECH</span> MechaPunkx initially yield MECH token, which enables minting more MechaPunkx.
									<div style={{"padding-top": "14px"}}><span style={{"border": "2px solid gray", "padding": "5px", "border-radius": "4px", "margin-right": "7px"}}>LAMBO</span> Later, MechaPunkx permanently yield LAMBO token, which ties each MechaPunkx NFT directly to the value of LamboCorp itself. LAMBO also allows lifetime free mints of future LamboCorp collections.</div></td>*/}
									
									<td className="mechapunkx-home-subtext" >
										<div style={{"display": "flex", "flex-direction": "row", "justify-content": "left", "align-items": "center", "margin-bottom": "24px", "margin-top": "14px"}}>
											<span style={{"border": "2px solid red", "padding": "5px", "border-radius": "4px", "margin-right": "2vw", "color": "red"}}>MECH</span>
											<span>MechaPunkx initially yield MECH token, which enables minting more MechaPunkx.</span>
										</div>
										<div style={{"display": "flex", "flex-direction": "row", "justify-content": "left", "align-items": "center", "margin-bottom": "20px"}}>
										<span style={{"border": "2px solid #d9d922", "padding": "5px", "border-radius": "4px", "margin-right": "2vw", "color": "#d9d922"}}>LAMBO</span>
											<span>Later, MechaPunkx permanently yield LAMBO token, which ties each MechaPunkx NFT directly to the value of LamboCorp as a whole. LAMBO also allows lifetime free mints of future LamboCorp collections.</span>
										</div>
									</td>
									{/*<td className="mechapunkx-home-subtext" style={{"line-height": "2em", "padding-top": "14px"}}><span style={{"border": "2px solid gray", "padding": "5px", "border-radius": "4px", "margin-right": "5px"}}>MECH</span> MechaPunkx initially yield MECH token, which enables minting more MechaPunkx.
									<div style={{"padding-top": "14px"}}><span style={{"border": "2px solid gray", "padding": "5px", "border-radius": "4px", "margin-right": "5px"}}>LAMBO</span> Later, MechaPunkx permanently yield LAMBO token, which ties each MechaPunkx NFT directly to the value of LamboCorp itself. LAMBO also allows lifetime free mints of future LamboCorp collections.</div></td>
	*/}
								</tr>
								<tr style={{"height": "10px"}}></tr>
								<tr>
									<td>2.</td>
									<td>MechaPunkx are fun, standalone art collectibles.</td>
								</tr>
								<tr style={{"height": "12px"}}></tr>
								<tr>
									<td></td>
									<td className="mechapunkx-home-subtext"></td>
								</tr>
								<tr>
									<td>3.</td>
									<td>Buy 1, get 30.</td>
								</tr>
								<tr style={{"height": "5px"}}></tr>
								<tr>
									<td></td>
									<td className="mechapunkx-home-subtext">(Thirty is the estimated maximum amount that can be claimed from 1 Pre-Sale MechaPunkx)</td>
								</tr>
								<tr style={{"height": "12px"}}></tr>
								<tr>
									<td>4.</td>
									<td>Future integration</td>
								</tr>
								<tr style={{"height": "5px"}}></tr>
								<tr>
									<td></td>
									<td className="mechapunkx-home-subtext">MechaPunkx fight. MechaPunkx explore. How can they fight with no battle? How can they explore without a world?</td>
								</tr>
							</tbody>
						</table>
						</div>



						<br></br>
						<p>
							{/*<span style={{"color": "red"}}>Mecha</span>Punkx?*/}
							<span className="mechapunkx-home-subtitle text-lightblue">HOW DO I GET 30?</span>
							<img src={BirdIcon14} style={{"height": "3.5vw", "width": "3.5vw", "margin-left": "1vw"}}></img>
						</p>
						<p style={{"text-indent": "2vw"}}>
							Gamified Distribution:
							<br></br>
						</p>
						<p style={{"text-indent": "4vw"}}>
							1. Buy a MechaPunkx (WL / Pre-Sale / Trove).
							<br></br>
						</p>
						<p style={{"text-indent": "4vw"}}>
							2. Earn MECH tokens daily.
							<br></br>
						</p>
						<p style={{"text-indent": "4vw"}}>
							<span>3. Use your MECH tokens, and claim new MechaPunkx, for free</span>.
							<br></br>
						</p>
						<blockquote className="mechapunkx-home-subtext" style={{"padding-left": "1vw"}}>
							{/*If you're disciplined, */}
							Claim up to 30 MechaPunkx over the 6 month distribution. Only 100 MechaPunkx total can be claimed per day on a first-come basis.
						</blockquote>
						<p style={{"text-indent": "2vw", "padding-top": "10px"}}>
							Why "Gamified"?
							<br></br>
						</p>
						<div style={{"padding-left": "2vw"}}>
						<ul>
							<li style={{"margin-bottom": "7px"}}>Fun distribution (new daily releases)</li>
							<li style={{"margin-bottom": "7px"}}>High liquidity (MECH token)</li>
							<li style={{"margin-bottom": "7px"}}>Smooth price discovery (6 month period, no pump & dump)</li>
							<li style={{"margin-bottom": "7px"}}>Highest value for holders (controlled supply & demand)</li>
						</ul>
						</div>
						
						{/*
						<p style={{"text-indent": "2vw"}}>
							This method is likely to provide a distribution that's fun (new daily releases), smooth (6 month period, no pump & dump), is highly liquid (MECH token), and achieves the highest value for holders.
							More details can be found on the Tokenomics page.
							<br></br>
						</p>*/}
						<p style={{"text-indent": "2vw"}}>
							For more details, see <RouterLink 
							to="/mechapunkx/tokens" 
							className="text-brightblue"
							onClick={() => playClick()}
							>Tokens</RouterLink>.
							<br></br>
						</p>
						<p style={{"text-indent": "2vw"}}>
							For Pre-Sale info, keep an eye on our announcements channel in <a href="https://discord.gg/VbSjbrFsPt" target="_blank" className="text-brightblue">discord</a>!
							<br></br>
						</p>


						<br></br>
						<p>
							<span className="mechapunkx-home-subtitle text-lightblue">MARKET</span>
							<img src={BirdIcon10} style={{"height": "3.2vw", "width": "3.2vw", "margin-left": "1vw"}}></img>
						</p><p>
							MechaPunkx is exclusively on <a href="https://twitter.com/TroveByTreasure" target="_blank">
							<span style={{"color": "red"}}>TROVE</span></a> because:
						</p>
						<div style={{"padding-left": "2vw"}}>
							<table>
								<tbody>
									<tr style={{"vertical-align": "top"}}>
										<td>Trove is positioned to be the dominant NFT marketplace by its design as a multi-chain, decentralized (community owned), gamified marketplace. 
											Part of secondary market royalties from trading MechaPunkx go directly to the creators of Trove, Treasure DAO, so they can continue developing the preeminent L2 gaming ecosystem. 
										</td>
									</tr>
									
								</tbody>
							</table>
						</div>
						<br></br>
						<br></br>
						<p>
							<span className="mechapunkx-home-subtitle text-lightblue">ART</span>
							<img src={BirdIcon7} style={{"height": "3.5vw", "width": "3.5vw", "margin-left": "1vw"}}></img>
						</p><p>
						Approximately 369 different armor variations, with many hair styles, weapons, and more. Drawn by <span style={{"color": "yellow"}}>Uncle Tommy</span>.
						</p>

		      		</div>
	      		</div>
      		</div>
      	</div>

    </div>
  );
}