import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';
import { Fade } from '@mui/material';
import Inventory5Small from '../assets/inventory1.png';

export default function About() {
  return (
  	<div className="mechapunkx-about padding-page text-page">
      <div className="mechapunkx-about-main mechapunkx-about-text">
        <Fade in timing={800}>
          <div className="mechapunkx-about-left">
            <div className="noselect mechapunkx-about-title" 
              style={{"padding-bottom": "2vw"}}>About the <span style={{"color": "red"}}>MECHA</span><span style={{"color": "blue"}}>PUNKX</span></div>
            <div style={{"display": "flex", "flex-direction": "row", "justify-content": "center", "align-items": "center", "margin-bottom": "2vw"}}>
              <img src={Inventory5Small} width="100%" style={{"border-radius": "10px"}}/>
            </div>
            <p style={{"text-indent": "2vw"}}>
            The MechaPunkx are just average-Joe punks, but they've been equipped with hundreds of different armors and dozens of items for combat. 
            These explorers of Billy's World have one goal - to find a lost Billy, and bring him home from the vast wilderness. 
            </p>
            <p style={{"text-indent": "2vw"}}>
            The story of MechaPunkx begins where all great stories do, with Uncle Tommy. Many months ago, Uncle Tommy re-opened his factory. 
            His not-so-smart nephew, Billy, had gone missing, and so Uncle Tommy had to do something.
            For the longest time, he didn't know what to do, only that he wanted to find Billy who was lost out there in the dangerous Billy's World. 
            </p>
            <p style={{"text-indent": "2vw"}}>One day Uncle Tommy realized that he and a few LEP's (<a href="https://twitter.com/LowEffortPunks" target="_blank" style={{"color": "#9615c7", "font-weight": "bold"}}>Low Effort Punks</a>) could suit up in Mech suits, explore Billy's World, and simply bring Billy back home. 
            That day though, the universe had different plans, and little did Uncle Tommy know that he himself was about to go on a grand adventure beyond his wildest imagination.
            </p>
            <br></br>
            <h2>Team - Who is <span style={{"color": "yellow"}}>Uncle Tommy</span>?</h2>
            <p style={{"text-indent": "2vw"}}>Uncle Tommy has been described as a One-Man-Army as LamboCorp's solo artist, dev, and marketer. He is LamboCorp's brightest employee and many say he is responsible for its rise to fame. 
            Once after a few too many drinks at a company happy hour, he mentioned his experience in architecting distributed systems.
            </p>
            <p style={{"text-indent": "2vw"}}>
            His history prior to moving to LA remains a mystery, but Billy felt that Uncle Tommy could do just about anything he imagined.
            Today, Uncle Tommy remains committed to the journey of finding Billy, continuously builds, and despises JavaScript while frequently shitposting at <a href="https://twitter.com/zebosXL" target="_blank" style={{"color": "#00deff"}}>@zebosXL</a>.
            </p>
          </div>
        </Fade>
        <div className="mechapunkx-about-right">
          <h2 className="noselect" style={{"padding-bottom": "2vw"}}><span style={{"color": "red"}}>MECHA</span><span style={{"color": "blue"}}>PUNKX</span> Promo #1</h2>
          {/*<Fade in={true} appear={true} timing={2000}>*/}
          <iframe className="mechapunkx-about-promo1" src="https://www.youtube-nocookie.com/embed/1zRdpDA565s" 
            title="YouTube video player" frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
          </iframe>
          {/*</Fade>*/}
          <h2 className="noselect" style={{"margin-top": "50px", "padding-bottom": "2vw"}}><span style={{"color": "red"}}>MECHA</span><span style={{"color": "blue"}}>PUNKX</span> Promo #2</h2>
          
          <TwitterTweetEmbed tweetId={'1490919974063333376'} options={{ theme: 'dark' }}/>
          
        </div>
      </div>


  	  
	</div>
  );
}

/*
Can set placeholder while tweet is loading


story
	
twitter follow button
	https://developer.twitter.com/en/docs/twitter-for-websites/follow-button/overview
Tweet button
	https://developer.twitter.com/en/docs/twitter-for-websites/tweet-button/overview

Can put the lambocorp feed on here
	https://developer.twitter.com/en/docs/twitter-for-websites

link to newly released at least?

mpx legends, just for fun / promotional

BIG collage of the ones minis 

options={{'theme': 'dark'}} data-theme={'dark'} style={{"data-theme": "dark"}} theme={'dark'}



Video embed was not working to change light/dark theme
<div className="mechapunkx-about padding-page text-page">
  	MECHA ABOUT1
	<div> 
    	<TwitterVideoEmbed onLoad={function noRefCheck(){}}
  options={{
    theme: 'dark'
  }}
  id={'1490919974063333376'} />



EXAMPLES
https://saurabhnemade.github.io/react-twitter-embed/?path=/story/twitter-hashtag-button--hashtag-button

https://www.npmjs.com/package/react-twitter-embed

TIMELINE
<TwitterTimelineEmbed
  sourceType="profile"
  screenName="saurabhnemade"
  options={{height: 400}}
/>

TWEET
<TwitterTweetEmbed
  tweetId={'933354946111705097'}
/>

SHARE
<TwitterShareButton
    url={'https://facebook.com/saurabhnemade'}
    options={{ text: '#reactjs is awesome', via: 'saurabhnemade' }}
  />

  MENTION
  <TwitterMentionButton
    screenName={'saurabhnemade'}
  />

  FOLLOW
  <TwitterFollowButton
    screenName={'saurabhnemade'}
  />

  VIDEO
  <TwitterVideoEmbed
    id={'560070183650213889'}
  />

To add tweet, start here:
https://developer.twitter.com/en/docs/twitter-for-websites/embedded-tweets/guides/embedded-tweet-javascript-factory-function
https://twitter.com/i/status/1490919974063333376


*/