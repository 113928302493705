import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';
import { Fade } from '@mui/material';
import MechDistributionTable from './mechDistributionTable';
import Image from 'material-ui-image'
import sample_5 from '../assets/mechapunkx/output_1492_small.png';
import sample_6 from '../assets/mechapunkx/output_7722.png';
import sample_7 from '../assets/mechapunkx/output_8188_small.png';
import sample_8 from '../assets/mechapunkx/output_920_small.png';
import sample_9 from '../assets/mechapunkx/output_259_proc_small.png';
import composite1 from '../assets/mechapunkx/composite1.jpg';
import composite2 from '../assets/mechapunkx/composite2.jpg';
import arrowRight from '../assets/arrowRight.png';
import arrowDown from '../assets/arrowDown.png';

import { AnimationOnScroll } from 'react-animation-on-scroll';
import "animate.css/animate.min.css";


import UncleTommy from '../assets/uncleTommy.png'
import Juicy from '../assets/juicyTransparentDialogue.png'

export default function MechaTokens() {
	return (
		<div className="mechapunkx-tokens text-page">
			<div className="mechapunkx-about-text">
					<div className="mechapunkx-tokens-left">
					<Fade in timing={800}>
						<div className="mechapunkx-home-title noselect" style={{"color": "#000eff", "text-align": "center", "padding-top": "4vw", "padding-bottom": "2vw"}}>
							<span style={{"color": "#e81a00"}}>TOKEN </span> INFO
						</div>
						</Fade>

						<div style={{"width": "100%", "margin-bottom": "8vw", 
							"display": "flex", "flex-direction": "column", "justify-content": "center", "align-items": "center"}}>

							{/*<div className="home-row-collapse background-gray" style={{
								"display": "flex", "flex-direction": "row", "padding-bottom": "5vw",
								"align-items": "center", "justify-content": "space-evenly", "padding-left": "2vw", "padding-right": "2vw",
								"border-top": "4px solid #2e2e2e", "border-bottom": "4px solid #333333"}}>
								<div style={{"display": "flex", "flex-direction": "column", "justify-content": "center", 
									"align-items": "center", "padding-left": "2vw", "padding-right": "2vw"}}>
									<div style={{"display": "flex", 
										"flex-direction": "row", "align-items": "center", "justify-content": "center", "padding-top": "7vw", "padding-bottom": "2vw"}}>
										<Image 
											src={sample_5}
											style={{"width": "300px", "background-color": "transparent"}}
											imageStyle={{"height": "auto", borderRadius: "0.75rem 0.75rem 0.75rem 0.75rem", "border": "6px solid #585858"}}
											disableSpinner={true}
											animationDuration={2000}
											aspectRatio={"1/1"}
										/>
									</div>
									<div className="home-text-nametag home-text-border-thick" style={{"background-color": "rgb(78,78,76)", 
										"font-family": "Kanit", "color": "white", "padding-top": "0.5vw", 
										"padding-bottom": "0.5vw", "padding-left": "1.5vw", "padding-right": "1.5vw",  
										"border-radius": "8px", "margin-top": "1vw"}}>
										MechaPunkx NFT
									</div>
								</div>
								
								<div className="mobile-full-width" style={{"display": "flex", "justify-content": "center", "position": "relative",  
									"align-items": "center", "border-radius": "8px", 
									"margin-top": "2vw", "padding-right": "2vw", "padding-left": "2vw", "max-width": "52%"}}>
									<div style={{"display": "flex", "flex-direction": "column", "justify-content": "center", "align-items": "center"}}>
									 
											<div style={{"display": "flex", "flex-direction": "row", "justify-content": "center", "align-items": "center"}}>
												<div style={{"display": "flex", "flex-direction": "column"}}>
												<div className="home-text-bubble home-text-border-thick" style={{"align-self": "flex-start"}}>
													Hi again, it's Uncle Tommy here to help explain.
												</div>
													<div className="home-text-bubble home-text-border-thick" style={{"margin-top": "2vw", "align-self": "flex-end", "width": "fit-content"}}>
												This, is a MechaPunkx.
											</div>
												</div>
												<div style={{"display": "flex", "flex-direction": "row", 
													"align-self": "flex-start", "width": "20%", "margin-left": "2vw", "padding-top": "1vw"}}>
													<img src={UncleTommy} width="100%" style={{"background-color": "white", "border-radius": "6px"}}/>                 
												</div>
											</div>
										
											
									</div>
								</div>
							</div>*/}




							{/*<div className="home-row-collapse background-gray" style={{
								"display": "flex", "flex-direction": "row", "padding-bottom": "5vw",
								"align-items": "center", "justify-content": "space-evenly", "padding-left": "2vw", "padding-right": "2vw",
								"border-top": "4px solid #2e2e2e", "border-bottom": "4px solid #333333"}}>
								<div className="mobile-full-width" style={{"display": "flex", "justify-content": "center", "position": "relative",  
									"align-items": "center", "border-radius": "8px", 
									"margin-top": "2vw", "padding-right": "2vw", "padding-left": "2vw", "max-width": "52%"}}>
									<div style={{"display": "flex", "flex-direction": "column", "justify-content": "center", "align-items": "center"}}>
										<div style={{"display": "flex", "flex-direction": "row", "justify-content": "center", "align-items": "center"}}>
											<div style={{"display": "flex", "flex-direction": "column"}}>
												<div className="home-text-bubble home-text-border-thick" style={{"align-self": "flex-start"}}>
													You can see 400 on launch day, then I'll release 7,418 more. Here's how! 
													
												</div>
											</div>
											<div style={{"display": "flex", "flex-direction": "row", 
												"align-self": "flex-start", "width": "20%", "margin-left": "2vw", "padding-top": "1vw"}}>
												<img src={UncleTommy} width="100%" style={{"background-color": "white", "border-radius": "6px"}}/>                 
											</div>
										</div>

										<div style={{"display": "flex", "flex-direction": "row", 
											"justify-content": "center", "align-items": "center", "margin-top": "2vw"}}>
											<div style={{"display": "flex", "flex-direction": "column"}}>
												<div className="home-text-bubble home-text-border-thick" style={{"align-self": "flex-start"}}>
													Each MechaPunkx earns 1 MECH Token (ERC20) per day.
												</div>
											</div>
											<div style={{"display": "flex", "flex-direction": "row", 
												"align-self": "flex-start", "width": "20%", "margin-left": "2vw", "padding-top": "1vw"}}>
												<img src={UncleTommy} width="100%" style={{"background-color": "white", "border-radius": "6px", "opacity": 0}}/>                 
											</div>
										</div>
									</div>
								</div>
							</div>*/}

							<table style={{"width": "80%"}}>
								<tbody>
									<tr>
										<td>
										</td>
										<td style={{"padding-top": "2vw", "display": "flex", "flex-direction": "row", "justify-content": "right"}}>
											<AnimationOnScroll animateIn="animate__fadeInDown" animateOnce={true} delay={800}>
												<div className="home-text-bubble home-text-border-thick" style={{"width": "fit-content"}}>
													Hey again, it's Uncle Tommy here to help explain.
												</div>
											</AnimationOnScroll>
										</td>
										<td style={{"vertical-align": "top", "padding-top": "2.5vw"}}>
											<div style={{"display": "flex", "flex-direction": "row", 
												"align-self": "flex-start", "margin-left": "2vw", "width": "6vw"}}>
												<img src={UncleTommy} style={{"background-color": "white", "border-radius": "6px", "max-width": "100%"}}/>                 
											</div>
										</td>
									</tr>
									<tr>
										<td>
										</td>
										<td style={{"display": "flex", "flex-direction": "row", "justify-content": "right", "padding-top": "2vw"}}>
											<AnimationOnScroll animateIn="animate__fadeInDown" animateOnce={true} delay={1000}>
												<div className="home-text-bubble home-text-border-thick" style={{
													"align-self": "flex-start", "width": "fit-content"}}>
													These, are MechaPunkx.
												</div>
											</AnimationOnScroll>
										</td>
										<td>
										</td>
									</tr>


									<tr>
										<td></td>
										<td colspan={1} style={{"text-align": "center", "padding-top": "4vw", "padding-bottom": "2vw"}}>
											{/*<Image 
											src={sample_5}
											style={{"width": "300px", "background-color": "transparent"}}
											imageStyle={{"height": "auto", borderRadius: "0.75rem 0.75rem 0.75rem 0.75rem", "border": "6px solid #585858"}}
											disableSpinner={true}
											animationDuration={2000}
											aspectRatio={"1/1"}
											/>*/}
											{/**/}
											<div style={{"display": "flex", "flex-direction": "row", "justify-content": "space-between", "align-items": "center"}}>
												<AnimationOnScroll animateIn="animate__fadeInDown" animateOnce={true} delay={1000}>
													<div style={{"display": "flex", "flex-direction": "row", "width": "100%"}}>
														<img src={sample_8} width="100%" style={{"background-color": "white", "border-radius": "6px", "border": "6px solid #585858"}}/>
													</div>
												</AnimationOnScroll>
												<AnimationOnScroll animateIn="animate__fadeInDown" animateOnce={true} delay={1200}>
													<div style={{"display": "flex", "flex-direction": "row", "width": "100%"}}>
														<img src={sample_7} width="100%"style={{"background-color": "white", "border-radius": "6px", "border": "6px solid #585858"}}/>
													</div>
												</AnimationOnScroll>
												<AnimationOnScroll animateIn="animate__fadeInDown" animateOnce={true} delay={1350}>
													<div style={{"display": "flex", "flex-direction": "row", "width": "100%"}}>
														<img src={sample_5} width="100%"style={{"background-color": "white", "border-radius": "6px", "border": "6px solid #585858"}}/>
													</div>
												</AnimationOnScroll>
												<AnimationOnScroll animateIn="animate__fadeInDown" animateOnce={true} delay={1470}>
													<div style={{"display": "flex", "flex-direction": "row", "width": "100%"}}>
														<img src={sample_9} width="100%"style={{"background-color": "white", "border-radius": "6px", "border": "6px solid #585858"}}/>
													</div>
												</AnimationOnScroll>
											</div>
										</td>
										<td></td>
									</tr>


									<tr>
										<td>
										</td>
										<td style={{"padding-top": "2vw"}}>
												<div className="home-text-bubble home-text-border-thick" style={{
													"align-self": "flex-start", "width": "fit-content"}}>
													You can see 400 on launch day, then I'll release 7,418 more.
												</div>
										</td>
										<td style={{"vertical-align": "top", "padding-top": "2vw"}}>
											<div style={{"display": "flex", "flex-direction": "row", 
												"align-self": "flex-start", "margin-left": "2vw", "width": "6vw"}}>
												<img src={UncleTommy} style={{"background-color": "white", "border-radius": "6px", "margin-top": "1vw", "max-width": "100%"}}/>                 
											</div>
										</td>
									</tr>
									

									<tr>
										<td style={{"vertical-align": "top", "padding-top": "4vw"}}>
											<div style={{"display": "flex", "flex-direction": "row", "justify-content": "right", 
												"align-self": "flex-end", "padding-right": "2vw", "margin-top": "1vw"}}>
												<img className="swinging" src={Juicy} width="100px" style={{"border-radius": "6px"}}/>                 
											</div>
										</td>
										<td style={{"padding-top": "4vw"}}>
												<div className="home-text-bubble home-text-border-thick" style={{"align-self": "flex-start", "width": "fit-content"}}>
													How are they released?
												</div>
										</td>
										<td style={{"padding-top": "2vw"}}>
										</td>
									</tr>

									<tr>
										<td>
										</td>
										<td style={{"padding-top": "4vw", "display": "flex", "flex-direction": "row", "justify-content": "right"}}>
												<div className="home-text-bubble home-text-border-thick" style={{"align-self": "flex-start", "width": "fit-content"}}>
													Oh hey Juicy, didn't see you standing there!
												</div>
										</td>
										<td style={{"vertical-align": "top", "padding-top": "4.5vw"}}>
											<div style={{"display": "flex", "flex-direction": "row", 
												"align-self": "flex-start", "margin-left": "2vw", "width": "6vw"}}>
												<img src={UncleTommy} style={{"background-color": "white", "border-radius": "6px", "max-width": "100%"}}/>                 
											</div>
										</td>
									</tr>

									<tr>
										<td>
										</td>
										<td style={{"display": "flex", "flex-direction": "row", "justify-content": "right", "padding-top": "2vw"}}>
												<div className="home-text-bubble home-text-border-thick" style={{"align-self": "flex-start", "width": "fit-content"}}>
													For each MechaPunkx you own, you earn 1 MECH Token (ERC20) daily.
												</div>
										</td>
										<td>
										</td>
									</tr>
									<tr>
										<td style={{"vertical-align": "top", "padding-top": "4vw"}}>
											<div style={{"display": "flex", "flex-direction": "row", "justify-content": "right", 
												"align-self": "flex-end", "padding-right": "2vw"}}>
												<img src={Juicy} width="100px" style={{"border-radius": "6px"}}/>                 
											</div>
										</td>
										<td style={{"padding-top": "4vw"}}>
												<div className="home-text-bubble home-text-border-thick" style={{"align-self": "flex-start", "width": "fit-content"}}>
													What can I do with MECH tokens?
												</div>
										</td>
										<td style={{"padding-top": "2vw"}}>
										</td>
									</tr>


									<tr>
										<td>
										</td>
										<td style={{"padding-top": "4vw", "display": "flex", "flex-direction": "row", "justify-content": "right"}}>
												<div className="home-text-bubble home-text-border-thick" style={{"align-self": "flex-start", "width": "fit-content"}}>
													You can use MECH tokens to mint new MechaPunkx!
												</div>
										</td>
										<td style={{"vertical-align": "top", "padding-top": "4.5vw"}}>
											<div style={{"display": "flex", "flex-direction": "row", 
												"align-self": "flex-start", "margin-left": "2vw", "width": "6vw"}}>
												<img src={UncleTommy} style={{"background-color": "white", "border-radius": "6px", "max-width": "100%"}}/>                 
											</div>
										</td>
									</tr>
									{/*<tr>
										<td>
										</td>
										<td style={{"display": "flex", "flex-direction": "row", "justify-content": "right", "padding-top": "2vw"}}>
											<div className="home-text-bubble home-text-border-thick" style={{"align-self": "flex-start", "width": "fit-content"}}>
												
											</div>
										</td>
										<td>
										</td>
									</tr>*/}

									<tr>
										<td style={{"vertical-align": "top", "padding-top": "4vw"}}>
											<div style={{"display": "flex", "flex-direction": "row", "justify-content": "right", 
												"align-self": "flex-end", "padding-right": "2vw", "margin-top": "1vw"}}>
												<img src={Juicy} width="100px" style={{"border-radius": "6px"}}/>                 
											</div>
										</td>
										<td style={{"padding-top": "4vw"}}>
												<div className="home-text-bubble home-text-border-thick" style={{"align-self": "flex-start", "width": "fit-content"}}>
													Ok, so I just hold, earn, and mint!
												</div>
										</td>
										<td style={{"padding-top": "2vw"}}>
										</td>
									</tr>
									<tr>
										<td style={{"vertical-align": "top", "padding-top": "2vw"}}>
											<div style={{"display": "flex", "flex-direction": "row", "justify-content": "right", 
												"align-self": "flex-end", "padding-right": "2vw", "margin-top": "1vw"}}>
												{/*<img src={Juicy} width="100px" style={{"background-color": "white", "border-radius": "6px"}}/>                 */}
											</div>
										</td>
										<td style={{"padding-top": "2vw"}}>
												<div className="home-text-bubble home-text-border-thick" style={{"align-self": "flex-start", "width": "fit-content"}}>
													And if I have whitelist or buy in the pre-sale on June 20th, I get more for free?
												</div>
										</td>
										<td style={{"padding-top": "2vw"}}>
										</td>
									</tr>

									<tr>
										<td>
										</td>
										<td style={{"padding-top": "4vw", "display": "flex", "flex-direction": "row", "justify-content": "right"}}>
												<div className="home-text-bubble home-text-border-thick" style={{"align-self": "flex-start", "width": "fit-content"}}>
													That's right!
												</div>
										</td>
										<td style={{"vertical-align": "top", "padding-top": "4.5vw"}}>
											<div style={{"display": "flex", "flex-direction": "row", 
												"align-self": "flex-start", "margin-left": "2vw", "width": "6vw"}}>
												<img src={UncleTommy} style={{"background-color": "white", "border-radius": "6px", "max-width": "100%"}}/>                 
											</div>
										</td>
									</tr>

									<tr>
										<td colspan={3} width="100%" style={{"padding-top": "6vw"}}>
											<div style={{"display": "flex", "flex-direction": "row", "width": "100%"}}>
												<img src={composite1} width="50%" style={{"opacity": "0.15"}}/>
												<img src={composite2} width="50%" style={{"opacity": "0.15"}}/>
											</div>

										</td>
									</tr>







									<tr>
										<td style={{"vertical-align": "top", "padding-top": "6vw"}}>
											<div style={{"display": "flex", "flex-direction": "row", "justify-content": "right", 
												"align-self": "flex-end", "padding-right": "2vw", "margin-top": "1vw"}}>
												<img src={Juicy} width="100px" style={{"border-radius": "6px"}}/>                 
											</div>
										</td>
										<td style={{"padding-top": "6vw"}}>
												<div className="home-text-bubble home-text-border-thick" style={{"align-self": "flex-start", "width": "fit-content"}}>
													What about LAMBO? 
												</div>
										</td>
										<td style={{"padding-top": "6vw"}}>
										</td>
									</tr>

									<tr>
										<td>
										</td>
										<td style={{"padding-top": "4vw", "display": "flex", "flex-direction": "row", "justify-content": "right"}}>
												<div className="home-text-bubble home-text-border-thick" style={{"align-self": "flex-start", "width": "fit-content"}}>
													For 50 MECH, convert your MechaPunkx and it will generate LAMBO daily.
													{/*For 50 MECH, convert your MechaPunkx to start generating 1 LAMBO daily.*/}
												</div>
										</td>
										<td style={{"vertical-align": "top", "padding-top": "4.5vw"}}>
											<div style={{"display": "flex", "flex-direction": "row", 
												"align-self": "flex-start", "margin-left": "2vw", "width": "6vw"}}>
												<img src={UncleTommy} style={{"background-color": "white", "border-radius": "6px", "max-width": "100%"}}/>                 
											</div>
										</td>
									</tr>

									<tr>
										<td style={{"vertical-align": "top", "padding-top": "4vw"}}>
											<div style={{"display": "flex", "flex-direction": "row", "justify-content": "right", 
												"align-self": "flex-end", "padding-right": "2vw", "margin-top": "1vw"}}>
												<img src={Juicy} width="100px" style={{"border-radius": "6px"}}/>                 
											</div>
										</td>
										<td style={{"padding-top": "4vw"}}>
												<div className="home-text-bubble home-text-border-thick" style={{"align-self": "flex-start", "width": "fit-content"}}>
													Forever? 
												</div>
										</td>
										<td style={{"padding-top": "4vw"}}>
										</td>
									</tr>
									
									<tr>
										<td>
										</td>
										<td style={{"padding-top": "4vw", "display": "flex", "flex-direction": "row", "justify-content": "right"}}>
												<div className="home-text-bubble home-text-border-thick" style={{"align-self": "flex-start", "width": "fit-content"}}>
													That's right Juicy, it's forever! 
												</div>
										</td>
										<td style={{"vertical-align": "top", "padding-top": "4.5vw"}}>
											<div style={{"display": "flex", "flex-direction": "row", 
												"align-self": "flex-start", "margin-left": "2vw", "width": "6vw"}}>
												<img src={UncleTommy} style={{"background-color": "white", "border-radius": "6px", "max-width": "100%"}}/>                 
											</div>
										</td>
									</tr>

									<tr>
										<td style={{"vertical-align": "top", "padding-top": "4vw"}}>
											<div style={{"display": "flex", "flex-direction": "row", "justify-content": "right", 
												"align-self": "flex-end", "padding-right": "2vw", "margin-top": "1vw"}}>
												<img className="excited-juicy" src={Juicy} width="100px" style={{"border-radius": "6px"}}/>                 
											</div>
										</td>
										<td style={{"padding-top": "4vw"}}>
											<AnimationOnScroll animateIn="animate__zoomInUp" animateOnce={true} delay={500} duration={4}>
												<AnimationOnScroll animateIn="animate__shakeY" animateOnce={true} delay={2500} duration={1.5}>
												<div className="home-text-bubble home-text-border-thick" style={{"align-self": "flex-start", "width": "fit-content"}}>
													Yaaaaaaaaaaaaaaaaaaaaa!!
												</div>
												</AnimationOnScroll>
											</AnimationOnScroll>
										</td>
										<td style={{"padding-top": "2vw"}}>
										</td>
									</tr>

								</tbody>
							</table>

									


							{/*<img src={mechaTitle} width={"100%"} style={{}}/>*/}
							<div className="mechapunkx-home-title noselect" style={{"color": "#000eff", "text-align": "center", "margin-top": "8vw"}}>
								<span style={{"color": "#e81a00"}}>SUMMARY </span>
							</div>

							<div className="tokens-text-summary" style={{"width": "100%", "margin-top": "2vw", "margin-bottom": "2vw"}}>
								{/*, "table-layout": "fixed"*/}
								{/*"max-width": "100%", */}
								<table style={{"width": "100%", "table-layout": "fixed"}}>
									<tbody>
										<tr>
											<td style={{}}>
												<div style={{"display": "flex", "flex-direction": "row", "justify-content": "center"}}>
												<img src={sample_6} width="68%" style={{"border-radius": "10px", "border": "6px solid #585858"}}/>
												</div>
											</td>
											{/*<td style={{"width": "5%"}}>
												<span style={{"border": "4px solid #585858", "padding": "8px", "border-radius": "8px", "color": "#585858"}}>=></span>
											</td>*/}
											<td style={{"vertical-align": "middle"}}>
												<div>
													<span style={{"padding-right": "5vw", "color": "#585858"}}><img src={arrowRight} height="25px"/></span>
													Earns 1 MECH / day
												</div>
												<div style={{"padding-top": "1.5vw"}}>
													<span style={{"padding-right": "5vw", "opacity": 0}}><img src={arrowRight} height="25px"/></span>
													Mint new MechaPunkx using MECH
												</div>
												{/*<ul style={{"list-style-type": "none"}}>
													<li>Earns 1 MECH / day</li>
													<li style={{"padding-top": "1.5vw"}}>Mint new MechaPunkx using MECH</li>
												</ul>*/}
											</td>
										</tr>
										<tr>
											<td style={{"text-align": "center", "font-weight": "bold", "color": "#585858"}}>
												{/*style={{"border": "8px solid #585858", "padding": "1vw", "border-radius": "8px"}}*/}
												<img src={arrowDown} height="25px" style={{"padding-top": "4vw"}}/>
											</td>
											<td>
											</td>
											
										</tr>
										<tr>
											<td style={{"text-align": "right", "padding-right": "5vw", "vertical-align": "top", "padding-top": "2vw"}}>
												{/*<span style={{"border": "4px solid white", "border-radius": "8px", "padding": "0.5vw"}}>*/}
													Convert
												{/*</span>*/}
												<span style={{"padding-left": "1vw"}}> (Costs 50 Mech)
												</span>
											</td>
											{/*<td style={{"width": "5%", "vertical-align": "top"}}>
												<span style={{"border": "4px solid #585858", "padding": "8px", "border-radius": "8px", "color": "#585858"}}>=></span>
											</td>*/}
											<td style={{"vertical-align": "top", "display": "table-cell", "padding-top": "2vw", "white-space": "nowrap"}}>
												<div>
													<span style={{"padding-right": "5vw", "color": "#585858"}}><img src={arrowRight} height="25px"/></span>
													Earns LAMBO daily <span style={{"color": "#585858"}}>&nbsp;(instead of MECH)</span>
												</div>
												<div style={{"padding-top": "1.5vw"}}>
													<span style={{"padding-right": "5vw", "opacity": 0}}><img src={arrowRight} height="25px"/></span>
													Used in LamboCorp World game
													<div><span style={{"padding-right": "5vw", "opacity": 0}}><img src={arrowRight} height="25px"/></span>
													and across LamboCorp
													</div>
												</div>
												
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>

						{/*Useable across LamboCorp*/}

						<div style={{"display": "flex", "flex-direction": "row", 
							"justify-content": "center", "padding-left": "4vw", "padding-right": "4vw", "margin-bottom": "8vw"}}>
							<ul className="tokens-text-summary-bottom">
							    <li style={{"padding-bottom": "2vw"}}>MechaPunkx yield MECH to distribute themselves as a collection, then their utility is to perpetually yield LAMBO.</li>
							    <li style={{"padding-bottom": "2vw"}}>LAMBO is used across LamboCorp, in the LamboCorp World game, and also for future free mints (available only priced in LAMBO).</li>
							    <li style={{"padding-bottom": "2vw"}}>LamboCorp's goal is to build on LAMBO.</li>
							    <li style={{"padding-bottom": "2vw", "list-style-type": "none", "padding-left": "4vw"}}>
							    	Increase the utility and "velocity of money" of LAMBO, and burn as much LAMBO as possible.</li>
							    <li style={{"padding-bottom": "2vw"}}>LamboDAO earns 30% of all LAMBO burned (the other 70% is actually burned).</li>
							    <li style={{"padding-bottom": "2vw", "list-style-type": "none", "padding-left": "4vw"}}>
							    	The DAO can use LAMBO to buy Treasure NFTs to give as in-game rewards, buy MAGIC and stake in The Flywheel, or any other activity that benefits the DAO and the players of LamboCorp World.</li>

							    {/***
								The amount of LAMBO yielded from each MechaPunkx changes monthly based on the amount of LAMBO being burned (auto managing inflation).*/}
							</ul>
						</div>

						<div>
						<div className="frog-background" style={{"position": "relative"}}>
						<div style={{"padding-left": "6vw", "padding-right": "6vw"}}>
						
						

						<div className="noselect mechapunkx-tokens-title" 
							style={{"padding-top": "2vw", "padding-bottom": "2vw", 
							"font-size": "3.4vw"}}><span style={{"color": "red"}}>MECHA</span><span style={{"color": "blue"}}>PUNKX</span></div>
						
						<p>
						Suit up ya freak bitch. 

						MechaPunkx, created by <span style={{"color": "yellow"}}>Uncle Tommy</span>, features a new launch style for NFT collections. 
						For the initial mint, there will be 200 MechaPunkx for the whitelist, 200 pre-sale, and
						30 for dev / marketing (<span style={{"color": "yellow"}}> LamboCorp</span>). The remaining 7,418 MechaPunkx will be given away for free over 6 months, and are only claimable using the MECH token.
						</p>



						<div className="noselect mechapunkx-tokens-title" 
							style={{"padding-bottom": "2vw", "padding-top": "3vw"
						}}><span style={{"color": "red"}}>MECH</span> Token</div>
							{/*<img src={} style={{"position": "absolute"}}/>*/}
						<p>
							The MECH token (ERC20) enables the distribution of the MechaPunkx NFT collection. 
							<br></br>
							<br></br>
							<ul>
								<li>Each MechaPunkx NFT earns <span style={{"color": "red"}}>1 MECH token / day</span></li>
								<br></br>
								<li>With 10 MECH tokens, go claim a new MechaPunkx NFT</li>
								<br></br>
								<li>Only available to MechaPunkx NFT holders</li>
								<br></br>
								<li>100% fair distribution</li>
								<br></br>
								<li>At most, only 100 MechaPunkx can be claimed per day</li>
								<br></br>
								<li>Cost to claim starts at 10 MECH tokens, gradually reaching max. of 100 MECH tokens</li>
								<br></br>
							</ul>
						</p>
						{/*<p className="mechapunkx-tokens-title">
							Each MechaPunkx NFT earns <span style={{"color": "red"}}>1 MECH token / day</span>.
						</p>
						<p className="mechapunkx-tokens-title">
							With 10 MECH tokens, go claim a new MechaPunkx NFT. 
						</p>*/}
						<p style={{"text-indent": "2vw"}}>
							There may be a bit of competition some days, but if successfully claimed, you get a new NFT, earn MECH token at an increased rate, and the 10 MECH is burned. So claim as soon as possible, that way you can maximize the rate of earning MECH token and the number of MechaPunkx you receive. 
							MECH token is an ERC20, and will likely also be tradeable on a DEX such as Sushiswap. 
							In that case, MECH does not have to be spent on MechaPunkx, but can also be traded for other ERC20's.
						</p>
						{/*<p>
							The claim process will continue until the entire collection of 7,848 has been claimed over about 6 months.
						</p>*/}




						
						<div className="noselect mechapunkx-tokens-title" 
							style={{"padding-bottom": "2vw", "padding-top": "3vw", "color": ""}}>Why distribute this way?</div>
						<p>
						The collection was designed this way to:
						
						<br></br>
						<br></br>
						</p>
						<div style={{"padding-left": "2vw"}}>
						<table >
							<tbody style={{"vertical-align": "top"}}>
								<tr>
									<td>1.</td>
									<td>Reward WL and pre-sale participants</td>
								</tr>
								<tr style={{"height": "5px"}}></tr>
								<tr>
									<td></td>
									<td className="mechapunkx-tokens-subtext">WL (free mint) and pre-sale can collect significantly more MechaPunkx for free</td>
								</tr>
								<tr style={{"height": "10px"}}></tr>
								<tr>
									<td>2.</td>
									<td>Create a fun experience with daily releases of new punks</td>
								</tr>
								<tr style={{"height": "5px"}}></tr>
								<tr>
									<td></td>
									<td className="mechapunkx-tokens-subtext">Encourage rarity hunting, building hype, and growing community over time</td>
								</tr>
								<tr style={{"height": "10px"}}></tr>
								<tr>
									<td>3.</td>
									<td>Constrict the supply to raise the value</td>
								</tr>
								<tr style={{"height": "5px"}}></tr>
								<tr>
									<td></td>
									<td className="mechapunkx-tokens-subtext">Each NFT you hold increases your rate of earning more MechaPunkx, so holding is incentivized</td>
								</tr>
								<tr style={{"height": "10px"}}></tr>
								<tr>
									<td>4.</td>
									<td>Resistant to exploit of pump & dump life cycle</td>
								</tr>
								<tr style={{"height": "5px"}}></tr>
								<tr>
									<td></td>
									<td className="mechapunkx-tokens-subtext">The collection continues to grow in size, community, and value over many months</td>
								</tr>
							</tbody>
						</table>
						</div>
						
						<br></br>

						<div className="noselect mechapunkx-tokens-title" 
							style={{"padding-bottom": "2vw", "padding-top": "3vw"}}>Will it be fun?</div>
						<p style={{"text-indent": "2vw"}}>
						{/*, "line-height": "1.5em"*/}
						{/*Trading and rarity hunting with each day's new drops should be fun, as done by most collections.
						aside from allowing daily rarity hunting and trading. The
						*/}
						The gamified distribution creates several incentives - primarily, to hold and collect additional MechaPunkx. 
						If everyone holds, then the value of the NFTs increases indefinitely. 
						At a high enough value, some holders will sell, but many may have a reinforced conviction to hold even longer, getting the full value of their yet-to-be-claimed, future MechaPunkx. 
						</p>
						<p style={{"text-indent": "2vw"}}>
						{/*, "color": "gray"*/}
						This behavior creates a feedback loop where if the floor price increases, the expected value of future claims increases. 
						More holders will hold for longer causing increased scarcity, feeding back into increasing floor prices. 
						This process will sustainably bootstrap LamboCorp's economy, creating a valuable base asset, compared to other projects which attempt to build value overnight and quickly crash.
						This feedback cycle can continue as long as there are MechaPunkx left to claim, but instead of the value collapsing as the end of claims approaches, the value of the NFTs may transition to be based on their perpetual yield of LAMBO, LamboCorp's primary token. 
						{/*holders 
						should be fun to strategize around.*/}
						</p>
						<p style={{"text-indent": "2vw", "padding-bottom": "2vw"}}>
						During the distribution period, the MECH token itself will be scarce, and some holders may want to sell their MECH tokens. 
						There should be high demand, since they can be used to claim MechaPunkx which yield more MECH or LAMBO.
						At different points during the distribution, many will choose to pursue LAMBO. They will burn 50 MECH tokens to begin permanently yielding LAMBO instead of MECH. 
						Every MechaPunkx permanently yielding LAMBO (that stops yielding MECH token) makes the MECH token more scarce by lowering its global inflation rate, benefitting all other MechaPunkx holders.
						</p>
						{/*prior to continued development*/}
					 {/* <p style={{"text-indent": "2vw"}}>
						It may seem unrealistic to project a feedback cycle of 
						Each MechaPunkx NFT that does this conversion reduces the inflation rate of MECH token, and increases its scarcity.
						</p>*/}
</div>
					</div>
						<div className="noselect mechapunkx-tokens-title" 
							style={{"padding-bottom": "2vw", "padding-top": "3vw", "padding-left": "6vw", "padding-right": "6vw"}}><span style={{"color": "yellow"}}>LAMBO</span> Token</div>

					{/*MechaPunkx is LAMBOCORP's primary NFT collection. 
As such, it has a unique long term utility that no other collection will; MechaPunkx can yield LAMBO.*/}
					<div style={{"padding-left": "6vw", "padding-right": "6vw"}}>
						<p>
							The LAMBO token (ERC20) provides utility within the LamboCorp World. 
						</p>
						<p>
							The LAMBO token's value also comes from the fact that all future LamboCorp NFT sales will only be available for mint priced in LAMBO. 
							In that way, holding a MechaPunkx allows for perpetual future free mints of LamboCorp releases, by yielding the base token of the LamboCorp metaverse. 
							The entire supply of LAMBO will be distributed through this mechanism, with 100% going to holders of MechaPunkx. 
							<br></br>
							<br></br>
							<ul>
								<li>Each MechaPunkx NFT yields MECH tokens</li>
								<br></br>
								<li>For the cost of 50 MECH tokens, a MechaPunkx NFT can switch the yield to earn <span style={{"color": "yellow"}}>1 LAMBO token / day</span></li>
								<br></br>
								<li>The switch is permanent - the MechaPunkx NFT will no longer yield MECH token (lowering MECH inflation).</li>
								<br></br>
								<li>MechaPunkx will be the only collection with the permanent utility of yielding LAMBO, and will produce the entire supply of LAMBO through their daily yield.</li>
								<br></br>
								<li>For one example of LAMBO's utility, it enables perpetual future free mints of LamboCorp releases. 
								This is because all future collections released will only be available for purchase with LAMBO, priced in LAMBO. 
								</li>
								<br></br>
							</ul>
								<br></br>
								{/*Billy's World*/}
								More details to come.
							<br></br>
							<br></br>
							<br></br>
							<br></br>

						</p>
						</div>
						
						<MechDistributionTable/>
						

					 

						<div style={{"display": "flex", "flex-direction": "row", "justify-content": "center", "align-items": "center", "margin-bottom": "2vw"}}>  
						</div>
						</div>
					</div>

				
			</div>
	</div>
	);
}

/*
 Hopefully this page has illustrated why holding the MechaPunkx NFT through the 6 month distribution, and beyond, is likely the most valuable strategy.
{<p>

{<div className="noselect mechapunkx-tokens-title" 
style={{"padding-bottom": "2vw", "padding-top": "3vw", "color": ""}}>What happens when all 7,418 MechaPunkx are claimed?</div>}

<p style={{"text-indent": "2vw"}}>
Once the MechaPunkx are distributed, the MECH token will have one remaining use case. 
For the cost of 50 MECH tokens, at any time following the launch, each MechaPunkx NFT can convert its daily yield to the ERC20 LAMBO token. 
The MechaPunkx NFTs will be the only collection to hold this permanent utility, and the entire supply of LAMBO will be produced through this daily yield. 
</p>
<p style={{"text-indent": "2vw"}}>
The LAMBO token does warrant further description (more details soon). For now, the key takeaways are that:
(1) MechaPunkx have utility beyond the distribution period. 
(2) Converting MechaPunkx yield to LAMBO acts as a MECH token sink, reducing the MECH inflation rate, eventually to 0/
(3) LAMBO is valuable within LamboCorp
Hopefully this page has illustrated why holding the MechaPunkx NFT through the 6 month distribution, and beyond, is likely the most valuable strategy.
</p>
}


MechaPunkx is LAMBOCORP's primary NFT collection. 
As such, it has a unique long term utility that no other collection will; MechaPunkx can yield LAMBO.
LAMBO's value comes from the fact that all future LAMBOCORP NFT sales will only be available for mint priced in LAMBO. 
Because LAMBO will only be distributed to MechaPunkx, holding a MechaPunkx allows for perpetual future free mints, by yielding the base token of the LamboCorp metaverse.

					<p>
						<span className="mechapunkx-home-subtitle" style={{"color": "blue"}}>LAUNCH</span>
						<img src={BirdIcon2} style={{"height": "3.5vw", "width": "3.5vw", "margin-left": "1vw"}}></img>
					</p>
					<br></br>
					<p>
					<span style={{"color": "white"}}>How do I get <span style={{"color": "red"}}>MECH</span> tokens?</span>
						<blockquote>
						Earn 1 MECH token / day for each MechaPunkx you own
						</blockquote>
					</p><br></br><p>
					<span style={{"color": "white"}}>How do I get <span style={{"color": "red"}}>Mecha</span><span style={{"color": "blue"}}>Punkx</span>?</span>
						<blockquote>
						(a) Buy in pre-sale or be on whitelist<br></br>
						(b) Buy on the secondary market on TROVE<br></br>
						(c) Claim with MECH tokens<br></br>
						(d) Beg your cousin Ricky to give you one
						</blockquote>
					</p><br></br><p>
					With 100% of the supply split between holders, the MECH token has a 100% fair distribution. 
					This <a href="https://lambo.lol/mechapunkx/infographic.png" target="_blank" style={{"color": "#00deff"}}>infographic </a> 
					has more details. It shows that holding just one MechaPunkx from the pre-sale, until the collection is complete, allows you to claim up to 30 MechaPunkx for free.
					Especially after the pre-sale, don't wait because the sooner you get yours, 
					the more MechaPunkx you will end up with as the 6 month distribution period continues. 
					</p><p>
					So accumulate MechaPunkx and MECH token, and you'll have a blast along the way. 
					
					Make sure to check for rare traits on your MechaPunkx every time you claim one. 
					Then decide to hold, trade, or whatever strategy you choose with your MechaPunkx and your MECH tokens. 
					Lastly, be generous, and share your newfound riches to help build the community, those are <span style={{"color": "yellow"}}>Uncle Tommy</span>'s orders!
					That said, what are you waiting for? Let's go!!!!

					<br></br><br></br>
					
					<p>
						<span className="mechapunkx-home-subtitle" style={{"color": "blue"}}>CLAIMING</span>
						<img src={BirdIcon12} style={{"height": "3.2vw", "width": "3.2vw", "margin-left": "1vw"}}></img>
					</p><p>
					Only a small amount of MechaPunkx will ever be sold by <span style={{"color": "yellow"}}>LamboCorp</span>. 
					However, over time, 7,418 will be distributed to collection holders via token claiming. 
					More details can be found on the <a href="https://lambo.lol/mechapunkx/inventory" style={{"color": "#2196f3"}}>Inventory</a> page and 
					in the infographic <a href="https://lambo.lol/mechapunkx/infographic.png" target="_blank" style={{"color": "#2196f3"}}>here</a>.
					</p><p>
					Each MechaPunkx NFT earns 1 MECH token per day. Once you've accumulated 10 MECH tokens, you can claim a new MechaPunkx. 
					The claim process will continue until the entire collection of 7,848 has been claimed over about 6 months. 
					The cost in MECH token required to claim will gradually increase over time, as dictated by the contract.
					</p>

					<br></br>
					<p>
						<span className="mechapunkx-home-subtitle" style={{"color": "blue"}}>UTILITY</span>
						<img src={BirdIcon13} style={{"height": "3.5vw", "width": "3.5vw", "margin-left": "1vw"}}></img>
							</p>
							<div style={{"padding-left": "1vw"}}>
						<ul>
							<li>Holding MechaPunkx distributes more MechaPunkx to the holder at a compounding rate until the collection is completely released.</li>
							<li className="mechapunkx-home-subtext" style={{"list-style-type": "none", "margin-top": "1vw", "margin-bottom": "1vw"}}>Holding MechaPunkx earns ERC20 MECH token, which allows claiming of MechaPunkx.</li>
							
							<li>Holding MechaPunkx earns (and is the only way to earn) ERC20 <span style={{"color": "yellow"}}>LAMBO</span>.</li>
							<li className="mechapunkx-home-subtext" style={{"list-style-type": "none", "margin-top": "1vw", "margin-bottom": "1vw"}}> 
								<p>
								MechaPunkx is LAMBOCORP's primary NFT collection. 
								As such, it has a unique long term utility that no other collection will; MechaPunkx can yield LAMBO.
								</p><p> 
								Eventually all 7,848 MechaPunkx will be claimed. By then the MECH token will have completed its purpose successfully. 
								However, after launch, you can spend 50 MECH to stop your MechaPunkx from yielding MECH token, and begin permanently yielding LAMBO daily.
								This will expenditure will constrict the supply of MECH, increase the value of MechaPunkx, and provide a significant reward of yielding LAMBO.
								</p><p>
								------{ (analogous to Treasure's $MAGIC token)}
								LAMBO's value comes from the fact that all future LAMBOCORP NFT sales will only be available for mint priced in LAMBO. 
								Because LAMBO will only be distributed to MechaPunkx, holding a MechaPunkx allows for perpetual future free mints, by yielding the base token of the LamboCorp metaverse.
								</p>
							</li>
							<li>Future Roadmap (Community Wallet, Billy's World)</li>
						</ul>
					</div>

					*/