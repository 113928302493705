/* Copies of functions from the contract */

function claimUpperBound(maxEarned) {
		
	let day = maxEarned;

	if (day > 141) {
		let wks = Math.floor((day - 141) / 7);
		return 1208 + (100 * wks);
	}

	let milestones = [...Array(17)].map(x=>Array(2).fill(0));
	milestones[0] = [141,1208];
	milestones[1] = [135,1106];
	milestones[2] = [128,994];
	milestones[3] = [122,904];
	milestones[4] = [114,806];
	milestones[5] = [107,702];
	milestones[6] = [98,594];
	milestones[7] = [89,495];
	milestones[8] = [80,405];
	milestones[9] = [71,324];
	milestones[10] = [62,252];
	milestones[11] = [53,189];
	milestones[12] = [44,135];
	milestones[13] = [35,90];
	milestones[14] = [26,54];
	milestones[15] = [17,27];
	milestones[16] = [8,9];

	for (let i = 0; i < milestones.length; i++) {
		if (day >= milestones[i][0]) {
			return milestones[i][1];
		}
	}

	return 0;
}

/* Returns the day of next MECH unlock day (interval step), and the max claimable at that time */
export function nextUnlockPeriod(maxEarned) {

	let result = claimUpperBound(maxEarned);

	// Find the next day where the upper bound is greater than the current
	for (let i = 1; i < 100; i++) {
		let fut = claimUpperBound(maxEarned + i);
		if (fut > result) {
			return [maxEarned + i, fut];
		}
	}

	console.log("Error calculating next unlock period: ", maxEarned);
	return [0, 0];
}

export function claimableBalance(maxEarned, amountClaimed) {
	let amountCanClaim = claimUpperBound(maxEarned);
	if (amountClaimed >= amountCanClaim) {
		return 0;
	}
	return amountCanClaim - amountClaimed;
}