import * as React from 'react';
import { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component'
import axios from 'axios';
import ReactDOM from 'react-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Fade } from '@mui/material';
import { AnimationOnScroll } from 'react-animation-on-scroll';
// import "../animate.css/animate.min.css";
import HolderRow from './topHolderRow';
import MPXMetadata from '../mechapunkx_metadata.json';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';

const themeSortButton = createTheme({
  status: {
	danger: '#ff0000',
  },
  palette: {
	primary: {
	  main: '#333638',
	  darker: '#004a99'
	},
	neutral: {
	  main: '#333638',
	  contrastText: '#fff'
	},
	action: {
      disabledBackground: '#242728',
       disabled: '#555b5e',
      // disabled: ''
    }
  },
});

const mapTokenToRarity = {};

// Extract map for tokenId => rarity rank
for (let tokenId in MPXMetadata) {
	let traits = MPXMetadata[tokenId];
	let r = -1;
	for (let i=traits.length-1; i>=0; i--) {
		let t = traits[i];
		if (t["trait_type"] === "Rank") {
			r = parseInt(t["value"])
			break;
		}
	}
	// console.log("Setting rarity: ", item.tokenId, r);
	if (r !== -1) {
		mapTokenToRarity[tokenId] = r;
	}
}

// let traits = [];
// if (props.metadata.hasOwnProperty(props.tokenId)) {
// 	traits = props.metadata[props.tokenId];
// }

// Better to do this outside one time instead of on-click
// Try extract rarity from traits	

export interface TopHoldersSectionProps {
	// sortByRarity: Boolean
}

export default function TopHoldersSection(props) {

	const lamboCorpLower = "0x9615f693C05258594ac9b0d85251ae4719395D77".toLowerCase();

	const [sortByRarity, setSortByRarity] = React.useState(false);
	const [sortRarityButtonDisabled, setSortRarityButtonDisabled] = React.useState(false);
	const [sortQuantityButtonDisabled, setSortQuantityButtonDisabled] = React.useState(true);
	
	// const [sortByRarity, setSortByRarity] = React.useState(true);
	// const [sortRarityButtonDisabled, setSortRarityButtonDisabled] = React.useState(true);
	// const [sortQuantityButtonDisabled, setSortQuantityButtonDisabled] = React.useState(false);

	function toggleSortByQuantity() {
		setSortQuantityButtonDisabled(true);
		setSortRarityButtonDisabled(false);
		if (sortByRarity) {
			setAccounts([]);
			setAccountData((x) => x.sort(hasMoreTokens));
			setSortByRarity(false);
		}
	}

	function toggleSortByRarity() {
		setSortRarityButtonDisabled(true);
		setSortQuantityButtonDisabled(false);
		if (!sortByRarity) {
			setAccounts([]);
			setAccountData((x) => x.sort(hasHigherMaxRarity));
			setSortByRarity(true);
		}
	}

	const [accountData, setAccountData] = React.useState([]);

	// Displayed on scroll, sorted
	const [accounts, setAccounts] = React.useState([]);
	const [loaded, setIsLoaded] = React.useState(false);

	function hasMoreAccounts() {
		return accounts.length < accountData.length;
	}

	// count = 9
	function fetchRow() {
			//const apiRoot = "https://kneecaps.io/images/"
			/*
			axios.get('http://localhost:5000/1246.png', {}).then (res => {
				setImages([...images, ...res.data]);
				setIsLoaded(true);
			});
			*/  
			let count = 7;
			
			// Load more if available
			if (hasMoreAccounts()) {
				// console.log("Loading more...");
				let fsPaths = [];
				let data = accountData;
				let end = Math.min(accounts.length + count, data.length);
				for (let i=accounts.length; i<end; i++) {
					// fsPaths.push('https://lambo.lol/mecha-sample/' + fs[i]);
					fsPaths.push(data[i]);
				}
				// console.log("Adding to displayed accounts: ", fsPaths);
				setAccounts(arr => [...accounts].concat(fsPaths));
				setIsLoaded(true);
			}
	}

	function hasMoreTokens(a,b) {
		let x = a.tokens.length;
		let y = b.tokens.length;
		if (x > y) return -1;
		else if (x < y) return 1;
		else return 0;
	}

	function hasHigherMaxRarity(a,b) {
		let x = a.rarest.rank;
		let y = b.rarest.rank;
		if (x > y) return 1;
		else if (x < y) return -1;
		else return 0;
	}

	// THIS WAS regular constant b4
	async function fetchAccountData() {
		
		console.log("Fetching account data.");
		
		const resp = await fetch('../ownersToTokens.json', {
		  method: 'GET',
		  headers: {
		    'Content-Type': 'application/json',
		    'Accept': 'application/json',
		  }
		})

		if (!resp.ok) {
			const message = `Error getting top holders: ${resp.status}`;
			console.error(message);
			return;
		}

		try {
			// console.log("Parsing account response: ", resp);

			const data = await resp.json();
			let ownerMap = data.ownerToTokens.filter((o) => o.address.toLowerCase() != lamboCorpLower);

			// console.log("Owner map: ", ownerMap);
			let ownerMapList = [];
			for (let i=0;i<ownerMap.length;i++) {
				let owner = ownerMap[i];
				// console.log("Iter one owner: ", owner);

				// Calculate the rarest token the owner holds
				let mostRare = {
					rank: 7848,
					tokenId: -1
				};
				let ranks = {};
				for (let j=0; j<owner.tokens.length; j++) {
					let t = owner.tokens[j];
					if (mapTokenToRarity.hasOwnProperty(t)) {
						let rarity = mapTokenToRarity[t];
						ranks[t] = rarity;
						if (rarity < mostRare.rank) {
							mostRare.rank = rarity;
							mostRare.tokenId = t;
						}
					}
					else {
						console.error("Did not find rank for token: ", t, "owner: ", owner.address);
						ranks[t] = 7848;
					}
				}

				function byTokenRank(a,b) {
					let x = ranks[a];
					let y = ranks[b];
					if (x > y) return 1;
					else if (x < y) return -1;
					else return 0;
				}

				ownerMapList.push({
					"address": owner.address,
					"ensName": owner.ensName,
					"smolName": owner.smolName,
					"tokens": owner.tokens.sort(byTokenRank), 
					"ranks": ranks, 
					"rarest": mostRare 
				});
			}

			// console.log("Ownerlist: ", ownerMapList);
			if (sortByRarity) setAccountData(ownerMapList.sort(hasHigherMaxRarity));
			else setAccountData(ownerMapList.sort(hasMoreTokens));
		}
		catch (error) {
			console.error("Error parsing top accounts response: ", error);
		}
	}

	React.useEffect(() => {
		fetchRow();
	}, []);

	React.useEffect(() => {
		fetchAccountData();
	}, []);

	

	return(


		<div id="mpx-db-holders" style={{
				"margin-top": "4vw", "padding": "4vw", "display": "flex", "flex-direction": "column"}}>
				<div style={{"display": "flex", "flex-direction": "row", "justify-content": "space-between"}}>
					<div>Top Holders</div>
					<div style={{"display": "flex", "flex-direction": "row", "align-items": "center"}}>
						<div className="mpx-db-text-small" style={{"padding-right": "2vw"}}>Sort by:</div>
						<ThemeProvider theme={themeSortButton}>
							<Button id="sortRarityButton"
								disableRipple
								disabled={sortRarityButtonDisabled}
								variant="contained" size={"large"} color={"primary"} 
								style={{"font-family": "inherit", "font-weight": "bolder", "font-size": "1vw", "line-height": "1vw",
								"border": "3px solid #242728", "border-radius": "4px"}}
								onClick={toggleSortByRarity}
								>Rarity</Button>
						</ThemeProvider>
						<ThemeProvider theme={themeSortButton}>
							<Button id="sortQuantityButton"
								disableRipple
								disabled={sortQuantityButtonDisabled}
								variant="contained" size={"large"} color={"primary"} 
								style={{"font-family": "inherit", "font-weight": "bolder", "font-size": "1vw", "line-height": "1vw",
								"border": "3px solid #242728", "border-radius": "4px", "margin-left": "1vw"}}
								onClick={toggleSortByQuantity}
								>Quantity</Button>
						</ThemeProvider>
					</div>
				</div>
				{/*<div className="mpx-db-text-medium">Coming soon!</div>*/}
				<div style={{"margin-top": "2vw"}}>
					


		<div style={{"margin-bottom": "4vw", "padding": "2vw", "background-color": "#1d1f21", "border-radius": "6px"}}>

			<InfiniteScroll
				// key={"ownerlist-" + props.sortByRarity.toString()}
				dataLength={accounts}
				next={() => {fetchRow();}}
				// next={() => { console.log("Called next... ")}}
				hasMore={hasMoreAccounts}
				style={{"overflow-y": "hidden"}}
				loader={
					hasMoreAccounts() ?
					<Box sx={{ display: 'flex'}}>
						<CircularProgress size={"2.5vw"}/>
					</Box> : ""
				}>
			 
				{/*delay={800}*/}
			 		<div> {
			 			loaded ? 
			 			accounts.map((a, idx) => 
			 				
			 				<AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true} duration={0.51}>
								<HolderRow 
									key={"top-holders-" + a.address} 
									address={a.address}
									ensName={a.ensName}
									smolName={a.smolName} 
									tokens={a.tokens} 
									ranks={a.ranks} 
									sortByRarity={sortByRarity} 
									holderRank={idx+1}
								/>
							</AnimationOnScroll>
						): ""
			 		}
					</div>
			</InfiniteScroll>

		</div>
		</div>
			</div>
	);
}

{/*
	<div className="image-grid" style={{ marginTop: "30px" }}>
		{loaded ? 
			images.map((image, index) => (
				<GalleryImage url={image} key={index} handleClickOpen={handleClickOpen} imageId={getImageIdFromURL(image)} metadata={getImageMetadataFromURL(image)}/>
			)): ""}
	</div>*/}




/*
<img
	 src={MonkeyIcon}
	 style={{borderRadius: "10px", backgroundColor: "transparent", marginTop: "40px", marginBottom: "30px"}}
	 height="110px"
	 width="110px"
	 borderRadius="8 px"
	 alt="loading"
/>
*/
