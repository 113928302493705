import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Web3 from 'web3';
import Image from 'material-ui-image'
import useSound from 'use-sound';
import { TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton} from 'react-twitter-embed';

import ConnectSound from '../sounds/a-move-select.wav';
import LaughSound from '../sounds/villain_laugh.mp3';

import Whitelist from '../whitelist_6_08.json';
// import SipSipBanner4 from '../assets/sipsipBanner4.jpg';
import SipSipBanner4 from '../assets/monkeyUpper.png';
import CursorIcon from '../assets/icons/cursor2.png'


const themeConnectButton = createTheme({
  status: {
	danger: '#ff0000',
  },
  palette: {
	primary: {
	  main: '#007bff',
	  darker: '#004a99'
	},
	neutral: {
	  main: '#007bff',
	  contrastText: '#fff'
	},
	action: {
      disabledBackground: '#003e80',
      // disabled: ''
    }
  },
});

export default function WLCheck() {

	let addresses = new Set(Whitelist.addresses);
	
	const { ethereum } = window;
	const [accounts, setAccounts] = React.useState([]);
	const [connectButtonText, setConnectButtonText] = React.useState("Connect");
	const [connectButtonDisabled, setConnectButtonDisabled] = React.useState(false);
	const [addressDisplay, setAddressDisplay] = React.useState("");
	const [addressDisplayStyle, setAddressDisplayStyle] = React.useState("none");
	const [resultText, setResultText] = React.useState("");

	const [playConnect] = useSound(ConnectSound, { volume: 0.2 });
	const [playLaugh] = useSound(LaughSound, { volume: 0.4, playbackRate: "0.9" });

	useEffect(() => {
		if (ethereum === undefined) {
			alert("Please install the MetaMask wallet extension from https://metamask.io");
		}
		else {
			init();

			ethereum.on('accountsChanged', handleAccountsChanged);
			ethereum.on('chainChanged', handleChainChanged);

			return () => {
				ethereum.removeListener('accountsChanged', handleAccountsChanged);
				ethereum.removeListener('chainChanged', handleChainChanged);
			};
		}
	}, []); // [] => Only run once

	const handleAccountsChanged = (accs) => {
		console.log("Account changed");
		updateAccount(accs);
	}

	// Display connected account
	async function updateAccount(accs) {
		console.log("Trying update accounts: ", accs === undefined, accs);
		if (accs !== undefined && accs.length > 0 && accs[0] !== undefined) {
			setConnectButtonText("Connected");
			setConnectButtonDisabled(true);
			setAddressDisplay(ethereum.selectedAddress);
			setAddressDisplayStyle("block");
			playConnect();

			let res = hasWhitelist(accs[0]);
			if (res) {
				setResultText("YES, SEE YOU ON JUNE 20th, FREE MINT!! HIT THE TWEET BUTTON ^^");
			}
			else {
				playLaugh();
				setResultText("Nope. Hang around our discord, more giveaways still going!");
			}
		}
		else {
			setAddressDisplay('No account.');
			setConnectButtonText("Connect");
			setConnectButtonDisabled(false);
			setAddressDisplayStyle("none");
		}
	}

	async function connectMetamask() {
		if (accounts === undefined || accounts.length === 0) {
			try {
				let id = ethereum.networkVersion; // 42161 Arbitrum
				
				// if (id !== 421611) {
				// // if (id !== 42161) { // Arbitrum One
				// 	let network = await switchNetworkArbitrum();
				// 	if (!network) return;
				// }

				// Will open the MetaMask UI
				// Disable button while the request is pending
				setConnectButtonDisabled(true);
				let accs = await ethereum.request({ method: 'eth_requestAccounts' })
					.catch((error) => {
						console.log("Error requesting accounts: ", error);
					});

				/*
				.then(handleAccountsChanged)
				    .catch((error) => {
				      if (error.code === 4001) {
				        // EIP-1193 userRejectedRequest error
				        console.log('Please connect to MetaMask.');
				      } else {
				        console.error(error);
				      }
				    });
				*/

				// Make sure an update occurred
				if (accs !== undefined && accounts !== undefined && 
					(accs.length !== accounts.length || !accs.every(function(value, index) { return value === accounts[index]}))) {
					
					setAccounts(accs);
					updateAccount(accs);
				}
			}
			catch (error) {
				console.error(error);
				setConnectButtonDisabled(false);
			}
		}
	}

	async function init() {
		if (window.web === undefined) {
			window.web3 = new Web3(window.ethereum);
		}
	}

	const handleChainChanged = (chainId) => {
		console.log("Chain changed");
		// Handle the new chain.
		// Correctly handling chain changes can be complicated.
		// We recommend reloading the page unless you have good reason not to.
		// window.location.reload();
		// updateAccount();
	}

	// slow low opacity raining mpx in background
	// Juicy spin / boss monkey
	// undulating wave of mini mechapunkx / raining mechapunkx

	// DON'T PUSH THAT BUTTON
	// Check Whitelist status, sends juicy flying off the button
	// Button

	function hasWhitelist(addr) {
		return addresses.has(addr.toLowerCase());
	}

	return(
		// "padding-left": "4vw", "padding-right": "4vw", 
		<div style={{"background-color": "black", 
			"color": "white", "display": "flex", "flex-direction": "column", 
			"justify-content": "center", "position": "relative", "overflow": "hidden" 
		}}>
			<div className="mpx-whitelist2" style={{"position": "absolute", "width": "100%", "height": "85vw", "z-index": 0}}>
			</div>
			
			<div style={{"display": "flex", "flex-direction": "row", 
				"justify-content": "center", "padding-top": "8vw", "padding-bottom": "4vw",
				"font-size": "2.7vw", "font-family": "'Courier New', monospace", "font-weight": "bold", zIndex: 20}}>
				<div style={{"display": "flex", "flex-direction": "column", "justify-content": "center"}}>
					<div>Did you get MechaPunkx Whitelist?</div>
					{/*"text-align": "right", */}
					<div style={{"padding-top": "3vw"}}>Click =></div>  
				</div>
				<div style={{"display": "flex", "flex-direction": "row", "justify-content": "center", "padding-left": "4vw"}}>
					
				</div>

			</div>

			<div style={{"display": "flex", "flex-direction": "row", "justify-content": "center", zIndex: 20}}>
				<div style={{"max-width": "30%"}}>
					{/*Hello WL Check Hello WL Check Hello WL Check Hello WL CheckHello WL CheckHello WL Check*/}
				</div>
				<div>
					<div className="" style={{"width": "100%", 
						"display": "flex", "flex-direction": "row", "justify-content": "space-between", "align-items": "flex-end"}}>
						<div style={{"display": "flex", "flex-direction": "row", "align-items": "flex-end"}}>
							<div style={{"padding-right": "2vw", "padding-top": "0.7vw", "padding-bottom": "0.7vw", "font-family": "'Courier New', monospace", "font-weight": "bold"}}>
								Account:
							</div>
							{/*<div style={{"padding-top": "1vw", "padding-bottom": "1vw"}}>*/}
							<div id="selectedAddress" style={{"background-color": "#303030", "display": "flex", "align-items": "center", 
								"padding": "0.7vw", "border-radius": "8px", "font-size": "0.9vw", "height": "40%", "display": addressDisplayStyle}}>
								{addressDisplay}
							</div>
							{/*</div>*/}
						</div>
						<div style={{"display": "flex", "flex-direction": "row", "padding-left": "4vw"}}>
							<ThemeProvider theme={themeConnectButton}>
								<Button id="connectButton" 
									disabled={connectButtonDisabled}
									variant="contained" size={"large"} color={"primary"} 
									style={{"font-family": "inherit", "font-weight": "bolder", "font-size": "0.8vw", "padding": "7px",
									"border": "3px solid #006fe6", "border-radius": "4px"}} onClick={connectMetamask}>{connectButtonText}</Button>
							</ThemeProvider>
						</div>

					</div>
				</div>


			</div>
			<div style={{zIndex: 20, "display": "flex", "flex-direction": "row", "justify-content": "center", "padding-top": "4vw",
				"font-size": "2.7vw", "font-family": "'Courier New', monospace", "font-weight": "bold", "padding-left": "8vw", "padding-right": "8vw", "text-align": "center"}}>
				{resultText}
				<div className="centerContent" style={{"position": "absolute", "top": 0, "right": 0, "padding-right": "10vw", "padding-top": "5vh"}}>
				  <div className="selfCenter spaceBetween" style={{"position": "relative"}}>
				    <TwitterShareButton
				      onLoad={function noRefCheck(){}}
				      options={{
				        text: 'I got #MechaPunkx whitelist!!\nSee you on Trove! $MAGIC\n\n',
				        via: 'lamboc0rp'
				      }}
				      url="https://lambo.lol/mechapunkx/whitelist"
				    />
				    <div className="mechapunkx-home-gallery-link-icon cursor-icon-hover noselect" style={{"position": "absolute", "right": 0}}>
						<img src={CursorIcon} height="100%"/>
					</div>
				  </div>
				</div>
				
			</div>
			<div style={{"width": "100%", "display": "flex", "flex-direction": "column", 
				"justify-content": "space-between", "position": "relative"
				}}>
				
				<Image 
						src={SipSipBanner4}
						style={{"background-color": "transparent"}}
						imageStyle={{height: "auto", borderRadius: "0.75rem 0.75rem 0.75rem 0.75rem"}}
						disableSpinner={true}
						animationDuration={2500}
						aspectRatio={"2/1"}
						// aspectRatio={"3/1"}
					/>
				{/*<img src={SipSipBanner4} style={{"border-radius": "10px"}}/>*/}
			</div>
		</div>
	);
}


/*

parent n grand
<div className="home-new" style={{"display": "flex", "flex-direction": "column", "overflow": "hidden"}}> 

<div className="background-blue" style={{"text-align": "center", 
		"font-size": "8vw", "color": "yellow", "font-weight": "bold", 
		"font-family": "Kanit", "padding-bottom": "2vw",
		"position": "relative"
}}>




*/


