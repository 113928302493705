import * as React from 'react';
// import logo from './logo.svg';
import './App.css';
import { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component'
import axios from 'axios';
import ReactDOM from 'react-dom';
import MPXMetadata from './mechapunkx_metadata.json';
import BirdIcon13 from './assets/icons/birdIcon13.png'

// react-router
// import { render } from "react-dom";
//import { BrowserRouter } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  Outlet
} from "react-router-dom";
// import Projects from "./routes/projects";


//import titleBg from './assets/titleBg.png';
import titleBg from './assets/skyBlue.png';
import titleText from './assets/titleText.png';

import RouterBreadcrumbs from './about';


// For modal
// import SimpleDialogDemo from './simpleDialog';
import GalleryModal from './galleryModal';

import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Fade } from '@mui/material';
//import PersonIcon from '@mui/icons-material/Person';
//import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';

import GalleryFilter from "./gallery-filter"
import MonkeyIcon from './assets/monkeyTransparentIcon.png'



// Testing
import Image from 'material-ui-image'


/*
Appears to work, but everything looks janky, page kind of flashes, the fade can't set timing
// import {useImage} from 'react-image'
import {Img} from 'react-image';
<Img className="test-trans" src={url} 
	loader={"https://kneecaps.io/images/1246.png"} 
	height="200 px" 
	width="200px" 
	container={(children) => {
    	return <Fade in appear={true} timing={4000}>{children}</Fade>
  }}
/>

it does prevent the partial loading of the images without the container part, and then with container it does that fade
but the timing doens't work and the page gets all janky




Fade
unmountOnExit={true}
*/



const GalleryImage = ({ url, metadata, imageId, keyy, handleClickOpen }) => {
	
	// if (url.includes("6093")){
	// 	console.log("M", metadata);
	// }
	const errorImage = <div style={{"color": "#5a5959", "font-size": "6vw", "font-weight": "bold"}}>?</div>
	
	return (
		<div className="image-outer">
			<div className="image-item" key={keyy}>
				<div className="gallery-img">
					{/* Seems to work some of the time? but not great, can tell better when gallery filter enabled */}
					{/*<Fade in={true} appear={true} timing={4000} >
						<img src={url} style={{borderRadius: "0.75rem"}} height="200 px" width="200 px" 
							onClick={() => handleClickOpen(url, metadata, imageId)} />
					</Fade>*/}
					
					<Image 
						src={url}
						style={{"background-color": "#1e1e1e", "display": "flex", "flex-direction": "row", "justify-content": "center", "align-items": "center"}}
						imageStyle={{borderRadius: "0.75rem"}}
						errorIcon={errorImage}
						// height: "200px", width: "200px", 
						onClick={() => handleClickOpen(url, metadata, imageId)}
						disableSpinner={true}
						animationDuration={3500}
					/>

					{/*
					<img src={url} style={{borderRadius: "0.75rem"}} height="200 px" width="200 px" 
							onClick={() => handleClickOpen(url, metadata, imageId)} />
					*/}
				</div>
			</div>
			<div className="image-caption noselect" style={{"margin-bottom": "17px"}}>
				{/*NO. {url.split("output_").slice(-1)[0].split('.png')[0]}*/}
				NO. {imageId}
			</div>
			{/*<div id="image-id" className="image-caption2 noselect">
				NO. {url.split("output_").slice(-1)[0].split('.png')[0]}
			</div>*/}
		</div>
	);
};

let Collage = () => {

	// Gallery Filter Categories
	const [categories, setCategories] = React.useState({
		"Background": ["Rare"]
	});

	// Map of ID to metadata for all 8k images (at least what has been minted up to the present)
	const [metadata, setMetadata] = React.useState({});

	const [images, setImages] = React.useState([]);
	const [loaded, setIsLoaded] = React.useState(false);

	// Gallery Modal
	const [open, setOpen] = React.useState(false);
	const [modalImage, setModalImage] = React.useState({"url": "", "metadata": [], "imageId": 0}); 

	// Provided function in hook runs every time array parameter is updated
	
	React.useEffect(() => {
		fetchImages();
	}, []);

	React.useEffect(() => {
		fetchCategories();
	}, []);

	React.useEffect(() => {
		fetchMetadata();
	}, []);

	const fetchCategories = () => {	
		
		// axios.get('https://lambo.lol/mecha-sample-json/trait_map.json', {}).then (res => {
		// 	console.log("Got it bitch: ", res);
		// });

		fetch('../trait_map.json',{
	      headers : { 
	        'Content-Type': 'application/json',
	        'Accept': 'application/json'
	       }
	   	}).then(function(response){
        	//console.log("Got categories resp: ", response);
        	return response.json();
      	}).then(function(json){
      		setCategories(json);
      	});
	}

	/* Use a single file updated with all the metadata currently available, everything else is derived from that*/
	const fetchMetadata = () => {
		setMetadata(MPXMetadata);
		/*
		fetch('../mechapunkx_metadata.json',{
	      headers : { 
	        'Content-Type': 'application/json',
	        'Accept': 'application/json'
	       }
	   	}).then(function(response){
        	//console.log("Got categories resp: ", response);
        	return response.json();
      	}).then(function(json){
      		setMetadata(json);
      	});
      	*/
	}

	let sampleImages = [
		"output_102.png", "output_251.png", "output_2725.png", "output_353.png", "output_2417.png", 
		"output_5555.png", "output_6272.png", "output_7062.png", "output_8579.png", "output_1084.png", "output_3217.png", 
		"output_2945.png", "output_3663.png", "output_4946.png", "output_556.png", "output_6362.png", "output_7208.png", 
		"output_5497.png", "output_1224.png", "output_352.png", "output_3116.png", "output_3792.png", "output_5118.png", 
		"output_5616.png", "output_6568.png", "output_6662.png", "output_1431.png", "output_4848.png", "output_2079.png", 
		"output_4263.png", "output_5233.png", "output_6.png", "output_736.png", "output_6840.png", "output_1545.png", 
		"output_6026.png", "output_3418.png", "output_4381.png", "output_7849.png", "output_7972.png", "output_5420.png", 
		"output_6093.png", "output_1740.png", "output_1979.png", "output_4664.png", "output_2263.png", "output_921.png", 
		"output_8383.png", "output_2580.png", "output_6947.png"];
	sampleImages.reverse();

	function hasMoreSamples() {
		return images.length < sampleImages.length;
	}


	// Display sample images
	const fetchImages = (count = 9) => {
			//const apiRoot = "https://kneecaps.io/images/"
			/*
			axios.get('http://localhost:5000/1246.png', {}).then (res => {
				setImages([...images, ...res.data]);
				setIsLoaded(true);
			});
			*/  
			
			// Load more if available
			if (hasMoreSamples()) {

				// console.log("Loading " + count.toString() + " images");

				let fs = sampleImages.slice(images.length, Math.min(images.length + count, images.length + (sampleImages.length - images.length)));
				let fsPaths = [];
				for (let i=0; i<fs.length; i++) {
					fsPaths.push('https://lambo.lol/mecha-sample/' + fs[i]);
				}
				
				//console.log("Images displayed, samples left: " + images.length.toString() + ", " + (sampleImages.length - images.length).toString())
				// Original, successful
				//setImages([...images, ['https://lambo.lol/mecha-sample/' + sampleImages[images.length]]]);								
				setImages(arr => [...images].concat(fsPaths));
				setIsLoaded(true);
			}
	}
	
	/* For MODAL */
	const handleClickOpen = (url, metadata, imageId) => {
		setOpen(true);
		setModalImage({"url": url, "metadata": metadata, imageId: imageId});
		// console.log("Clicked img: ", url);
	}

	/* For MODAL */
	const handleClose = (value) => {
	    setOpen(false);
	    //setModalImage(""); // TODO: .... hide image / use default fixed size maybe so no shift?
	 };

	 const getImageMetadataFromURL = (url) => {
	 	
		// let fields = url.split('/');
		// let idx = fields[fields.length-1].split('output_')[1];
	 	// 	idx = idx.substring(0, idx.length-4);
	 	//console.log("Fields: ", fields);
	 	//console.log("New idx", idx);
	 	let idx = getImageIdFromURL(url);

	 	if (metadata.hasOwnProperty(idx)) {
	 		return metadata[idx];
	 	}
	 	else {
	 		console.log("Error: ")
	 		return [];
	 	}
	 }

	 const getImageIdFromURL = (url) => {
	 	let fields = url.split('/');
		let idx = fields[fields.length-1].split('output_')[1];
	 	return idx.substring(0, idx.length-4);
	 }

	// 307f0c
	return (
		<div className="" style={{"background-color": "#1e1e1e", "position": "relative"}}>

			{/*505050*/}
			{/*ce1c1c*/}
			{/*7920c4*/}
			{/*0054ff*/}
			
			<div className="gallery-message-hover noselect" 
				style={{position: "absolute", "color": "white", "background-color": "#ce1c1c",  
					"border-radius": "8px",
					"display": "inline-block"
				}}>
				Under Construction!
			</div>

			<div className="gallery">
				
				<div className="gallery-left">
					<div className="gallery-filter">
						<GalleryFilter categories={categories}/>
					</div>
				</div>
				
				<div className="gallery-right">
					<div className="container">
						<div className="content gallery-t">
							<div className="gallery-title-left">
								<span className="gallery-title noselect" style={{"font-family": "Kanit"}}>
									MECHA<span className="gallery-title2">PUNKX</span> <span className="gallery-subtitle">// </span><span style={{"color":"#717171"}}>7848</span>
								</span>
							</div>
							{/* 
							At least need a clear all button
							Possibly semitransparent dropdown with X's
							<div className="gallery-title-right">
								Dragonzord + Green Eyes + Lambo Blade + Wild Purple Hair + 
								Dragonzord + Green Eyes + Lambo Blade + Wild Purple Hair + 
								Dragonzord + Green Eyes + Lambo Blade + Wild Purple Hair + 
							</div>*/}
							
						</div>
						<InfiniteScroll
							dataLength={images}
							next={() => fetchImages(5)}
							//next={() => { console.log("Called next... ")}}
							hasMore={true}
							loader={
							<img
								 src={MonkeyIcon}
								 style={{borderRadius: "10px", backgroundColor: "transparent", marginTop: "40px", marginBottom: "30px"}}
								 height="110px"
								 width="110px"
								 borderRadius="8 px"
								 alt="loading"
							/>}
						 >
								 <div className="image-grid" style={{ marginTop: "30px" }}>
									{loaded ? 
										images.map((image, index) => (
											<GalleryImage url={image} key={index} handleClickOpen={handleClickOpen} imageId={getImageIdFromURL(image)} metadata={getImageMetadataFromURL(image)}/>
										)): ""}
								</div>
						</InfiniteScroll>
					</div>
				</div>
			</div>

			<GalleryModal
		        modalImage={modalImage}
		        open={open}
		        onClose={handleClose}
		      />
		</div>
	);
};

class Gallery extends Component {

	constructor(props) {
		super(props);

		this.state = {
			//accounts: [],
			//contract: null,
			//kneecaps: [] // minted by user
		};

		//this.initialize = this.initialize.bind(this);
		//window.addEventListener('DOMContentLoaded', this.initialize);
	}

	render() {
		return (
			<Collage/>
		);
	}
}

export default Gallery;
