import * as React from 'react';
import Image from 'material-ui-image'
import { Link as RouterLink } from 'react-router-dom';

import Juicy from './assets/juicyTransparent.png'
import JuicyEyesOpen from './assets/juicyTransparent2.png'
import UncleTommy from './assets/uncleTommy.png'
import sample_5 from './assets/mechapunkx/output_1492.png';
import LamboIcon from './assets/icons/lamboIcon.png'
import grassHills from './assets/grass.png';

// Collage
import moonCharacter from './assets/moon.png';
import lamboCharacter from './assets/lambo3SatIconSmall.png';
import monkeyCharacter from './assets/monkeyTransparentIcon.png';
import juicyCharacter from './assets/juicyTransparentSmall.png';
import bandaidsCharacter from './assets/bandaids_face.png';
import candiesCharacter from './assets/candies_faces.png';
import army1Character from './assets/army1.png';
import army2Character from './assets/army2.png';
import treasureBox from './assets/treasure.png';
// import kneecapsSample1 from './assets/kneecaps-sample-2-small.png';
// import kneecapsSample2 from './assets/kneecaps-sample-3-small.png';
// import kneecapsSample3 from './assets/kneecaps-sample-4-small.png';
// import kneecapsSample4 from './assets/kneecaps-sample-8-small.png';
// import lambocorpEnvironment from './assets/titleBgCropped.png';

// import BirdIcon from './assets/icons/birdIcon.png'
// import BirdIcon2 from './assets/icons/birdIcon2.png'
// import BirdIcon3 from './assets/icons/birdIcon3.png'
// import BirdIcon4 from './assets/icons/birdIcon4.png'
// import BirdIcon5 from './assets/icons/birdIcon5.png'
// import BirdIcon6 from './assets/icons/birdIcon6.png'
// import BirdIcon7 from './assets/icons/birdIcon7.png'
// import BirdIcon8 from './assets/icons/birdIcon8.png'
// import BirdIcon9 from './assets/icons/birdIcon9.png'
// import BirdIcon10 from './assets/icons/birdIcon10.png'
// import BirdIcon11 from './assets/icons/birdIcon11.png'
// import BirdIcon12 from './assets/icons/birdIcon12.png'
// import BirdIcon13 from './assets/icons/birdIcon13.png'
// import BirdIcon14 from './assets/icons/birdIcon14.png'
// import BirdIcon15 from './assets/icons/birdIcon15.png'

// import familyPortrait from './assets/familyPortrait.png'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import "animate.css/animate.min.css";
import MusicNote from './assets/icons/musicNote.png';

export interface HomeProps {
	// Theme song control
	togglePlay: () => void;
}

export default function Home2(props: HomeProps) {

	return(
		<div className="home-new" style={{"display": "flex", "flex-direction": "column", "overflow": "hidden"}}> 
			{/*rgb(25, 41, 223)*/}
			<div className="background-blue" style={{"text-align": "center", 
					"font-size": "8vw", "color": "yellow", "font-weight": "bold", 
					"font-family": "Kanit", "padding-bottom": "2vw",
					"position": "relative"
			}}>
				<div className="home-new-logo-bg noselect" style={{"display": "inline-block", 
					"padding-top": "1vw", "padding-bottom": "1vw", "padding-left": "3vw", 
					"padding-right": "3vw", "border-radius": "12px", "margin-top": "4vw", "box-shadow": "rgb(36 80 101 / 80%) 4px 4px 16px"}}>
				LAMBOCORP
				</div>
				{/*"margin-top": "4vw"*/}
				<div className="cloud-intro" style={{"position": "absolute", "width": "100%", "height": "65vw", "z-index": 0}}>
				</div>
			</div>
			<div className="home-row-collapse background-aqua" style={{"display": "flex", "flex-direction": "row", "padding-bottom": "2vw", 
				"align-items": "center", "justify-content": "space-evenly", "margin-bottom": "0vw", "margin-top": "10vw", zIndex: 20}}>
				<div style={{"display": "flex", "flex-direction": "column", "justify-content": "center", 
					"align-items": "center", "padding-left": "2vw", "padding-right": "2vw"}}>
					
					<AnimationOnScroll animateIn="animate__rollIn" animateOnce={true} delay={1400}>
						<div style={{"display": "flex", 
							"flex-direction": "row", "align-items": "center", "justify-content": "center", "padding-top": "4vw", "padding-bottom": "0vw"}}>
							<Image 
								src={Juicy}
								style={{"width": "400px", "background-color": "transparent"}}
								imageStyle={{"height": "auto", borderRadius: "0.75rem 0.75rem 0.75rem 0.75rem"}}
								disableSpinner={true}
								animationDuration={2000}
								aspectRatio={"1/1"}
							/>
						</div>
					</AnimationOnScroll>
					
					<AnimationOnScroll animateIn="animate__fadeInDown" animateOnce={true}>
					{/*"background-color": "rgb(78,78,76)"*/}
						<div className="home-text-nametag-blue" style={{"font-family": "Kanit", "color": "white", 
						"padding-left": "1vw", "padding-right": "1vw", "padding-top": "0.5vw", "padding-bottom": "0.5vw", "border-radius": "8px"}}>
							Mr. Juicy
						</div>
					</AnimationOnScroll>
				</div>
				{/*"background-color": "rgb(40,41,35)", */}
				<div className="mobile-full-width" style={{"display": "flex", "justify-content": "center", "position": "relative", 
					"align-items": "center", "border-radius": "8px", 
					"margin-top": "2vw", "margin-bottom": "2vw", "padding-right": "2vw", "padding-left": "2vw", "max-width": "52%"}}>
					<div style={{"display": "flex", "flex-direction": "column", "justify-content": "center", "align-items": "center"}}>
						<AnimationOnScroll animateIn="animate__fadeInDown" animateOnce={true} delay={500}>
							<div className="home-text-bubble home-text-bubble-blue" style={{
									"margin-bottom": "2vw", "align-self": "flex-start", "display": "flex", 
									"flex-direction": "row", "align-items": "center"}}>
								Hi, Welcome to LamboCorp!
								<div style={{"display": "flex", "flex-direction": "row"}}>
									<div
										onClick={() => props.togglePlay()} 
										style={{"display": "flex", "flex-direction": "column", "justify-content": "center", "padding-left": "1.4vw"}}>
										<img className="music-button" src={MusicNote} style={{"border-radius": "5px"}}/>
									</div>
								</div>
							</div>
						</AnimationOnScroll>
						<AnimationOnScroll animateIn="animate__fadeInDown" animateOnce={true} delay={750}>
							<div className="home-text-bubble home-text-bubble-blue">
								I'm Mr. Juicy.
								<br></br>
								<br></br>
								{/*main character and */}
								I'm the main character and I'm here to guide you through the basics of LamboCorp! 
								{/*I'm the main character. In the past, I trained raptors in Myanmar, and explored the world hunting treasure, but now I'm the most popular celebrity juicebox in LA. Huh-ho!*/}
								<br></br>
								<br></br>
								Meet my friend, Uncle Tommy!
							</div>
						</AnimationOnScroll>
					</div>
				</div>
			</div>

			<div style={{backgroundImage: `url(${grassHills})`, "background-size": "cover", zIndex: 10}}>
				<div className="home-row-collapse-reverse background-green" style={{ "margin-bottom": "6vw", "margin-top": "18vw",  
					"display": "flex", "flex-direction": "row", "align-items": "center", "justify-content": "space-evenly", "padding-bottom": "2vw"}}>
					<div className="mobile-full-width" style={{"max-width": "40%", "padding-left": "2vw", "padding-right": "2vw"}}>
						<div style={{"display": "flex", "flex-direction": "column", "justify-content": "center"}}>
							<AnimationOnScroll animateIn="animate__fadeInDown" animateOnce={true}>
								<div className="home-text-bubble mpx-home-bg-green" style={{"margin-bottom": "2vw"}}>
									Hi, I'm Uncle Tommy. I'm LamboCorp's chief R&D scientist, engineer, and artist. I build here at LamboCorp.
								</div>
							</AnimationOnScroll>
							
							<AnimationOnScroll animateIn="animate__fadeInDown" animateOnce={true} delay={250}>
								<div className="home-text-bubble mpx-home-bg-green">
									Hey Juicy, let's tell them the two main parts of LamboCorp they should know about right now...
								</div>
							</AnimationOnScroll>
						</div>
					</div>
					<div style={{"padding-bottom": "2vw"}}>
						<div style={{"display": "flex", "flex-direction": "column", "justify-content": "center", 
							"align-items": "center", "padding-left": "2vw", "padding-right": "2vw"}}>
						
							{/*<AnimationOnScroll animateIn="animate__fadeInDown" animateOnce={true} delay={300}>*/}
							<div className="home-text-team" style={{"font-family": "Kanit", "text-align": "center", "width": "100%", 
								"color": "white",  "padding-top": "3vw", 
								"border-radius": "8px"}}>
								{/*"position": "absolute", "top": "-16%"*/}
								Team
							</div>
							{/*</AnimationOnScroll>*/}

							<div style={{"display": "flex", 
								"flex-direction": "row", "align-items": "center", "justify-content": "center", "padding-bottom": "2vw"}}>
								<Image 
									src={UncleTommy}
									style={{"width": "260px", "background-color": "transparent"}}
									imageStyle={{"height": "auto", borderRadius: "0.75rem 0.75rem 0.75rem 0.75rem"}}
									disableSpinner={true}
									animationDuration={2000}
									aspectRatio={"1/1"}
								/>
							</div>
							<AnimationOnScroll animateIn="animate__fadeInDown" animateOnce={true} delay={350}>
								<div className="home-text-nametag" style={{"background-color": "#2c750b", "font-family": "Kanit", 
									"color": "white", "padding": "1vw", "border-radius": "8px"}}>
									Uncle Tommy (<a className="text-brightblue" href="https://twitter.com/zebosXL" target="_blank">@zebosXL</a>)
								</div>
							</AnimationOnScroll>
						</div>
					</div>
				</div>
			</div>


			<div className="home-row-collapse background-gray" style={{
				"display": "flex", "flex-direction": "row", "padding-bottom": "5vw",
				"align-items": "center", "justify-content": "space-evenly", "padding-left": "2vw", "padding-right": "2vw",
				"border-top": "4px solid #2e2e2e", "border-bottom": "4px solid #333333"}}>
				<div style={{"display": "flex", "flex-direction": "column", "justify-content": "center", 
					"align-items": "center", "padding-left": "2vw", "padding-right": "2vw"}}>
					<div style={{"display": "flex", 
						"flex-direction": "row", "align-items": "center", "justify-content": "center", "padding-top": "7vw", "padding-bottom": "2vw"}}>
						<Image 
							src={sample_5}
							style={{"width": "300px", "background-color": "transparent"}}
							imageStyle={{"height": "auto", borderRadius: "0.75rem 0.75rem 0.75rem 0.75rem", "border": "6px solid #585858"}}
							disableSpinner={true}
							animationDuration={2000}
							aspectRatio={"1/1"}
						/>
					</div>
					<div className="home-text-nametag home-text-border-thick" style={{"background-color": "rgb(78,78,76)", 
						"font-family": "Kanit", "color": "white", "padding": "1vw", "border-radius": "8px", "margin-top": "1vw"}}>
						MechaPunkx NFT
					</div>
				</div>
				{/*"background-color": "rgb(40,41,35)", */}
				<div className="mobile-full-width" style={{"display": "flex", "justify-content": "center", "position": "relative",  
					"align-items": "center", "border-radius": "8px", 
					"margin-top": "2vw", "margin-bottom": "2vw", "padding-right": "2vw", "padding-left": "2vw", "max-width": "52%"}}>
					<div style={{"display": "flex", "flex-direction": "column", "justify-content": "center", "align-items": "center"}}>
						<AnimationOnScroll animateIn="animate__fadeInDown" animateOnce={true}>
							<div className="home-text-bubble home-text-border-thick" style={{"margin-bottom": "2vw", "align-self": "flex-start"}}>
								First, this is a MechaPunkx.
							</div>
						</AnimationOnScroll>
						<AnimationOnScroll animateIn="animate__fadeInDown" animateOnce={true}>
							<div className="home-text-bubble home-text-border-thick">
								MechaPunkx is LamboCorp's primary NFT collection.
								<br></br>
								<br></br>
								Each MechaPunkx works hard in the LamboCorp factory and yields 
								<span style={{"color": ""}}> LAMBO</span> for the holders (that's you!).
							</div>
						</AnimationOnScroll>
					</div>
					{/*<div style={{"position": "absolute", "top": "0", "right": "0"}}>
						THE OVERLAY IMAGES
					</div>*/}
				</div>
			</div>

			<div className="home-row-collapse background-gray" style={{
				"display": "flex", "flex-direction": "row", "padding-bottom": "4vw", "background-color": "yellow", 
				"align-items": "center", "justify-content": "space-evenly", "padding-left": "2vw", "padding-right": "2vw"}}>
				<div style={{"display": "flex", "flex-direction": "column", "justify-content": "center", 
					"align-items": "center", "padding-left": "2vw", "padding-right": "2vw"}}>
					<div style={{"display": "flex", 
						"flex-direction": "row", "align-items": "center", "justify-content": "center", "padding-top": "6vw", "padding-bottom": "4vw"}}>
						<Image 
							src={LamboIcon}
							style={{"width": "300px", "background-color": "transparent"}}
							imageStyle={{"height": "auto", borderRadius: "0.75rem 0.75rem 0.75rem 0.75rem", 
								"border": "16px solid black", "box-shadow": "4px 4px 4px rgb(36 36 36 / 40%)"}}
							disableSpinner={true}
							animationDuration={2000}
							aspectRatio={"1/1"}
						/>
					</div>
					<div className="home-text-nametag" style={{"background-color": "#ffdc36c7", "font-family": "Kanit", "color": "black", "padding": "1vw", "border-radius": "8px"}}>
						LAMBO (ERC20)
					</div>
				</div>
				{/*"background-color": "rgb(40,41,35)", */}
				<div className="mobile-full-width" style={{"display": "flex", "justify-content": "flex-start", "width": "100%", 
					"align-items": "center", "border-radius": "8px", 
					"margin-top": "2vw", "margin-bottom": "2vw", "padding-right": "2vw", "padding-left": "2vw", "max-width": "52%"}}>
					<div style={{"display": "flex", "flex-direction": "column", "justify-content": "center", "align-items": "center"}}>
						<AnimationOnScroll animateIn="animate__fadeInDown" animateOnce={true} delay={500}>
							<div className="home-text-bubble-lambo" style={{"margin-bottom": "2vw", "align-self": "flex-start"}}>
								What is LAMBO?
							</div>
						</AnimationOnScroll>
						<AnimationOnScroll animateIn="animate__fadeInDown" animateOnce={true} delay={600}>
							<div className="home-text-bubble-lambo">
								{/*LAMBO is a token used across the LamboCorp World. */}
								{/*<br></br>
								<br></br>*/}
								LAMBO is a token used in the game LamboCorp World. LAMBO is needed for everything from taking steps, to doing battle, to going on adventures. Without LAMBO, you're stuck.
								<br></br>
								<br></br>
								The good news is that each MechaPunkx produces LAMBO for free, forever!

								{/*the mechas are the players, and the lambo is the fuel to interact in the world */}
							</div>
						</AnimationOnScroll>
					</div>
				</div>
			</div>

			<div className="background-gray" style={{"padding-left": "10vw", "padding-right": "10vw", paddingTop: "8vw"}}>
				
				<div className="home-text-bubble home-text-border-thick" style={{"padding": "3vw"}}>
					<div className="home-row-collapse" style={{"display": "flex", "flex-direction": "row"}}>
						{/*<AnimationOnScroll animateIn="animate__rollIn" animateOnce={true} delay={1400}>*/}
							<div style={{"display": "flex", 
								"flex-direction": "row", "align-items": "center", "justify-content": "center"}}>
								<Image 
									src={JuicyEyesOpen}
									style={{"width": "400px", "background-color": "transparent"}}
									imageStyle={{"height": "auto", borderRadius: "0.75rem 0.75rem 0.75rem 0.75rem"}}
									disableSpinner={true}
									animationDuration={2000}
									aspectRatio={"1/1"}
								/>
							</div>
						{/*</AnimationOnScroll>*/}
						<div style={{"display": "flex", "flex-direction": "row", "align-items": "center"}}>
							<div>
								To summarize:
								<br></br>
								<br></br>
								In the LamboCorp World game, MechaPunkx are the players, and <span style={{"color": ""}}>LAMBO</span> lets players interact in the world.
								<br></br>
								<br></br>
								But to get the MechaPunkx to yield <span style={{"color": ""}}>LAMBO</span> forever, you have to earn it, right Uncle Tommy?
								<br></br>
								<br></br>
								Read more <RouterLink to="/mechapunkx/tokens" style={{"color": "#2196f3"}}>here</RouterLink>.
							</div>
						</div>
					</div>
				</div> 
			</div>

			{/*<div style={{"display": "flex", 
					"flex-direction": "row", "align-items": "center", "justify-content": "center", 
					"margin-top": "8vw", "position": "relative", "padding-right": "2vw", "padding-left": "2vw"
				}}>
					<div style={{"position": "relative", "width": "100%", "display": "flex", "flex-direction": "row", "justify-content": "center", "padding-top": "100px"}}>
						<Image 
							src={familyPortrait}
							style={{"width": "100%", "background-color": "transparent"}}
							imageStyle={{"height": "auto", borderRadius: "0.75rem 0.75rem 0.75rem 0.75rem"}}
							disableSpinner={true}
							animationDuration={2000}
							aspectRatio={"1/1"}
						/>
						<div className="home-text-bubble home-text-border-thick" style={{"padding": "3vw", "width": "60%", "position": "absolute", "top": 0, "right": 0}}>
							LamboCorp is a global corporation and a world of NFTs with a cast of characters. 
							<br></br>
							<br></br>
							Join Mr. Juicy, Uncle Tommy, the MechaPunkx, and the rest and be a part of LamboCorp!
						</div>
					</div>	
			</div>*/}	
			{/*<AnimationOnScroll animateIn="animate__rubberBand" animateOnce={true} animationDuration={8000}>*/}
			<div style={{"width": "100%", "height": "100%", "min-height": "680px", "position": "relative", "margin-top": "8vw"}}>
				
				<div className="home-text-bubble home-text-border-thick home-center-mobile" style={{
					 "width": "54%", "position": "absolute", "top": 0, "right": 0, "margin-right": "10vw"}}>
					LamboCorp is a global corporation and a world of NFTs with a cast of characters. 
					<br></br>
					<br></br>
					Join Mr. Juicy, Uncle Tommy, the MechaPunkx, and the rest on their adventure! 
					<RouterLink to="/projects" className="home-continue-link" style={{"padding-left": "1vw", "color": "#2196f3"}}>Continue =></RouterLink>
				</div>
				
				<img className="character-spin" src={lamboCharacter} width="20%" style={{"position": "absolute", "top": "47%", "right": "47.3%"}}/>
				<img className="character-spin" src={bandaidsCharacter} width="22%" style={{"position": "absolute", "top": "45%", "left": "14.5%"}}/>
				<img className="character-spin" src={moonCharacter} width="15%" style={{"position": "absolute", "top": "12%", "left": "9%"}}/>
				<img className="character-spin" src={UncleTommy} width="8%" style={{"position": "absolute", "top": "42.5%", "right": "38.7%"}}/>
				
				<img className="character-spin" src={treasureBox} width="18%" style={{"position": "absolute", "top": "62%", "right": "12%"}}/>
				<img className="character-spin" src={monkeyCharacter} width="32%" style={{"position": "absolute", "top": "37%", "right": "16%"}}/>
				<img className="character-spin" src={juicyCharacter} width="22%" style={{"position": "absolute", "top": "55%", "right": "31.3%"}}/>
				<img className="character-spin" src={army1Character} width="21%" style={{"position": "absolute", "top": "58%", "left": "17%"}}/>
				<img className="character-spin" src={army2Character} width="16%" style={{"position": "absolute", "top": "60%", "left": "10%"}}/>
				<img className="character-spin" src={candiesCharacter} width="12%" style={{"position": "absolute", "top": "75%", "left": "38%"}}/>
			</div>	
			{/*</AnimationOnScroll>	*/}
		</div>
	);
}

/*
brightest single text
rgb(170, 170, 170)

brighter text div bg (name tags)
rgb(78,78,76)

txt div backgrounds
rgb(48, 48, 45)
ANIMATIONS TOO ***8
*/