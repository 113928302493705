
import BoysBackInTown from './assets/boysBackInTown.png';
import Juice2 from './assets/juice2.png';
import Juicy2Blue from './assets/juicy2Blue.png';
import SpeedMonkey1 from './assets/speedMonkey1.jpeg';
import SpeedMonkey4 from './assets/speedMonkey4.jpeg';

export default function History() {
	return (
		<div className="padding-page text-page">
		<div className="lambocorp-story-content">
    	{/*/ Uncle Tommy reopened in town his factory, Los Angeles Mechanical, and started making MechaPunkx (as the best way) to find Billy.*/}
    	<div className="noselect lambocorp-story-title" style={{"font-family": "Kanit", textShadow: "0 5px 5px #00000035"}}>The Story of LamboCorp</div>
    	<div className="lambocorp-story">
	    	<p>
	    	LamboCorp might be the largest, most powerful company in the world, producing products of all different kinds. Today, people world-wide recognize it as a household name. 
	    	The story begins with a humble man named Uncle Tommy and his simple nephew Billy.
			</p>
			<div style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", "margin-bottom": "3vw", "margin-top": "1vw"}}>
				<img src={BoysBackInTown} style={{borderRadius: "10px", boxShadow: "0 6px 6px #00000075"}}></img>
				{/*, border: "4px solid #0f3f57d9"*/}
				<img src={Juice2} style={{marginLeft: "2vw", borderRadius: "10px", width: "33%", boxShadow: "0 6px 6px #00000075"}}></img>
			</div>
			<p>
				Billy and Uncle Tommy had a long history together, but after releasing Kneecaps in June of 2021, Billy suddenly went missing. Back on the farm, Uncle Tommy heard the news of Billy from a few 
				<a href="https://twitter.com/LowEffortPunks" target="_blank" style={{"color": "blue", "font-weight": "bold"}}> LEP</a>'s, so he dusted off his tools, and reopened his old factory in town called Los Angeles Mechanical. He knew the best way to find Billy was to suit up, so he started making the MechaPunkx for him and the LEP's. 
			</p><p>
			Elsewhere in the world, Mr. Juicy, was traveling through South East Asia, and he found a great treasure on the beach in Myanmar. Eventually thanks to his newfound wealth, 
			Mr. Juicy ended up in L.A. and became a celebrity. He and his best friend, Mr. Grapes, decided to open a club, and called it Juicy Grapes' Sip Club. Just by random chance, it turned out the club was right next door to Uncle Tommy's shop.
			</p>
			<div style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", "margin-top": "4vw", "margin-bottom": "4vw"}}>
				<img src={Juicy2Blue} style={{borderRadius: "10px", boxShadow: "0 6px 6px #00000075", width: "74%"}}></img>
				{/*, border: "4px solid #0f3f57d9"*/}
				{/*<img src={Juice2} style={{marginLeft: "2vw", borderRadius: "10px", width: "33%", boxShadow: "0 6px 6px #00000075"}}></img>*/}
			</div>
			<p>
			Inevitably, Uncle Tommy and Juicy became great friends and they slammed mad beers together. Unfortunately, later Mr. Grapes sold the club to someone named Mr. V, and Juicy got kicked out. Now around this time, Uncle Tommy had his own problems and his factory ended up out of control even though he tried to stop it. Eventually he got approached by someone from a company who said he could help, but he also said that Uncle Tommy would have to sell him the company in order to stay and keep creating his machines. 
			</p><p>It turned out it was Mr. V., and now with his control of LA Mech. and what Mr. Grapes had renamed the Boring Old Club of Raging Parties, it was Mr. V who joined both L.A. MECH and B.O.C.O.R.P. into what is now known as LAMBOCORP. Today, Mr. Grapes continues to work as a manager for LamboCorp, and so does Uncle Tommy, supposedly in R&D, but whether by his own accord or by force is not known.
			</p><p>
			Kicked out of his own club, and down on his luck, Juicy slumped over, alone on a street bench, and said to himself "Ah Juicy, you've really got yourself in a mess here." Now, even though he found the treasure, he was broke from pouring all his money into the Sip Club that Grapes had stolen from him. In his saddest moment, with a tear of juice running down his box, he started to hear loud music and heavy beats, and as he looked up, he saw a car with music blaring come to a rolling stop on the street right in front of him. 
			</p>
			<div style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", "margin-top": "4vw", "margin-bottom": "4vw"}}>
				<img src={SpeedMonkey4} style={{borderRadius: "10px", boxShadow: "0 6px 6px #00000075", width: "74%"}}></img>
				{/*, border: "4px solid #0f3f57d9"*/}
				{/*<img src={Juice2} style={{marginLeft: "2vw", borderRadius: "10px", width: "33%", boxShadow: "0 6px 6px #00000075"}}></img>*/}
			</div>
			<p>"Yo Juicy, get in!" said a deep voice, as the window began to roll down. Juicy lept off the bench and started to dance, but moreso as a panic reflex, as he had always done. Now only the future knows what awaits for a nervous Juicy confronted by this mysterious stranger, and for the rest of our characters, Mr. Grapes, Mr. V., Uncle Tommy, and of course Billy.
				</p>
		</div>
	</div>
	</div>
 );
}
