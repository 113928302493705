import MPXMetadataTraits from '../mechapunkx_trait_percs.json';

/* Highlight rare traits
Below 1% is a good baseline for most
*/
export function traitFrequencyToColor(category, trait) {

	let colorYellow = "#ffe72a"

	if (MPXMetadataTraits.hasOwnProperty(category)) {
		let cat = MPXMetadataTraits[category];
		if (cat.hasOwnProperty(trait)) {
			let p = cat[trait] * 100;
			
			if (category === "Armor Version") {
				if (p < 0.00128) return colorYellow;
				return "";
			}
			else if (category === "Hair") {
				if (p < 0.5) return colorYellow;
				else return "";
			}
			else if (category === "Eyes") {
				if (trait === "Yellow Glasses") return colorYellow;
				else return "";
			}
			else if (p < 1){
				return colorYellow;
			}
		}
	}
	
	return ""
}

export function traitFrequency(category, trait) {
	if (MPXMetadataTraits.hasOwnProperty(category)) {
		let cat = MPXMetadataTraits[category];
		if (cat.hasOwnProperty(trait)) {
			let p = cat[trait] * 100;
			if (p < 1) {
				// return "< 1"; 
				if (p < 0.01) return p.toFixed(3).toString();
				else return p.toFixed(2).toString();
			}
			else if (p < 10) {
				return p.toFixed(0).toString();
			}
			return p.toFixed(0).toString();
		}
		else {
			console.error("Trait frequency not found: [" + trait + "]");
			return "~";
		}
	}
	else {
		console.error("Trait cat not found: [" + category + "]");
		return "~";
	}
}