import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Link from '@mui/material/Link';
import ListItem from '@mui/material/ListItem';
import Collapse from '@mui/material/Collapse';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import MenuItem from '@mui/material/MenuItem';
import ScrollToTop from "./scrollToTop";
import Dashboard from './mechapunkx/dashboard';

import { ExpandLess as ExpandLessIcon } from "@material-ui/icons";
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";
import Container from '@mui/material/Container';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import {
  Link as RouterLink,
  Route,
  Routes,
  MemoryRouter,
  BrowserRouter,
  useLocation
} from 'react-router-dom';

import Home from './home';
import Home2 from './home2';
import Gallery from './gallery';
import LamboCorpLinks from './homeLinks';
import WLCheck from './mechapunkx/wl_check';

import { default as KneecapsHome } from "./kneecaps/home";
import { default as KneecapsAbout } from "./kneecaps/about";
// import { default as KneecapsLinks } from "./kneecaps/links";

import { default as MechaHome } from "./mechapunkx/home";
import { default as MechaMint } from "./mechapunkx/mint";
import { default as MechaAbout } from "./mechapunkx/about";
import { default as MechaTokens } from "./mechapunkx/tokens";
// import { default as MechaLinks } from "./mechapunkx/links";

import { default as Projects } from "./projects";
import { default as History } from "./history";

import useSound from 'use-sound';
import ClickSound from './sounds/click.wav';
import AlertSound from './sounds/alert.wav';
import themeSong from './sounds/coolSong3Small.mp3';

// import PopSound from './sounds/pop.wav';
// import SwapSound from './sounds/swap.wav';
// import SwitchSound from './sounds/switch_page.wav';



const breadcrumbNameMap = {
  
  // '/inbox/important': 'Important',
  '/projects': '',
  '/history': 'STORY',
  '/kneecaps': 'KNEECAPS',
  //'/kneecaps/about': 'about', // add to continue breadcrumb, but need to configure w/ spacers etc.
  // '/kneecaps/links': 'links',

  '/mechapunkx': 'MECHAPUNKX',
  // '/mechapunkx/gallery': 'gallery',
  // '/mechapunkx/inventory': 'Inventory',
  // '/mechapunkx/about': 'About',
  // '/mechapunkx/links': 'Links'

};

function ListItemLink(props) {
  const { to, open, ...other } = props;
  const primary = breadcrumbNameMap[to];

  let icon = null;
  if (open != null) {
    icon = open ? <ExpandLessIcon /> : <ExpandMoreIcon />;
  }

  return (
    <li>
      <ListItem button component={RouterLink} to={to} {...other}>
        <ListItemText primary={primary} />
        {icon}
      </ListItem>
    </li>
  );
}

ListItemLink.propTypes = {
  open: PropTypes.bool,
  to: PropTypes.string.isRequired,
};

const LinkRouter = (props) => <Link {...props} component={RouterLink} />;

const Page = () => {
    
  const [playClick] = useSound(ClickSound, { volume: 0.5 });
  const [playAlert] = useSound(AlertSound, { volume: 0.7, playbackRate: "0.3" });

  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);

  //const [projectMenu, setProjectMenu] = React.useState('abc');
  
  //let projectMenu = "";
  // console.log(pathnames);

  //if (pathnames.length >= 1 && pathnames[0].includes("projects")) {

  let project = "";

  // functions are not valid as react child, when I tried making this a function
  let projectMenu = [];
  if (pathnames.length === 0) projectMenu = ["Links"];
  else {
    if (pathnames[0] === "kneecaps") {
      // projectMenu = ["About", "Links"];
      projectMenu = ["Links"]; 
      project = pathnames[0];
    }
    else if (pathnames[0] === "mechapunkx") {
      projectMenu = ["Dashboard", "Gallery", "Mint", "Tokens", "About", "Links"];
      project = pathnames[0];
    }
    else if (pathnames[0] === "projects") {
      projectMenu = ["Links"];
    }
  }
  
  // console.log("proj: ", projectMenu);

  /* 
  Link Router is a MaterialUI Link ( ReactRouterDOM Link )
  Removed materialUI styling on ones below
  */

  const menuBackgroundColor = location.pathname === "/projects" ? '#2181f9': '#2181f9';

  
  {/*<div style={{ background: this.state.color }} id="main">

changeColor = color => {
  this.setState({ color });
};*/}


  return (
    <div className="menu-main menu-item" style={{font: "13vw Kanit", "background-color": menuBackgroundColor}}>
      <div className="menu-left">
      {/*underline="hover" color="inherit" */}
       <div className="spacer-h"></div>
        <RouterLink className="menu-item" to="/" onClick={() => playClick()}>
            HOME
        </RouterLink>
        <div className="spacer-h"></div>
        <RouterLink className="menu-item" to="/projects" onClick={() => playClick()}>
          PROJECTS
        </RouterLink>
        <div className="spacer-h"></div>
        
      {/*This was Breadcrumbs .... but the styling was bad */}
      <div style={{"display": "flex", "flex-direction": "row"}} aria-label="breadcrumb">
        
        {pathnames.map((value, index) => {
          const last = index === pathnames.length - 1;
          const to = `/${pathnames.slice(0, index + 1).join('/')}`;

          /*
          return last ? (
            <Typography color="text.primary" key={to}>
              {breadcrumbNameMap[to]}
            </Typography>
          ) : (
            <LinkRouter underline="hover" color="inherit" to={to} key={to}>
              {breadcrumbNameMap[to]}
            </LinkRouter>
          );
          */
          return last ? (
              <div className="menu-item">
                {breadcrumbNameMap[to]}
              </div>
          ) : (
            <RouterLink className="menu-item" to={to} key={to} onClick={() => playClick()}>
              {breadcrumbNameMap[to]}
            </RouterLink>
          );
        })}
        
      </div>
      </div>
      <div className="menu-right">

        {/*style={{"padding": "20px"}}  Can do this if desperate*/}
        {projectMenu.map((p, i) => {
          return (
            <div style={{"display": "flex", "flex-direction": "row"}}>
            <RouterLink 
              className="menu-item" to={project + '/' + p.toLowerCase()} key={project + '/' + p + "-" + i.toString()}
              onClick={() => { 
                if (project === "mechapunkx" && p === "Mint") {
                  // playAlert()
                  playClick()
                }
                else {
                  playClick()
                }
              }}
            >
            {p.toUpperCase()}
            </RouterLink>
            <div className="spacer-h"></div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default function RouterBreadcrumbs() {

  // const [open, setOpen] = React.useState(true);

  // const handleClick = () => {
  //   setOpen((prevOpen) => !prevOpen);
  // };

  // <MemoryRouter initialEntries={['/inbox']} initialIndex={0}>

  // Theme Song
  const [play, { stop, sound }] = useSound(themeSong, { interrupt: true, loop: true, volume: 1.07 });

  const togglePlay = () => {
    
    if (sound.playing()) {
      stop();
    }
    else {
      play();
    }
  }

  return (
    <div className="app-full">
      <BrowserRouter >
        <ScrollToTop />
        <Page />
        <Routes>
          {/*<Route path="/" element={<Home/>} />*/}
          <Route path="/" element={<Home2 togglePlay={togglePlay}/>} />
          <Route path="/links" element={<LamboCorpLinks/>} />

          <Route path="/kneecaps" element={<KneecapsHome/>} />
          {/*<Route path="/kneecaps/about" element={<KneecapsAbout/>} /> */}
          <Route path="/kneecaps/links" element={<LamboCorpLinks/>} /> 
          
          <Route path="/mechapunkx" element={<MechaHome/>} />
          <Route path="/mechapunkx/gallery" element={<Gallery/>} />
          <Route path="/mechapunkx/mint" element={<MechaMint toggleThemeSong={togglePlay} stopThemeSong={stop}/>} />
          <Route path="/mechapunkx/tokens" element={<MechaTokens/>} />
          <Route path="/mechapunkx/about" element={<MechaAbout/>} />
          <Route path="/mechapunkx/links" element={<LamboCorpLinks/>} />

          <Route path="/projects" element={<Projects/>} />
          <Route path="/history" element={<History/>} />
          <Route path="/mechapunkx/whitelist" element={<WLCheck/>} />
          <Route path="/mechapunkx/dashboard" element={<Dashboard/>} />
          <Route path="*" element={<Home2 togglePlay={togglePlay}/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}
// <Route path="*/*.png" element={<div></div>} status={404}/>