import Fade from '@mui/material/Fade';
import { TransitionGroup } from 'react-transition-group';

import Cloud1 from '../assets/icons/categories/cloud_1.png';
import Energy4 from '../assets/icons/categories/energy_4.png';
import Strength2 from '../assets/icons/categories/strength_2.png';
import RunningMan from '../assets/icons/categories/agility_1.png';
import Wood1 from '../assets/icons/categories/wood_1.png';
import Psychic8 from '../assets/icons/categories/psychic_8.png';
import Sound4 from '../assets/icons/categories/sound_4.png';
import Hammer from '../assets/icons/categories/attack_7.png';
import Craftsman9 from '../assets/icons/categories/craftsman_9.png';
import { traitFrequency, traitFrequencyToColor } from './traitFrequency';

const iconsCategories = {
  'Background': Cloud1,
  'Skin': Energy4,
  'Armor': Strength2,
  'Armor Version': Strength2,
  'Body': RunningMan,
  'Hair': Wood1,
  'Eyes': Psychic8,
  'Mouth': Sound4,
  'Item': Hammer,
  'Other': Craftsman9
}

export interface AccountViewProps {
	ownerAddress: String;
	tokenId: String;
	traits: Array;
	metadata: Object;
}

export default function AccountViewTraits(props: AccountViewProps) {

	let traits = [];
	if (props.metadata.hasOwnProperty(props.tokenId)) {
		// This includes the "Rank" trait
		traits = props.metadata[props.tokenId];
	}

	let skipArmorVersion = false;
	let armor, armorVersion;
	for (let i=0; i < traits.length; i++) {
		let m = traits[i];
		if (m["trait_type"] === "Armor") {
			armor = m["value"];
		}
		else if (m["trait_type"] === "Armor Version") {
			armorVersion = m["value"];
		}
	}
	if (armor === armorVersion) {
		// console.log("COMPARING: ", armor, armorVersion);
		skipArmorVersion = true;
	}
	else {
		if (armorVersion.startsWith(armor)) {
			armorVersion = armorVersion.substring(armor.length, armorVersion.length).trim();
		}
	}

	function showCategory(cat) {
		let c = cat.toLowerCase();
		return c.includes("armor") || c.includes("skin") || c.includes("mouth") || c.includes("hair");
	}

	function categoryOrBlank(cat) {
		return showCategory(cat) ? cat : "";
	}

	// good purple: 9b4efb
	// good pink: ff51dd
	// good aqua: 35eeed
	// neon: 22ee45
	// yellow: f2ff38 / ffe43c
	// e5f141
	function traitToColor(trait_json) {

		let type = trait_json["trait_type"];
		let value = trait_json["value"];

		if (type === "Armor") return "#43a7ff";
		else if (value === "Rare") return "#ffe43c";
		else if (value === "Alien" && type === "Skin") return "#22ee45";
		else if (value === "Ape" && type === "Skin") return "#e0c78f";
		else return "";
	}

	return (
		<div className="mpx-inv-text" style={{"width": "48%", "display": "flex", 
			"flex-direction": "column", "padding-left": "2vw", "padding-right": "3vw"
		}}>
			<div className="mpx-inv-account-title">
				<a href={"https://trove.treasure.lol/collection/mechapunkx/" + props.tokenId.toString()} target="_blank">
				MechaPunkx #{props.tokenId}
				</a>
			</div>
			<div>
				<div className="text-lightblue mpx-inv-account-address" style={{"display": "flex", 
					"flex-direction": "row", "justify-content": "space-between", "padding-top": "2vw"
				}}>	
					<div className="mpx-inv-account-address">
						Owned by: <span style={{"padding-left": "1vw"}}>
						<a href={"https://trove.treasure.lol/user/" + props.ownerAddress} target="_blank">
						{
							props.ownerAddress.length < 10 ? props.ownerAddress :
								props.ownerAddress.substring(0, 8) + "..." + props.ownerAddress.substring(
									props.ownerAddress.length - 4, props.ownerAddress.length)
						}
						</a>
						</span>
						
					</div>
					<div>
						{/*{props.ownerAddress}*/}
					</div>
				</div>
			</div>
			{/*<div style={{"padding-top": "2vw"}}>
				{props.traits.map((trait) => (
					<div>{trait}</div>
				))}
			</div>*/}
			<div style={{"padding-top": "2vw"}}>
				<table className="modal-attributes" style={{"display": "table",
			  		"text-align": "right", "borderCollapse": "separate", "padding-left": "0vw"}}>
			  		<tbody key={Math.random()}>
			  		{/*<Fade in={true} appear={true} timeout={370}><div>*/}
					{traits.map((trait_json) => 
						((skipArmorVersion && trait_json["trait_type"] === "Armor Version") || 
							(trait_json["trait_type"] === "Rank")) ? "" :
						<tr key={"trait_row_" + Math.random().toString()}>
							<td style={{"padding-right": "1vw", "text-align": "left", "padding-top": ".2vw", "padding-bottom": ".2vw"}}>
								<img style={{"border-radius": "4px", "verticalAlign": "middle"}}
						  			src={iconsCategories[trait_json["trait_type"]]} height="20px" width="20px"></img>
						  	</td>
							{/*<td style={{"padding-left": "1vw", "padding-right": "1vw", "font-size": "0.7em"}}>
						  	<span className="modal-category">{trait_json["trait_type"]}</span></td>
	
						(trait_json["trait_type"] === "Armor") ? "#4ea7ff" : "" 
								
	
						  */}
							<td className="text-offwhite account-traits-text noselect" style={{
								"text-align": "left", "padding-right": "2.5vw", "font-size": "1.4vw", "color": traitToColor(trait_json)}}>
								{
						  			(trait_json["trait_type"] === "Armor Version") ? armorVersion : trait_json["value"]
						  		}<span className="traits-cat-hover" style={{"color": "rgb(61 61 61)", "font-size": "0.65em", "padding-left": "1.5vw"}}>
						  		{categoryOrBlank(trait_json["trait_type"])}
						  		</span>
						  	</td>
						  	<td className="modal-category account-traits-text" style={{"font-size": "0.7em", "text-align": "center", 
						  		"padding-right": "1vw", "padding-left": "1vw", 
						  		"background-color": "#1e1e1e", "border-radius": "8px",
						  		"color": traitFrequencyToColor(trait_json["trait_type"], trait_json["value"])
						  	}}>
						  	{traitFrequency(trait_json["trait_type"], trait_json["value"])}%
						  	</td>
						  {/*<td style={{"padding-left": "1vw", "padding-right": "1vw", "font-size": "0.7em"}}>
						  	<span className="modal-category">{trait_json["trait_type"]}</span></td>*/}
						  {/*<td style={{"padding-right": "1vw"}}><img style={{"border-radius": "4px", "verticalAlign": "middle"}}
						  	src={iconsCategories[trait_json["trait_type"]]} height="20px" width="20px"></img></td>*/}
						</tr>
						
					)}
					{/*</div></Fade>*/}
					
					</tbody>
				</table>
			</div>
			{/*Icon, name gray, optional type white, percentage*/}

		</div>
	);
}

/*
<ImageListItem key={"mpx-inv-img-" + tokenId.toString()} className="mpx-inv-img-selectable" onClick={selectMechaPunkx}>
<img src={`${BirdIcon13}?w=164&h=164&auto=format`} srcSet={`${BirdIcon13}?w=164&h=164&auto=format&dpr=2 2x`} loading="lazy"></img>
</ImageListItem>
*/