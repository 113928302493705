import * as React from 'react';
import { Link } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import LamboLogo from './assets/lambo3SatIconSmall.png';
import KneecapsLogo from './assets/lambocorpKneesSmall.png';
import MonkeyIcon from './assets/links/mpx_logo_monkey.png';
import SipBanner5 from './assets/sipsipBanner5.jpg';
// import SipBanner from './assets/sipsipBanner.jpg';
// import MrJuicy3 from './assets/mrJuicy3.jpg'
// import MrJuicy4 from './assets/mrJuicy4.jpg'
// import MrJuicy2 from './assets/mrJuicy2.jpg'
import Candies from './assets/candies_blank_small.png';
import Image from 'material-ui-image';
import { Fade } from '@mui/material';

import useSound from 'use-sound';
import clickSound from './sounds/click.wav';
import mistakeSound from './sounds/mistake.wav';
import selectSound from './sounds/project-select.wav';



import BirdIcon from './assets/icons/birdIcon.png'
import BirdIcon2 from './assets/icons/birdIcon2.png'
import BirdIcon3 from './assets/icons/birdIcon3.png'
import BirdIcon4 from './assets/icons/birdIcon4.png'
import BirdIcon5 from './assets/icons/birdIcon5.png'
import BirdIcon13 from './assets/icons/birdIcon13.png'
import BirdIcon7 from './assets/icons/birdIcon7.png'
import BirdIcon8 from './assets/icons/birdIcon8.png'
import BirdIcon15 from './assets/icons/birdIcon15.png'



export default function Projects(props) {

	const [hover1, setHover1] = React.useState(false);
	const [hover2, setHover2] = React.useState(false);
	const [hover3, setHover3] = React.useState(false);

	// Just a toggle to make the menu musical
	// const [longSound, setLongSound] = React.useState(1.0);

	const [playClick] = useSound(clickSound, { volume: 0.5 });
	const [playMistake] = useSound(mistakeSound, { volume: 0.38 });	
	const [playSelect] = useSound(selectSound, { volume: 0.27, playbackRate: 1.1 });	
	const [playSelectLong] = useSound(selectSound, { volume: 0.26, playbackRate: 0.7 });	

	// "background-color": "#2eccff"
	return (
		<div style={{"display": "flex", "flex-direction": "column", "position": "relative", "overflow": "hidden"}}>
			<div className="cloud-intro" style={{"position": "absolute", "width": "100%", "height": "65vw", "z-index": 10}}>
			</div>
		<div className="projects-main padding-page text-page">
			
			<div style={{"z-index": 0}}>
				
				{/*<div className="noselect">

					Welcome to <span style={{"color": "yellow", fontFamily: "Kanit"}}>LamboCorp</span>.
					Have a look around, or check out our <Link to="/history" style={{"color": "blue"}}> story</Link>, then get back to work!
				</div>*/}
				

				{/*<div className="projects-title noselect" style={{"margin-bottom": "14px", "margin-top": "14px", "font-size": "1.3em"}}>BILLY'S WORLD
				</div>*/}
				
				<div style={{}} className="projects-content">
				<Fade in={true} timeout={800} appear={true}>
		    		<Link to="/kneecaps" className="projects-item" 
		    			onMouseEnter={() => {setHover1(true); playClick(); }} 
		    			onMouseLeave={() => { setHover1(false); }}
		    			onClick={() => playSelect() }
		    			style={{"margin-top": "14px", "display": "flex", "flex-direction": "row", "align-items": "center", "border-radius": "10px"}}>
		    			<div style={{"padding": "1vw"}}><img className={`${hover1 ? "projects-logo-spin" : ""}`} src={KneecapsLogo} height="50px" style={{"border-radius": "8px"}}></img></div>
			    		{/*ffe000*/}
			    		<div className="home-links-project-title" style={{"display": "flex", "flex-direction": "row", 
				    		"justify-content": "center", "align-items": "center", "padding-bottom": "0", "margin-bottom": "1vw", "color": "yellow"}}>
				    		KNEECAPS
				    	</div>
			    	</Link>
		    	</Fade>
			    
			    <Fade in={true} timeout={1200} style={{transitionDelay: 100}}>
					<Link to="/mechapunkx" className="projects-item" 
						onMouseEnter={() => { setHover2(true); playClick(); }} 
						onMouseLeave={() => setHover2(false)}
						onClick={() => playSelectLong() }
						style={{"display": "flex", "flex-direction": "row", "align-items": "center", "border-radius": "10px"}}>
		    			<div><img className={`${hover2 ? "projects-logo-spin" : ""}`} src={MonkeyIcon} height="50px" style={{"border-radius": "5px", "padding": "1vw"}}></img></div>
			    		<div className="home-links-project-title" style={{"display": "flex", "flex-direction": "row", 
				    		"justify-content": "center", "align-items": "center", "padding-bottom": "0", "margin-bottom": "1vw"}}>
				    		<span style={{"color": "red"}}>MECHA</span><span style={{"color": "blue"}}>PUNKX</span>
				    	</div>
			    	</Link>
		    	</Fade>

		    	<Fade in={true} timeout={1200} style={{transitionDelay: 250}}>
			    	<Link to="/projects" className="projects-item" 
			    		onMouseEnter={() => { setHover3(true); playClick(); }} 
			    		onMouseLeave={() => setHover3(false)} 
			    		onClick={() => playMistake()}
			    		style={{"display": "flex", "flex-direction": "row", "align-items": "center", "border-radius": "10px"}}>
		    			<div><img className={`${hover3 ? "projects-logo-spin" : ""}`} src={LamboLogo} height="50px" style={{"border-radius": "5px", "padding": "1vw"}}></img></div>
			    		<div className="home-links-project-title" style={{"display": "flex", "flex-direction": "row", 
				    		"justify-content": "center", "align-items": "center", "padding-bottom": "0", "margin-bottom": "1vw"}}>
				    		<span style={{"color": "#ffe000"}}>?</span>
				    	</div>
			    	</Link>
		    	</Fade>
		    </div>
		    <div>
		    </div>
		</div>
		    <div className="projects-content-right" style={{"z-index": 0}}>
		    	{/*<div>
		    		<div style={{"background-color": "yellow", "font-family": "Kanit", "font-size": "7.4vw", "font-weight": "bold", "text-align": "center", "border": "14px solid black", "box-sizing":"border-box", "border-radius": "24px"}}>LAMBOCORP</div>
		    	</div>
		    	<br></br>*/}
		    	<div style={{"width": "100%"}}>
			    	{/*{<svg className="projects-title-svg" x="0" y="0" textAnchor="middle">
		                <linearGradient id="gr-simple" x1="0" y1="0" x2="100%" y2="100%">
			                <stop stop-color="hsl(400, 100%, 70%)" offset="10%"/>
			                <stop stop-color="hsl(425, 100%, 50%)" offset="90%"/>
			            </linearGradient>
		                <text className="projects-title-svg-text" x="50%" y="72%">LAMBO<tspan className="projects-title-svg-text2" style={{"fill": "black"}}>CORP</tspan></text>
		            </svg>}*/}
		            {/*<div className="noselect" style={{"font-family": "Kanit", "font-size": "7.6vw", "font-weight": "bold", "text-align": "center"}}>LAMBOCORP</div>*/}
		            
	            </div>

		    	{/*<div style={{"font-size": "4vw", "margin-bottom": "2vw"}}>LAMBOCORP</div>*/}
		    	
		    	{/*
		    	<div className="projects-banner">	
			    	<Image 
						src={SipBanner5}
						style={{boxShadow: "0 6px 6px #00000075", "background-color": "transparent", borderRadius: "8px"}}
						// imageStyle={{height: "auto", borderRadius: "0.75rem 0.75rem 0.75rem 0.75rem"}}
						imageStyle={{height: "auto", borderRadius: "8px"}}
						disableSpinner={true}
						animationDuration={150}
						aspectRatio={"3/1"}
					/>
				</div>*/}
				{/*9615c7*/}

		    	<div className="projects-content-right-text noselect" style={{"padding-left": "2vw", "padding-right": "2vw", "padding-top": "3vw", "padding-bottom": "2vw", "border-radius": "10px", "color": "#eaeaea"}}>
		    		{/*Welcome to <span style={{"color": "yellow", fontFamily: "Kanit"}}>LamboCorp</span>*/}
					{/*Hi, good to see you!*/}
					Welcome to <span style={{fontFamily: "Kanit", "color": "yellow"}}>LamboCorp</span>!
					<br></br>
					<br></br>
					<div style={{"text-indent": "2vw"}}>
					In the LamboCorp World, you can play, earn, adventure, and collect NFT's.
					<br></br>
					<br></br>
					</div>
					<div style={{"text-indent": "2vw"}}>
					The stories of many <Link to="/history" className="text-lightblue">characters</Link> are only just beginning. Follow their journey!
					{/*, and explore the world*/}
					{/*Join them on their journey, or go back to work!*/}
					<br></br>

					<br></br>
					<Fade in={true} timeout={1500} appear={true}>
			            <div className="projects-content-banner noselect" style={{"display": "flex", "flex-direction": "row", 
			            	"justify-content": "space-evenly", "align-items": "center", 
			            	"padding-left": "2vw", "padding-right": "2vw", "padding-top": "1vw"
			            }}>
			            	<img src={BirdIcon4} width="12%" style={{"border-radius": "8px"}}></img>
			            	<img src={BirdIcon2} width="12%" style={{"border-radius": "8px"}}></img>
			            	<img src={BirdIcon3} width="12%" style={{"border-radius": "8px"}}></img>
			            	<img src={BirdIcon5} width="12%" style={{"border-radius": "8px"}}></img>
			            	<img src={BirdIcon13} width="12%" style={{"border-radius": "8px"}}></img>
			            	<img src={BirdIcon7} width="12%" style={{"border-radius": "8px"}}></img>
			            	<img src={BirdIcon15} width="12%" style={{"border-radius": "8px"}}></img>
			            </div>
		            </Fade>
					<br></br>
					<br></br>
					{/*353540*/}
					<Divider variant="middle" style={{"background": "#3b3b43"}}/>
					<br></br>
					<br></br>
					</div>
					<div style={{"text-indent": "2vw"}}>
					The projects listed are all NFT collections of characters, items, and locations from the <span style={{fontFamily: "Kanit"}}>LamboCorp World</span>, created by <span style={{fontFamily: "Kanit"}}>LamboCorp</span> (for now).
					<br></br>
					<br></br>
					
					[<span style={{"color": "red", fontFamily: "Kanit"}}>Mecha<span className="text-lightblue">Punkx</span></span>]
					<br></br>
					<br></br>
					<div style={{"text-indent": "2vw"}}>
					<span style={{"font-family": "Kanit"}}> LamboCorp</span>'s premier collection of 7,848 NFTs. New gamified approach to distribution. 
					Owners of one NFT can earn up to 30 more if you HODL for the entire 6 month distribution period. 
					Have fun, along with the community, and see the new traits of your <span style={{"font-family": "Kanit"}}>MechaPunkx</span> every time you mint the next free one.  
					<br></br>
					<br></br>
					</div>
					<div style={{"text-indent": "2vw"}}>
					<span style={{"font-family": "Kanit"}}>MechaPunkx</span> generate <span style={{"font-family": "Kanit"}}>LAMBO</span>, the ERC20 base token of the <span style={{"font-family": "Kanit"}}>LamboCorp World</span>. 
					The <span style={{"font-family": "Kanit"}}>MechaPunkx</span> will be the only collection to earn <span style={{"font-family": "Kanit"}}>LAMBO</span>, each yielding 1-50 <span style={{"font-family": "Kanit"}}>LAMBO</span> / day, with the entire supply of <span style={{"font-family": "Kanit"}}>LAMBO</span> distributed through this mechanism 
					(see <Link 
						to="/mechapunkx/tokens" 
						style={{"color": "#2196f3"}}
						onClick={() => playClick()}
						>Tokens</Link>).
					<br></br>
					<br></br>
					</div>
					<div style={{"text-indent": "2vw"}}>
						<span style={{"font-family": "Kanit"}}>MechaPunkx</span> will be launching soon (early Q2 2022) simultaneously with the launch of 
						<a href="https://treasure.lol" target="_blank"><span style={{"color": "red", "font-family": "Kanit"}}> TROVE</span></a> marketplace. 
					</div>
					<br></br>
				</div>

				<br></br>
				<Divider variant="middle" style={{"background": "black"}}/>
				<br></br>
				<br></br>
				[<span style={{"color": "#e000db", fontFamily: "Kanit"}}>Kneecaps</span>] 
				<br></br>
				<br></br>
				<div style={{"text-indent": "2vw"}}>
					<span style={{"font-family": "Kanit"}}>Kneecaps</span> is the genesis collection of <span style={{"font-family": "Kanit"}}>LamboCorp</span>. They're free to mint, but they will always be especially honored here. 
					For example, <span style={{"font-family": "Kanit"}}>Kneecaps</span> holders were the first to receive whitelist spots for <span style={{"font-family": "Kanit"}}>MechaPunkx</span>. Oh, and "Kneecaps are badass," Billy says.
					<br></br>
					<br></br>
				</div>
				<div style={{"text-indent": "2vw"}}>
					Further integration into the workings of <span style={{"font-family": "Kanit"}}>LamboCorp</span> may occur in the future. If you're an artist who would like to collaborate / contribute to the future of <span style={{"font-family": "Kanit"}}>Kneecaps</span>, please get in touch!
				</div>
					{/*The Projects of LamboCorp are  of characters, items, and locations from the LamboCorp world.*/}
					{/*LAMBO, 100% fair distribution through mining by NFTs*/}
					<br></br>
					<br></br>
					<Divider variant="middle" style={{"background": "black"}}/>
					
					<br></br>
					<br></br>
					<div style={{"color": "yellow"}}>
						LAMBO (ERC20)
						<br></br>
						<br></br>
					</div>
					<div style={{"text-indent": "2vw"}}>
						
						<span style={{"font-family": "Kanit"}}>LAMBO</span> powers the <span style={{"font-family": "Kanit"}}>LamboCorp World</span>, with a 100% fair distribution. 
						<br></br>
						<p style={{"text-indent": "2vw"}}>Full details on the <Link 
							to="/mechapunkx/tokens" 
							className="text-brightblue"
							onClick={() => playClick()}
							>Tokens</Link> page.
						</p>
					</div>
					<br></br>
					<br></br>
					{/*In the long term, <span style={{"font-weight": "bolder"}}>MechaPunkx</span> have the utility of earning <span style={{"font-weight": "bolder"}}>LAMBO</span>, the ERC20 base token of the <span style={{"font-weight": "bolder"}}>LamboCorp World</span>. 
					The <span style={{"font-weight": "bolder"}}>MechaPunkx</span> will be the only collection to earn <span style={{"font-weight": "bolder"}}>LAMBO</span>, each yielding 1 <span style={{"font-weight": "bolder"}}>LAMBO</span> / day, with the entire supply of <span style={{"font-weight": "bolder"}}>LAMBO</span> being distributed through this mechanism (more details to come).
					*/}
					{/*The world of <span style={{"color": "yellow", fontFamily: "Kanit"}}>LamboCorp</span> is powered by LAMBO, an ERC20 token.*/}
				</div>

		    </div>
		</div>
		<div className="projects-bottom" style={{"height": "auto", 
			"display": "flex", "flex-direction": "column", "justify-content": "end", "align-items": "end"}}>
			{/*<img src={Candies} className="projects-candies" style={{"position": "fixed", "bottom": "0px", "right": "0px"}}/>*/}
			<img src={Candies} className="projects-candies" style={{"bottom": "0px", "left": "0px"}}/>
		</div>
		</div>
  );
}


/*


Will you join them to journey and explore?
, and explore the world?
Today, many fascinating characters you'll meet along the way are just beginning their journeys. Will you join them?

For now, 
Today,
For now, the tales of many fascinating characters are just beginning. Will you join them on their journey?
All that's left is the journey ahead, and 
<br></br>
<br></br>
Will you join the fascinating characters you meet on their journeys?
If you choose, you'll meet many fascinating characters on your journey. 

Will you join them, as the many fascinating characters of the world go on their journeys?

<br></br>
<br></br>

Our future is bright, and <span style={{"color": "yellow", fontFamily: "Kanit"}}>LamboCorp</span>'s world is filled many fascinating characters. Will you join them, as they play, earn, adventure, and collect NFT's.

Our future is bright, and <span style={{"color": "yellow", fontFamily: "Kanit"}}>LamboCorp</span>'s world is filled with fun and fascinating characters, who play, earn, adventure, and collect NFT's. Maybe you'll join them on their adventures?

 


characters page

Have a look around, or check out our <Link to="/history" style={{"color": "yellow"}}> story</Link>, then get back to work!

*/
