import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Tooltip from '@mui/material/Tooltip';
import {
  createTheme,
  MuiThemeProvider,
  withStyles
} from "@material-ui/core/styles";

//import PersonIcon from '@mui/icons-material/Person';
//import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';

import TreasureLogo from './assets/links/treasure_logo_circle3black.png';
import Cloud1 from './assets/icons/categories/cloud_1.png';
import Energy4 from './assets/icons/categories/energy_4.png';
import Strength2 from './assets/icons/categories/strength_2.png';
import RunningMan from './assets/icons/categories/agility_1.png';
import Wood1 from './assets/icons/categories/wood_1.png';
import Psychic8 from './assets/icons/categories/psychic_8.png';
import Sound4 from './assets/icons/categories/sound_4.png';
import Hammer from './assets/icons/categories/attack_7.png';
import Craftsman9 from './assets/icons/categories/craftsman_9.png';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
//import Fade from '@mui/material/Fade';
import { traitFrequency, traitFrequencyToColor } from './mechapunkx/traitFrequency';


// Tooltip for rarity rank
const defaultTheme = createTheme();
const tooltipTheme = createTheme({
  overrides: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: 'pink',
          color: 'red',
          border: '1px solid #dadde9',
        },
      },
  	}
  }
});

const iconsCategories = {
  'Background': Cloud1,
  'Skin': Energy4,
  'Armor': Strength2,
  'Armor Version': Strength2,
  'Body': RunningMan,
  'Hair': Wood1,
  'Eyes': Psychic8,
  'Mouth': Sound4,
  'Item': Hammer,
  'Other': Craftsman9
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/*
const Transition2 = React.forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} />;
});
*/

export default function GalleryModal(props) {
	const { onClose, modalImage, open } = props;

	const handleClose = () => {
		onClose(modalImage);
	};

	const handleListItemClick = (value) => {
		onClose(value);
	};

	function showCategory(cat) {
		let c = cat.toLowerCase();
		return c.includes("armor") || c.includes("skin") || c.includes("mouth") || c.includes("hair");
	}

	function categoryOrBlank(cat) {
		return showCategory(cat) ? cat : "";
	}

	// good purple: 9b4efb
	// good pink: ff51dd
	// good aqua: 35eeed
	// neon: 22ee45
	// yellow: f2ff38 / ffe43c
	// e5f141
	function traitToColor(trait_json) {

		let type = trait_json["trait_type"];
		let value = trait_json["value"];

		if (type === "Armor") return "#43a7ff";
		else if (value === "Rare") return "#ffe43c";
		else if (value === "Alien" && type === "Skin") return "#22ee45";
		else if (value === "Ape" && type === "Skin") return "#e0c78f";
		else return "";
	}

  // console.log("PROPS: ", props);

  /*
  fullWidth
  impacted by marign
  maxWidth ('lg', 'xl')

  see for setting max width
  https://mui.com/components/dialogs/
  const [maxWidth, setMaxWidth] = React.useState('sm');
  
  fullWidth={true} 
  
  style={{width: '200px', marginLeft: '40%', backgroundColor: 'transparent'}}

  <CircularProgress size={2} style={Styles.mainLoader}/>
mainLoader: { 
	position: 'absolute',
	paddingTop: '15%',      
	width: '100%',
	height: '100%',
	zIndex: 1000,
	backgroundColor: '#000000',
	opacity: 0.5,
	textAlign: 'center',
}
  
  should have used PaperProps to modify size I think
  https://mui.com/api/dialog/
  and
  bodyStyle={{margin:0, padding:0}}
  style={{width: '200px', marginLeft: '40%', backgroundColor: 'transparent'}}
  title= 'Loading'
  titleStyle={{paddingTop: '0px', paddingLeft: '45px', fontSize: '15px', lineHeight: '40px'}}
  BackdropProps={{ style: { backgroundColor: "transparent" } }}

  <dialog>...
  <RefreshIndicator
	  style= {{display: 'inline-block'}}
	  size={50}
	  left={50}
	  top={30}
	  loadingColor="#FF9800"
	  status="loading"    
	/>
  </dialog>

  Great example of using the modal / backdrop with a loading indicator
  examples... https://stackoverflow.com/questions/41158325/how-to-create-mui-dialog-with-transparent-background-color
  
  fullWidth={"true"}
  */

  	
	let skipArmorVersion = false;
	let armor, armorVersion, rarityRank;
	for (let i=0;i<modalImage.metadata.length;i++) {
		let m = modalImage.metadata[i];
		let traitType = m["trait_type"];

		if (traitType === "Armor") {
			armor = m["value"];
		}
		else if (m["trait_type"] === "Armor Version") {
			armorVersion = m["value"];
		}
		else if (m["trait_type"] === "Rank") {
			rarityRank = parseInt(m["value"]);
		}
	}
	if (armor === armorVersion) {
		skipArmorVersion = true;
	}
	else {
		if (armorVersion.startsWith(armor)) {
			armorVersion = armorVersion.substring(armor.length, armorVersion.length).trim();
		}
	}

 // transitionDuration={1325}
  return (
	<Dialog onClose={handleClose} 
	  open={open} className="modal-dialogue" maxWidth={"md"} 
	 
	  TransitionComponent={Transition}
	  transitionDuration={400}
	  PaperProps={{
		  style: {
			backgroundColor: 'transparent',
			boxShadow: 'none',
		  },
		}}
	  BackdropProps={{ style: { backgroundColor: "rgba(0, 0, 0, 0.8)" } }}> 

	  <div className="modal-container">
		<div className="modal-container-left">
		  {/*Left*/}
		  <img className="modal-img" src={modalImage.url}></img>
		  {/*<img className="modal-img" src={modalImage} height="200" width="200"></img>*/}
		</div>
		<div className="modal-container-right noselect">
			{/*434343*/}
			<div className="modal-title" style={{"backgroundColor": "black", "color": "#535353"}}>
				<div style={{"padding-right": "0.5vw"}}>#{modalImage.imageId}</div>
				<a href="https://treasure.lol" target="_blank">
					<div style={{"padding": "1vw", "display": "flex", "flex-direction": "row", "justify-content": "center"}}>
						<img src={TreasureLogo} height="35px" width="35px" style={{"border-radius": "7px"}}></img>
					</div>
				</a>
			</div>

			{/*"white-space": "nowrap""font-size": "100%", "white-space": "nowrap". 

			"font-size": "calc(80%)",
			*/}

			{/*<div style={{"display": "flex-box", "flex-direction": "column", "justify-content": "space-between"}}>*/}
			<table className="modal-attributes" style={{"display": "table",
		  		"text-align": "right", "borderCollapse": "separate", "padding-left": "2vw"}}>
		  		<tbody key={Math.random()}>
		  		{/*<Fade in={true} appear={true} timeout={370}><div>*/}
				{modalImage.metadata.map((trait_json) => 
					((skipArmorVersion && trait_json["trait_type"] === "Armor Version") || 
						(trait_json["trait_type"] === "Rank")) ? "" :
					<tr key={"trait_row_" + Math.random().toString()}>
						<td style={{"padding-right": "1vw", "text-align": "left", "padding-top": ".2vw", "padding-bottom": ".2vw"}}>
							<img style={{"border-radius": "4px", "verticalAlign": "middle"}}
					  			src={iconsCategories[trait_json["trait_type"]]} height="20px" width="20px"></img>
					  	</td>
						{/*<td style={{"padding-left": "1vw", "padding-right": "1vw", "font-size": "0.7em"}}>
					  	<span className="modal-category">{trait_json["trait_type"]}</span></td>

					(trait_json["trait_type"] === "Armor") ? "#4ea7ff" : "" 
							

					  */}

					  {/*"display": "flex", "flex-direction": "row", "align-items": "center",*/}
						<td className="text-offwhite account-traits-text-modal noselect" style={{
							"font-weight": "normal", "font-family": "Kanit", 	
							"text-align": "left", "padding-right": "2.5vw", "font-size": "1.4vw", "color": traitToColor(trait_json)
						}}>
							{/*<div style={{"justify-content": "center", "align-items": "center"}}>*/}
							{
					  			(trait_json["trait_type"] === "Armor Version") ? armorVersion : trait_json["value"]
					  		}
					  		{/*</div>*/}
					  		<span className="traits-cat-hover" style={{"font-size": "0.7em", "padding-left": "1.5vw", 
					  			"color": "rgb(59 58 58)"}}>
					  		{categoryOrBlank(trait_json["trait_type"])}
					  		</span>
					  	</td>
					  	<td className="modal-category account-traits-text" style={{"font-size": "0.7em", "text-align": "center", 
					  		"padding-right": "1vw", "padding-left": "1vw", 
					  		"background-color": "#1e1e1e", "border-radius": "8px",
					  		"color": traitFrequencyToColor(trait_json["trait_type"], trait_json["value"])
					  	}}>
					  	{traitFrequency(trait_json["trait_type"], trait_json["value"])}%
					  	</td>
					  {/*<td style={{"padding-left": "1vw", "padding-right": "1vw", "font-size": "0.7em"}}>
					  	<span className="modal-category">{trait_json["trait_type"]}</span></td>*/}
					  {/*<td style={{"padding-right": "1vw"}}><img style={{"border-radius": "4px", "verticalAlign": "middle"}}
					  	src={iconsCategories[trait_json["trait_type"]]} height="20px" width="20px"></img></td>*/}
					</tr>
					
				)}
				{/*</div></Fade>*/}
				
				</tbody>
			</table>

			{/*<td className="text-offwhite account-traits-text" style={{
				"display": "flex", "flex-direction": "row", 
				"text-align": "left", "padding-right": "2.5vw", "font-size": "1.6vw", "color": traitToColor(trait_json)
			}}>
				<div style={{"padding-right": "1.5vw", "justify-content": "center", "align-items": "center"}}>{
		  			(trait_json["trait_type"] === "Armor Version") ? armorVersion : trait_json["value"]
		  		}
		  		</div>
		  		<div className="" style={{"font-size": "0.6em", "padding-left": "1.5vw", 
		  			"color": "#818181", "background-color": "#2c2c2c", "border-radius": "5px", "padding": "5px", "width": "fit-content"}}>
		  		{categoryOrBlank(trait_json["trait_type"])}
		  		</div>
		  	</td>
		  	<td className="modal-category account-traits-text" style={{"font-size": "0.7em", "text-align": "center", 
		  		"padding-right": "1vw", "padding-left": "1vw", 
		  		"background-color": "#1e1e1e", "border-radius": "8px"
		  	}}>
		  	6.9%
		  	</td>*/}



		  	{/*<table className="modal-attributes" style={{"display": "table",
		  		"text-align": "right", "border-collapse": "separate", "padding-left": "3vw"}}>
		  		<tbody>
				{modalImage.metadata.map((trait_json) => 
					(skipArmorVersion && trait_json["trait_type"] === "Armor Version") || (trait_json["trait_type"] == "Rank") ? "" :
					<tr>
						<td style={{"text-align": "left", "padding-right": "2.5vw"}}>{
					  		(trait_json["trait_type"] === "Armor Version") ? armorVersion : trait_json["value"]
					  	}</td>
					  	<td className="modal-category" style={{"font-size": "0.7em", "text-align": "center", 
					  	"padding-right": "2vw", "padding-left": "1vw", 
					  	"background-color": "#1e1e1e", "border-radius": "8px"}}>
					  	6.9%
					  	</td>
					  
					  <td style={{"padding-right": "1vw"}}><img style={{"border-radius": "4px", "verticalAlign": "middle"}}
					  	src={iconsCategories[trait_json["trait_type"]]} height="20px" width="20px"></img></td>
					</tr>
				)}
				</tbody>
			</table>*/}

			{/*style={{"padding-right": "2.5vw", "text-align": "right", "color": "white", "align-self": "flex-end", "height": "100%", "display": "inline-block"*/}
			<div style={{"height": "100%", "display": "flex", "flex-direction": "column", 
				"align-items": "flex-end", "padding-right": "4vw", "padding-bottom": "2vw"}}>
				<div style={{"height": "100%"}}>
				</div>
				
				<Tooltip title={"Rarity Rank"} placement="top-start" style={{"color": "white"}}
					componentsProps={{
					    tooltip: {
					      sx: {
					        bgcolor: '#202020',
					        '& .MuiTooltip-arrow': {
					          color: 'common.black',
					        },
					      },
					    },
					}}
				    >
					<div style={{"color": "white", "align-self": "flex-end", "font-weight": "bolder", "font-family": "Kanit"}}>
							{/*<div style={{"color": "white", "font-size": "6vw", "font-weight": "bold"}}>?</div></Tooltip>*/}
						<span className="modal-rank">{Number(rarityRank).toLocaleString()}
						</span>
						<span style={{"font-weight": "bold", "color": "#434343", "padding-left": "1.4vw", "font-size": "1.5vw"}}>/ &nbsp;7,848
						</span>
					</div>
				</Tooltip>
				
			</div>
			{/*</div>*/}






		</div>
	  </div>
	</Dialog>
  );
}

GalleryModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  modalImage: PropTypes.object.isRequired, // url, metadata, imageId
};

/*


<table className="modal-attributes" style={{"display": "table", "text-align": "left", "border-collapse": "separate",
		"padding-right": "2.5vw"}}>

	{modalImage.metadata.map((trait_json) => 
		(skipArmorVersion && trait_json["trait_type"] === "Armor Version") ? "" :
		<tr>
		  <td style={{"padding-right": "1vw"}}><img style={{"border-radius": "4px", "vertical-align": "middle"}}
		  	src={iconsCategories[trait_json["trait_type"]]} height="20px" width="20px"></img></td>
		  <td style={{"background-color": (trait_json["trait_type"] === "Armor" ? "#292929": "")}}>{
		  	(trait_json["trait_type"] === "Armor Version") ? armorVersion : trait_json["value"]
		  }<span className="modal-category" style={{"font-size": "0.7em", "padding-left": "8px"}}>({trait_json["trait_type"]})</span></td>
		  <td className="modal-category" style={{"font-size": "0.7em", "text-align": "right"}}>
		  	6.9%
		  </td>
		</tr>
	)}
</table>






Considered adding color for the armor name:
style={{"color": (trait_json["trait_type"] === "Armor" ? "red": "")}}


<table className="modal-attributes" style={{"display": "block", "text-align": "right", "border-collapse": "separate", "padding-left": "3vw"}}>
{modalImage.metadata.map((trait_json) => 
	(skipArmorVersion && trait_json["trait_type"] === "Armor Version") ? "" :
	<tr>
	  <td style={{"text-align": "left", "padding-right": "2.5vw"}}>{
	  	(trait_json["trait_type"] === "Armor Version") ? armorVersion : trait_json["value"]
	  }</td>
	  
	  <td style={{"padding-right": "1.7vw", "font-size": "0.7em"}}>
	  	<span className="modal-category">{trait_json["trait_type"]}</span></td>
	  <td style={{"padding-right": "1vw"}}><img style={{"border-radius": "4px", "vertical-align": "middle"}}
	  	src={iconsCategories[trait_json["trait_type"]]} height="20px" width="20px"></img></td>
	</tr>
)}
</table>





<table className="modal-attributes" style={{"display": "block", "text-align": "left", "border-collapse": "separate"}}>
{modalImage.metadata.map((trait_json) => 
	(skipArmorVersion && trait_json["trait_type"] === "Armor Version") ? "" :
	<tr>
	  <td style={{"padding-right": "1vw"}}><img style={{"border-radius": "4px", "vertical-align": "middle"}}
	  	src={iconsCategories[trait_json["trait_type"]]} height="20px" width="20px"></img></td>
	  <td style={{"padding-right": "1.5vw", "font-size": "0.7em"}}>
	  	<span className="modal-category">{trait_json["trait_type"]}</span></td>
	  <td>{
	  	(trait_json["trait_type"] === "Armor Version") ? armorVersion : trait_json["value"]
	  }</td>
	</tr>
)}
</table>






<td style={{"padding-right": "1vw"}}><img style={{"border-radius": "4px", "vertical-align": "middle"}}
	src={iconsCategories[trait_json["trait_type"]]} height="20px" width="20px"></img></td>
<td style={{"padding-right": "1.5vw", "font-size": "0.7em"}}>
	<span className="modal-category">{trait_json["trait_type"]}</span></td>
<td>{
	(trait_json["trait_type"] === "Armor Version") ? armorVersion : trait_json["value"]
}</td>

display: table-row;
	vertical-align: inherit;
	border-color: inherit;
table defaults
border-collapse: separate;
	text-indent: initial;
	border-spacing: 2px;
	table > tr {
	vertical-align: middle;
}
*/


/*

originally inside the Dialog
	  <List sx={{ pt: 0 }}>
		{emails.map((email) => (
		  <ListItem button onClick={() => handleListItemClick(email)} key={email}>
			<ListItemAvatar>
			  <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
				
			  </Avatar>
			</ListItemAvatar>
			<ListItemText primary={email} />
		  </ListItem>
		))}

		<ListItem autoFocus button onClick={() => handleListItemClick('addAccount')}>
		  <ListItemAvatar>
			<Avatar>
			</Avatar>
		  </ListItemAvatar>
		  <ListItemText primary="Add account" />
		</ListItem>
	  </List>


--

<ListItem autoFocus button onClick={() => handleListItemClick('addAccount')}>
		  <ListItemAvatar>
			<Avatar>
			  <AddIcon />
			</Avatar>
		  </ListItemAvatar>
		  <ListItemText primary="Add account" />
		</ListItem>
	  </List>
	</Dialog>
*/
