import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export default function GalleryCheckbox() {

	const [checked, setChecked] = React.useState(false);

	const handleChange = () => {
		setChecked(!checked);
	};

	  return (
		<FormGroup>
		  <FormControlLabel 
			label="" 
			control={
				<Checkbox defaultUnchecked size="small" checked={checked} onChange={handleChange}
				sx={{ 
					px: 0,
					py: 0,
					color: 'rgba(255,255,255,.1)',
					// color: '#ffffff',
					'&.Mui-checked': {
						color: 'rgba(255, 0, 0, .8)',
						// color: 'rgba(255, 255, 255, .8)',
					}
				}}/>
			}
		  />
		  
		</FormGroup>
	  );
}

/*

function Checkbox() {
  const [checked, setChecked] = React.useState(true);

  return (
    <label>
      <input type="checkbox"
        defaultChecked={checked}
        onChange={() => setChecked(!checked)}
      />
      Check Me!
    </label>
  );
}

ReactDOM.render(
  <Checkbox />,
  document.getElementById('checkbox'),
);

<input type="checkbox" defaultChecked={this.state.chkbox} onChange={this.handleChangeChk} />

<FormControlLabel control={<Checkbox defaultChecked />} label="Label" />
<FormControlLabel disabled control={<Checkbox />} label="Disabled" />

*/