import MechIconSmall from '../assets/icons/mechIconSmall.png';


export default function MechIcon() {
	return (<div style={{"background-color": "red", "color": "white", 
						"font-family": "Kanit", "font-weight": "bold", "border-radius": "15%",
						"display": "inline-block", "width": "2vw", "height": "2vw", "text-align": "center", "line-height": "1.35",
						"vertical-align": "middle",
						"margin-left": "1vw",
						"min-height": "20px",
						"min-width": "20px"
					}}><img src={MechIconSmall} width="100%"/>
			</div>);
}

/*
export default function MechIcon() {
	return (<div style={{"background-color": "red", "color": "white", 
						"font-family": "Kanit", "font-weight": "bold", "border-radius": "15%",
						"display": "inline-block", "width": "2vw", "height": "2vw", "text-align": "center", "line-height": "1.35",
						"margin-left": "1vw",
						"min-height": "20px",
						"min-width": "20px"
					}}>M
			</div>);
}
*/