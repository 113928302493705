import * as React from 'react';
import AccountViewTraits from './accountViewTraits';
import AccountViewItems from './accountViewItems';
import TreasureLogo from '../assets/links/treasure_logo_circle3black.png';
import BirdIcon13 from '../assets/icons/birdIcon13.png'
import MPXMetadata from '../mechapunkx_metadata.json';
import Image from 'material-ui-image'
import useSound from 'use-sound';
import ClickSound from '../sounds/click.wav';
import mistakeSound from '../sounds/mistake.wav';

export interface AccountViewProps {
	ownerAddress: String;
	tokens: Array;
	accountViewRefreshItems: Object;
}
/*
tokens: [
	{
		tokenId: "102",
		src: "https://lambo.lol/mechapunkx/images/102.png",
		rarity: 221,
	},
*/
/*

mint
	sends MPX sample data tokens to account view

	account view sends to account view items
		-- we have account view fetching metadata... 
		metadata ref is passed to AccountViewTraits

	items on click sneds back item
	item object has no traits
	so item.traits is not being used
*/

export default function AccountView(props: AccountViewProps) {

	/*
	let sampleImages = [
		"output_102.png", "output_251.png", "output_2725.png", "output_353.png", "output_2417.png", 
		"output_5555.png", "output_6272.png", "output_7062.png", "output_8579.png", "output_1084.png", "output_3217.png", 
		"output_2945.png", "output_3663.png", "output_4946.png", "output_556.png", "output_6362.png", "output_7208.png", 
		"output_5497.png", "output_1224.png", "output_352.png", "output_3116.png", "output_3792.png", "output_5118.png", 
		"output_5616.png", "output_6568.png", "output_6662.png", "output_1431.png", "output_4848.png", "output_2079.png", 
		"output_4263.png", "output_5233.png", "output_6.png", "output_736.png", "output_6840.png", "output_1545.png", 
		"output_6026.png", "output_3418.png", "output_4381.png", "output_7849.png", "output_7972.png", "output_5420.png", 
		"output_6093.png", "output_1740.png", "output_1979.png", "output_4664.png", "output_2263.png", "output_921.png", 
		"output_8383.png", "output_2580.png", "output_6947.png"];
	*/

	const errorImage = <div style={{"color": "#5a5959", "font-size": "6vw", "font-weight": "bold"}}>?</div>

	const [activeTokenId, setActiveTokenId] = React.useState("");
	const [activeRarity, setActiveRarity] = React.useState("~");
	const [activeTraits, setActiveTraits] = React.useState([]);
	const [activeImage, setActiveImage] = React.useState("123"); // Triggers default img, garbage hack
	
	// Map of ID to metadata for all 8k images (at least what has been minted up to the present)
	const [metadata, setMetadata] = React.useState({});

	const [playClick] = useSound(ClickSound, { volume: 0.43, "playbackRate": 0.7 });
	const [playMistake] = useSound(mistakeSound, { volume: 0.25 });	

	// TODO: Can make this global probably, 
	// instead of fetch in gallery and here ************

	React.useEffect(() => {
		fetchMetadata();
	}, []);

	/* Use a single file updated with all the metadata currently available, everything else is derived from that*/
	const fetchMetadata = () => {
		setMetadata(MPXMetadata);

		/*
		fetch('../mechapunkx_metadata.json',{
	      headers : { 
	        'Content-Type': 'application/json',
	        'Accept': 'application/json'
	       }
	   	}).then(function(response){
        	//console.log("Got categories resp: ", response);
        	return response.json();
      	}).then(function(json){
      		setMetadata(json);
      	});
      	*/
	}

	const handleClickItem = (item, event) => {
		// console.log("Item clicked: ", item);
		
		if (item.tokenId !== undefined)
			if (item.tokenId === activeTokenId) {
				playMistake();
			}
			else {
				setActiveTokenId(item.tokenId);
			
				// Better to do this outside one time instead of on-click
				// Try extract rarity from traits	
				// setActiveRarity(item.rarity);
				try {
					// let ts = item.traits;
					let ts = metadata[item.tokenId];
					// console.log("Checking traits: ", ts);
					let r = -1;
					for (let i=ts.length-1; i>=0; i--) {
						let t = ts[i];
						if (t["trait_type"] === "Rank") {
							r = parseInt(t["value"])
							break;
						}
					}
					// console.log("Setting rarity: ", item.tokenId, r);
					if (r !== -1) setActiveRarity(r);
				}
				catch (error) {
					console.log("Error reading rank from metadata: ", error);
				}
				
				// setActiveTraits(item.traits);
				
				// Use full size image 
				setActiveImage(item.src.replace("mechapunkx-small", "mechapunkx"))
				// setActiveImage(item.src);
				playClick();
			}
		}

	// Only display account if connected
	// if (props.ownerAddress == "") return (<div></div>);

	return (
		<div className="mpx-inv-account-view text-small" style={{"display": "flex", "flex-direction": "column", "justify-content": "center", 
			"border-radius": "8px", 
			"background-color": "black", "padding-bottom": "4vw", "margin-top": "4vw"
		}}>
			<div style={{
				"display": "flex", "flex-direction": "row", "justify-content": "space-between", 
				"padding-left": "3vw", "padding-right": "3vw", "padding-top": "2vw", "padding-bottom": "1vw"}}>
				<div className="text-lightblue mpx-inv-text noselect" style={{
				}}>
					<a href="https://trove.treasure.lol/collection/mechapunkx/" target="_blank">
					MechaPunkx
					</a>
				</div>
				<div style={{}}>
					<a href={"https://trove.treasure.lol/collection/mechapunkx/" + activeTokenId.toString()} target="_blank">
						<img src={TreasureLogo} style={{"width": "max(2.6vw, 27px)", "marginRight": "1vw"}}></img>
					</a>
				</div>
			</div>
			<div style={{"display": "flex", "flex-direction": "row", "width": "100%"}}>
				<div style={{"display": "flex", "flex-direction": "column", "width": "52%", "padding-left": "3vw", "padding-right": "2vw"}}>
					<a href={"https://trove.treasure.lol/collection/mechapunkx/" + activeTokenId.toString()} target="_blank">
						<div style={{"display": "flex", "flex-direction": "row"}}>
							<Image
								key={"account-image-" + Math.random().toString()}
								src={activeImage}
								style={{"background-color": "#1e1e1e", "display": "flex", "flex-direction": "row", 
									"justify-content": "center", "align-items": "center", "width": "100%"}}
								imageStyle={{"border-radius": "4px"}}
								aspectRatio={1.0}
								errorIcon={errorImage}
								// height: "200px", width: "200px", 
								// onClick={() => handleClickOpen(url, metadata, imageId)}
								// disableSpinner={true}
								animationDuration={0}
							/>
							{/*<img src={activeImage} width="100%" height="100%" style={{"aspect-ratio": "1"}} />*/}
						</div>
					</a>
					<div className="mpx-inv-text" style={{"display": "flex", "flex-direction": "row", 
						"justify-content": "space-between", "padding-top": "2vw", "padding-bottom": "3vw"
					}}>
						<div className="text-gray">
							<a href={"https://trove.treasure.lol/collection/mechapunkx/" + activeTokenId.toString()} target="_blank">
							#{activeTokenId}
							</a>
						</div>
						<div style={{"text-align": "right"}}>
							<span className="text-gray">Rarity:</span>
							<br></br>
							<div style={{"padding-top": "1vw"}}>{activeRarity.toLocaleString()} &nbsp;&nbsp;<span className="" style={{"color": "#434343"}}>/&nbsp;&nbsp; 7,848</span></div>
						</div>
					</div>
				</div>
				<AccountViewTraits traits={activeTraits} tokenId={activeTokenId} ownerAddress={props.ownerAddress} metadata={metadata}/>
			</div>
			<AccountViewItems handleClickItem={handleClickItem} items={props.tokens} accountViewRefreshItems={props.accountViewRefreshItems}/>	
		</div>
	);
}