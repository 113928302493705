import React, { Component } from 'react';

import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  Outlet
} from "react-router-dom";

class Projects extends Component {

	constructor(props) {
		super(props);

		// const [isHovering, setIsHovering] = useState(false);

		this.state = {
			//character: props.character,
			//hideStats: true
		}
	}

	/*
	handleMouseOver = () => {
		// setIsHovering(true);
		this.setState({hideStats: false})
	};
	*/


	render() {

		const pagesKnees = ["Kneecaps", "Future"];
		const pagesMecha = ["MechaPunkx", "Gallery", "Inventory", "Community", "About"];

		return(
			<main style={{ padding: "1rem 0" }}>
				<h2>Projects</h2>
				<div>
				<div to="/kneecaps" onClick={() => {
					console.log("CLICKD KNEES");
					// this.props.menu.setBreadcrumb(["a", "x", "z"]);
					// console.log();
					
					this.props.menu.setBreadcrumb();

				}}>Kneecaps</div>
				<br></br>
				<Link to="/mechapunkx">MechaPunkx</Link>
				</div>
			</main>
		)
	}

	/*
	render() {
		return (
			<div 
				className="hoverable-item cb-item position-relative px-2" 
				onMouseOver={this.handleMouseOver} 
				onMouseOut={this.handleMouseOut}
			>
				<img 
					src={this.props.character.src} 
					className="img-fluid cb-item-img" 
					draggable="false" 
					alt="col" 
					data-charid={this.props.character.charId} 
				/>
				{{console.log("Char: ", this.props.character)}}
				<HoverableStats 
					shouldHide={this.state.hideStats} 
					health={this.props.character.health}
					strength={this.props.character.strength}
					dexterity={this.props.character.dexterity}
					intelligence={this.props.character.intelligence}
				/>
				
			</div>
		)
	}
	*/


}

export default Projects