import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import useCallback from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  Outlet
} from "react-router-dom";


import {ReactComponent as MenuIcon} from './logo.svg';

//import MenuIcon from '@mui/icons-material/Menu';
//import { MenuIcon } from "./assets/menuIconFake.png";






// Add pseudo-breadcrumb 
// figur out how to allow scroll while modal is up, like azuki
// random sort button gallery!!!!


// FAR RIGHT MENU
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const pagesHome = ["About"];
const pagesKnees = ["Kneecaps", "Future"];
const pagesMecha = ["MechaPunkx", "Gallery", "Inventory", "Community", "About"];

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const [pages, setPages] = React.useState(pagesHome);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
    setPages(["A", "B", "C"]); // So this works to change the menu element
  };

  /*
  const setBreadcrumb = useCallback(
    () => {
      console.log('Click happened');
    },
    [], // Tells React to memoize regardless of arguments.
  );
  */


   /*
   I want Menu to have
   activePage.links
   and render those in the Menu
   which seems fine.... 
   but I need to define the pages outside of the menu, and then pass in the pages as props I guess....
    AND even then..... going to "/mechapunkx" from the projects page, will not update the menu... react puts you just on that page... 
    so why the fuck
    can't I just do onClick... usehistory('mepx') or useLocation or whatever, and then update the menu component?



    why can't I, on a link click... update the state of another component and migrate the route.... 

    I need to bind a function, don't want to rewrite as a Component with a constructor

    0 - Menu is a component inside each Page - bad
    1 - useParams (seems awful way to handle)
    2 - maybe activePages needs to be a data point ABOVE menu, not sure if we can mutate object within child component?
    3 - menu SHOULD be independent state of projects... so whyyyy is it not updating. and console print not working
    4 - need bind?, this.setBreadcrumb = this.setBreadcrumb.bind(this); // means re-write as a component
    */
  
  const setBreadcrumb = () => {
    // console.log("Setting breadcrumb: ");
   
    console.log("Judy");
    setPages(["x", "y", "z"]);
  }
  

  

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        
          


     {/* <div className="menu-main"> 
        <div className="menu-left">
          <div className="menu-logo">LOGO
          </div>
          <div className="menu-separator">|
          </div>
          <div className="menu-projects">PROJECTS
          </div>
          <div className="menu-separator">|
          </div>
          <div className="menu-breadcrumb">
            when switch project, call setPages(), which should reference the currently active breadcrumb elements or whatever
            the pages should be here
            <Menu ... pages.map{}... see below
            SOME OTHER // breadcrumpPieces.map{ breadcrumElement }
          </div>
        </div> 

        <div className="menu-right">
          <div className="">
            Right1
          </div>
          <div className="">
            Right2
          </div>
        </div>
      </div>

        <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
          >
            LOGO
          </Typography>

      */


    }
        
      <Toolbar disableGutters>
         


          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>

            {/*Could use this for LOGO | PROJECTS, duplicate for breadcrumb, need routes for each of the project names then*/}

            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          
          {/*
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
          >
            LOG
          </Typography>
          */}

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              
            <Button
              key="Lambocorp"
              onClick={handleCloseNavMenu}
              sx={{ my: 2, color: 'white', display: 'block' }}
            ><Link to="/">Lambocorp</Link> {/*|{" "}*/}
            </Button>

            <Button
              key="Projects"
              onClick={handleCloseNavMenu}
              sx={{ my: 2, color: 'white', display: 'block' }}
            ><Link to="/projects">Projects</Link> 
            </Button>

            {/*
            <Button
              key="separator"
              onClick={() => {}}
              sx={{ my: 2, color: 'white', display: 'block' }}
            >|
            </Button>
            */}      

            {pages.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block' }}
              ><Link to={`/${page}`}>{page}</Link> {/*|{" "}*/}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>

            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>


      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;




/*
<Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
          >
            LOGO
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
          >
            LOGO
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>

*/
