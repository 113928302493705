import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { styled, ThemeProvider, createTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import GalleryCheckbox from './gallery-checkbox';

//import ListItemIcon from '@material-ui/icons';
//import IconButton from '@material-ui/icons/IconButton';
import ArrowRight from '@material-ui/icons/ArrowRight';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import Home from '@material-ui/icons/Home';
import Settings from '@material-ui/icons/Settings';
import People from '@material-ui/icons/People';
import PermMedia from '@material-ui/icons/PermMedia';
import Dns from '@material-ui/icons/Dns';
import Public from '@material-ui/icons/Public';

import { ExpandLess as ListItemIcon } from "@material-ui/icons";
import { ExpandMore as IconButton } from "@material-ui/icons";

const data = [
  { icon: <People />, label: 'Type' },
  { icon: <Dns />, label: 'Armor' },
  { icon: <Dns />, label: 'Armor (specific)' },
  { icon: <Public />, label: 'Eyes' },
  { icon: <Public />, label: 'Hair' },
  { icon: <Public />, label: 'Mouth' },
  { icon: <PermMedia />, label: 'Item' },
  { icon: <PermMedia />, label: 'Other' }
];

export default function GalleryFilterCategory(props) {
 
  const [open, setOpen] = React.useState(false);

  return (
    <Box
      sx={{
        // bgcolor: open ? 'rgba(71, 98, 130, 0.2)' : null,
        // bgcolor: open ? '#212121' : null,
        bgcolor: open ? '#262626' : null,
        pb: open ? 2 : 0,
      }}
    >

      <ListItemButton
        alignItems="flex-start"
        onClick={() => setOpen(!open)}
        sx={{
          px: 3,
          pt: 2.5,
          pb: open ? 2.5 : 2.5,
          '&:hover, &:focus': { '& svg': { opacity: open ? 1 : 0 } },
        }}
      >
      <img src={props.icon} height="25px" width="25px" style={{"background-color": "transparent", "border-radius": "10px", "margin-right": "1vw"}}></img>
        <ListItemText
          primary={props.name}
          primaryTypographyProps={{
            fontSize: 15,
            fontWeight: 'medium',
            lineHeight: '20px',
            mb: '2px',
          }}
          
          sx={{ my: 0 }}
        />
        <KeyboardArrowDown
          sx={{
            mr: -1,
            opacity: 0,
            transform: open ? 'rotate(-180deg)' : 'rotate(0)',
            transition: '0.2s',
          }}
        />
      </ListItemButton>


    {/*key={item.label} was the previous for all*/}

      {open &&
        props.values.map((item, index) => (

          <ListItemButton
            key={item.name}
            // sx={{ py: 0, minHeight: 32, color: 'rgba(255,255,255,.8)' }}
            sx={{ 
              pb: 0,
              ml: "2vw",
              mr: "0vw",
              // pt: open && index === 0 ? '2vw' : 0,
              minHeight: 32, color: 'rgba(255,255,255,.8)' }}
          >
           {/* <ListItemIcon sx={{ color: 'inherit' }}>
              {item.icon}<GalleryCheckbox/>
            </ListItemIcon>*/}
            <GalleryCheckbox/>

            <ListItemText
              primary={item.name}
              primaryTypographyProps={{ fontSize: 14, pl: 0, fontWeight: 'medium'}}
            />
             <ListItemText
              primary={"(" + item.quantity + ")"}
              primaryTypographyProps={{ fontSize: 14, pl: 0, fontWeight: 'medium', 'textAlign': "right", "color": "#505050"}}
            />
          </ListItemButton>
        ))}
    </Box>
  );
}

GalleryFilterCategory.propTypes = {
  name: PropTypes.string.isRequired,
  values: PropTypes.array, // w/ name, quantity
};

// secondary="Categories and such"
// secondaryTypographyProps={{
//   noWrap: true,
//   fontSize: 12,
//   lineHeight: '16px',
//   color: open ? 'rgba(0,0,0,0)' : 'rgba(255,255,255,0.5)',
// }}