import * as React from 'react';
import logo from '../logo.svg';
// import './App.css';
import { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component'
import axios from 'axios';
import ReactDOM from 'react-dom';
import MPXMetadata from '../mechapunkx_metadata.json';
import MonkeyIcon from '../assets/monkeyTransparentIcon.png'
// react-router
// import { render } from "react-dom";
//import { BrowserRouter } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  Outlet
} from "react-router-dom";

//import titleBg from './assets/titleBg.png';
import titleBg from '../assets/skyBlue.png';
import titleText from '../assets/titleText.png';

// For modal
// import SimpleDialog from './simpleDialog';
import GalleryModal from '../galleryModal';

import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Fade } from '@mui/material';
//import PersonIcon from '@mui/icons-material/Person';
//import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';


const GalleryImage = ({ url, metadata, imageId, keyy, handleClickOpen }) => {
	
	return (
		<div className="image-outer">
			<div className="image-item" key={keyy}>
				<div className="gallery-img">
					<Fade in={true} appear={true} timing={2000}>
						<img style={{borderRadius: "0.75rem"}} src={url} height="200 px" width="200 px" 
							onClick={() => handleClickOpen(url, metadata, imageId)} />
					</Fade>
				</div>
			</div>
			<div className="image-caption noselect">
				{/*NO. {url.split("output_").slice(-1)[0].split('.png')[0]}*/}
				{/*NO. {imageId}*/}
			</div>
			<div id="image-id" className="image-caption2 noselect">
				{/*NO. {url.split("output_").slice(-1)[0].split('.png')[0]}*/}
			</div>
		</div>
	);
};


let Collage = () => {

	const [images, setImages] = React.useState([]);
	const [loaded, setIsLoaded] = React.useState(false);

	// Gallery Modal
	const [open, setOpen] = React.useState(false);
	// const [modalImage, setModalImage] = React.useState("");
	const [modalImage, setModalImage] = React.useState({"url": "", "metadata": [], "imageId": 0});

	// Mechapunkx NFT tokens found in wallet
	const [userHasItems, setUserHasItems] = React.useState(false);

	// Provided function in hook runs every time array parameter is updated
	
	React.useEffect(() => {
		// fetchImages();
	}, []);

	let sampleImages = [
		"output_102.png", "output_251.png", "output_2725.png", "output_353.png", "output_2417.png", 
		"output_5555.png", "output_6272.png", "output_7062.png", "output_8579.png", "output_1084.png", "output_3217.png", 
		"output_2945.png", "output_3663.png", "output_4946.png", "output_556.png", "output_6362.png", "output_7208.png", 
		"output_5497.png", "output_1224.png", "output_352.png", "output_3116.png", "output_3792.png", "output_5118.png", 
		"output_5616.png", "output_6568.png", "output_6662.png", "output_1431.png", "output_4848.png", "output_2079.png", 
		"output_4263.png", "output_5233.png", "output_6.png", "output_736.png", "output_6840.png", "output_1545.png", 
		"output_6026.png", "output_3418.png", "output_4381.png", "output_7849.png", "output_7972.png", "output_5420.png", 
		"output_6093.png", "output_1740.png", "output_1979.png", "output_4664.png", "output_2263.png", "output_921.png", 
		"output_8383.png", "output_2580.png", "output_6947.png"];
	sampleImages.reverse();

	function hasMoreSamples() {
		return images.length < sampleImages.length;
	}


	// Display sample images
	const fetchImages = (count = 9) => {
			//const apiRoot = "https://kneecaps.io/images/"
			/*
			axios.get('http://localhost:5000/1246.png', {}).then (res => {
				setImages([...images, ...res.data]);
				setIsLoaded(true);
			});
			*/  
			
			// Load more if available
			if (hasMoreSamples()) {

				console.log("Loading " + count.toString() + " images");

				let fs = sampleImages.slice(images.length, Math.min(images.length + count, images.length + (sampleImages.length - images.length)));
				let fsPaths = [];
				for (let i=0; i<fs.length; i++) {
					fsPaths.push('https://lambo.lol/mecha-sample/' + fs[i]);
				}
				
				//console.log("Images displayed, samples left: " + images.length.toString() + ", " + (sampleImages.length - images.length).toString())
				// Original, successful
				//setImages([...images, ['https://lambo.lol/mecha-sample/' + sampleImages[images.length]]]);								
				setImages(arr => [...images].concat(fsPaths));
				setIsLoaded(true);
			}
	}
	
	// url = {image.urls.regular} ----- previous
	// style={{backgroundImage: `url(${titleBg})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundAttachment: 'fixed'}}

	/* For MODAL */
	const handleClickOpen = (url, metadata, imageId) => {
		setOpen(true);
		setModalImage({"url": url, "metadata": metadata, imageId: imageId});
		// console.log("Clicked img: ", url);
	}

	/* For MODAL */
	const handleClose = (value) => {
	    setOpen(false);
	    //setModalImage(""); // TODO: .... hide image / use default fixed size maybe so no shift?
	 };


	 const emptyMessage = () => {
	 	return userHasItems ? "" : (<div className="text-small" style={{"padding-left": "2vw", "color": "darkred"}}>
			None found, showing recently minted.</div>);
	 }

	return (
		<div className="">
			<div className="gallery">
				
				
				{/*width: 71%;height: 100%;padding-right: 40px*/}
				<div style={{"background-color": "#1e1e1e", "width": "100%"}}>
					<div className="container">
						<div className="header content gallery-t-inventory noselect">
							{/*<h2 className="subtitle is-6">Gallery</h2>*/}
							<div style={{"display": "flex", "flex-direction": "row", "padding-bottom": "6px", "align-items": "center"}}>
								<div style={{"padding-right": "1vw"}}>Owned by:</div>
								<div className="text-small" style={{"text-align": "right", "background-color": "#36454F", 
									"border-radius": "8px", "padding": "6px", "display": "none"}}>0x</div>
								{emptyMessage()}
								
							</div>
							
							<span className="gallery-title-inventory"  style={{"font-family": "Kanit"}}>
								MECHA<span className="gallery-title2">PUNKX</span> <span className="gallery-subtitle"></span>
							</span>
							
							{/*<img src={titleText} height="100px" alt="image" />*/}
						</div>
						
						<InfiniteScroll
								 dataLength={images}
								 next={()=>{}}
								 // next={() => fetchImages(5)}
								 //next={() => { console.log("Called next... ")}}
								 hasMore={ userHasItems || images.length < 10 }
								 loader={ images.length === 0 ? '':
									<img
										 src={MonkeyIcon}
										 style={{borderRadius: "10px", backgroundColor: "transparent", marginTop: "40px", marginBottom: "30px"}}
										 height="110px"
										 width="110px"
										 alt="loading"
									/>}
						 >
								 <div className="image-grid" style={{ marginTop: "30px" }}>
										{loaded ? 
												images.map((image, index) => (
													<GalleryImage url={image} key={index} handleClickOpen={handleClickOpen} imageId={"4123"} metadata={[]}/>
												)): ""}
								</div>
						</InfiniteScroll>
					</div>
				</div>
			</div>

			<GalleryModal
		        modalImage={modalImage}
		        open={open}
		        onClose={handleClose}
		      />
		</div>
	);
};

class Gallery extends Component {

	constructor(props) {
		super(props);

		// this.menu = <ResponsiveAppBar />;
		// this.projects = <Projects menu={this.menu}/>;

		this.state = {
			//accounts: [],
			//contract: null,
			//kneecaps: [] // minted by user
		};

		//this.initialize = this.initialize.bind(this);
		//window.addEventListener('DOMContentLoaded', this.initialize);
	}

	render() {
		return (
			<Collage/>
		);
	}
}

export default Gallery;
